import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  constructor(private http: HttpClient) { }

  async getInvite(id: string, key: string) {
    return this.http.get<any>(environment.apiUrl + "/Invites/" + id + "/" + key + "")
  }
}
