export class User {
  id!: number;
  username!: string;
  firstname!: string;
  prefix?: string;
  lastname!: string;
  accesstoken?: string;
  idtoken?: string;
  /**
   * According to the Gaurds this is a string[] containing the named values of Roles
   */
  role: any;
  fullName!: string;
  email!: string;
  phoneNumber!: string;
  isCustomer!: boolean;
  isOrganisationAdmin!: boolean;
  isWhitelabelAdmin!: boolean;
  isSystemAdmin!: boolean;
  subscriptionLevel!: string;
  organisationGuid!: string; 
  organisation!: string;
  workgroup: any;
  /** The User Guid!!! */
  sub!: string;
  cscProviderName!: string;
  signatureProvider!: string;
  language!: string;
}

export class UserSearchResult {
  constructor(
    public firstname: string,
    public prefix: string,
    public lastname: string,
    public email: string,
    public mobile: string){}
}

export class FindUserModel {
  constructor(
    public voornaam: string,
    public tussenvoegsels: string,
    public achternaam: string,
    public email: string,
    public telefoon: string,
    public id?: string){}
}

export interface IUserResponse{
  total:number;
  results: UserSearchResult[];
}

export interface UserModel{
  Email: string;
  Lastname: string;
  Prefix: string | null | undefined;
  Firstname: string;
  PhoneNumber: string;
}

  