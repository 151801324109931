import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { StringHelper } from "./string-helper";

/** The formcontrol must have ATLEAST a certain Word count */
export function MinimumWordCountValidator(minimumWordCount: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let helper = new StringHelper();

    // do not count/validate words for no input
    if (!control.value) {
      return null;
    }
    const shorty = helper.countWords(control.value) < minimumWordCount;
    return shorty ? { shortComingOnWords: { value: control.value, minimum: minimumWordCount } } : null;
  };
}