import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { OrganisationApiClient } from 'src/app/services/organisation.apiclient';
import { OrganisationSettingsApiClient } from 'src/app/services/organisation-settings.apiclient';

@Component({
  templateUrl: './authorized.page.html'
})
export class AuthorizedPage implements OnInit {

  constructor(private authenticationService: AuthenticationService, private organisationApiClient: OrganisationApiClient, private organisationSettingsApiClient: OrganisationSettingsApiClient) { }
  longWaitTime:boolean=false;
  interval:any;
  private y:any;

  ngOnInit() {
    if (sessionStorage.getItem('currentUser') !== undefined && sessionStorage.getItem("returnUrl") !== undefined) {
      history.go(1);
    }

    this.interval=setInterval(()=>{
      this.longWaitTime = true;
    },3000);

    this.y=this.authenticationService.userdataAvailable.subscribe(x=>{
      clearInterval(this.interval);
      
      if(x===null) return;
      if(this.y) this.y.unsubscribe();
    });

    this.authenticationService.authorizeCallback();
    this.authenticationService.currentUser.subscribe((userData) => {
      if(userData != undefined && userData.organisationGuid != undefined){
        this.organisationSettingsApiClient.getOrganisationSettings(userData.organisationGuid);
        this.organisationApiClient.getOrganisationPlugins(userData.organisationGuid);
      }
    });
  }
}
