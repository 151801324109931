import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  sortedArray!: any[];

  transform(value: any[], order: any = '', column: string = ''): any[] {
    let itemsTypeofString = value.map(t=>t[column]).every(function(element) { return typeof element === 'string'; });

    if (!value || order === '' || !order) { return value; }
    if (value.length <= 1) { return value; }

    if (itemsTypeofString && column.split('.').length == 1) {
      this.sortedArray = orderBy(value, (o) => o[column].toString().toLowerCase(), [order]);
    } else {
      this.sortedArray = orderBy(value, [column], [order]);
    }
    return this.sortedArray;
  }
}