<div class="rounded-corners">
<table class="mat-mdc-table">
  <tr class="mat-mdc-header-row">
    <th class="mat-mdc-header-cell mdc-data-table__header-cell" colspan="2">
      <span class="float-start mobile-title">{{tableData.jobName}}
        <ngb-progressbar type="{{statusIndicator}}" [value]="statusIndicatorProgress()" [animated]="false"
          class="activity-item-progress-bar mt-0"></ngb-progressbar>
      </span>

      <!-- Dropdown request actions -->
      <span class="float-end">
        <mobile-status-actions></mobile-status-actions>
      </span>
    </th>
  </tr>
  <tr class="mat-mdc-row cols-12 mobile" *ngFor="let column of displayedColumns">
    <td class="mat-mdc-cell mdc-data-table__cell col-4 fw-bold" translate="Table.{{column.transTag}}"></td>
    <td class="mat-mdc-cell mdc-data-table__cell col-8">
      <app-status-display *ngIf="column.columnName === 'status'" [status]="tableData.status"
        [displayStatusString]="false" [displayStatusOrb]="true" class="badge-status-table"></app-status-display>
        {{ tableData[column.columnName] | valueByTypePipe : column.type }}
        <!-- Add copy to clipboard function, based on datatype Guid -->
        <ng-container *ngIf="column.type === 'Guid'">
          <em class="bi bi-files cursor-pointer copy-icon" aria-hidden="true" (click)="copyToClipboard(tableData[column.columnName])"></em>
        </ng-container>
    </td>
  </tr>
</table>
</div>