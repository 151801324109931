import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-formcontrol-error-messages',
    templateUrl: './formcontrol-error-messages.component.html'
})
export class FormcontrolErrorMessagesComponent {

    @Input("forControl") inputFormControl!: FormControl;

}
