<div class="modal-body m-0 m-lg-3" style="z-index: 9999;">
    <div class="mb-4">
        <h3 ngbAutofocus translate="Request.View.WaitingOtherPeople.WaitingForOtherPeople"></h3>
    </div>
    <div class="row row-cols-12 default-font-size">
        <app-action-summary [actions]="actions" [documents]="documents" [requestStatus]="requestStatus">
        </app-action-summary>
    </div>

    <p class="default-font-size" translate="Request.View.WaitingOtherPeople.ReceivingDownloadlink"></p>
</div>