import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StringHelper } from 'src/app/directives/string-helper';

@Component({
  templateUrl: './perform-action-reject.modal.html'
})
export class PerformedActionRejectModal {

  @Input() requestName!: string;
  /**
   * Output of this modal, when rejected with the reason
   */
  @Output() rejectedWithReason: EventEmitter<string> = new EventEmitter();

  
  MAX_ALLOWED_LENGTH = 500;
  stringHelper = new StringHelper();

  rejectionReasonControl: FormControl;

  constructor(private activeModal: NgbActiveModal) {
    this.rejectionReasonControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(this.MAX_ALLOWED_LENGTH)
    ]);
  }


  close() {
    this.activeModal.close();
  }

  reject() {
    let rejectionReason: string = '';
    if (this.rejectionReasonControl.valid)
      rejectionReason = this.stringHelper.ReplaceRNToBr(this.rejectionReasonControl.value);
    this.rejectedWithReason.emit(rejectionReason);
  }
}
