import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BadgeStatusEnum } from 'src/app/constants/badge-status.enum';
import { ActorStatusEnum, ActorStatusInformationModel } from 'src/app/models/api/actor-status-information.model';
import { TableColumn } from 'src/app/models/tableColumn';
import { RequestStatusService } from '../../requeststatus/requeststatus.service';
import { StatusRequest } from 'src/app/models/requests';
import { Subscription } from 'rxjs';
import { RequestStatusActionsEnum } from 'src/app/constants/RequestStatusActions.enum';

@Directive()

export class BaseTableClass implements OnDestroy {
  @Input() tableData: any[] = [];
  protected _displayedColumns: TableColumn[] = [];
  public columnsToDisplay: string[] = [];
  @Input() set displayedColumns(columns: TableColumn[]) {
    this._displayedColumns = columns;
  }
  get displayedColumns(): TableColumn[] {
    this.columnsToDisplay = this._displayedColumns.map((s: TableColumn) => s.columnName);
    return this._displayedColumns;
  }

  protected request!: StatusRequest;
  protected requestGuid!: string;
  protected requestSubscription: Subscription | null = null;

  constructor(protected requestStatusService: RequestStatusService){
    this.requestSubscription = this.requestStatusService.selectedRequest$.subscribe(request => {
      this.request = request;
      this.requestGuid = request.id;
    })
  }

  ngOnDestroy(): void {
    if(this.requestSubscription){
      this.requestSubscription.unsubscribe();
    }
  }
 
  public isActionAllowed() {
    let actionsAvailable = this.displayedColumns.findIndex(s => s.columnName === 'actions');
    return (actionsAvailable > -1);
  }

  public isPossibleToViewRendering(document: any){
    return document.documentType !== 'UnrenderedFile' &&  !document.name.endsWith('xml');
  }

  public performActionForAnId(actionName: string, id: string) {
    switch (actionName) {
      case RequestStatusActionsEnum.editPerson:
        this.requestStatusService.getRightActor(id);
        this.requestStatusService.editPerson();
        break;
      case RequestStatusActionsEnum.changeDeadline:
        this.requestStatusService.getRightActor(id);
        this.requestStatusService.changeDeadline();
        break;
      case RequestStatusActionsEnum.changeDeadlineDays:
        this.requestStatusService.getRightActor(id);
        this.requestStatusService.changeDeadlineDays();
        break;
      case RequestStatusActionsEnum.resendRequest:
        this.requestStatusService.getRightActor(id);
        this.requestStatusService.resendRequest();
        break;
      case RequestStatusActionsEnum.openPreview:
        if(this.request.documentInfo.findIndex(document => document.id === id) > -1){
          let document = this.request.documentInfo[this.request.documentInfo.findIndex(document => document.id === id)]; 
          this.requestStatusService.openPreview(document);
        }
        break;
      default:
        break;
    }
  }

  // change function if data connected
  public convertedStatusToBadgeStatus(status: string): BadgeStatusEnum {
    if (!status) {
      return BadgeStatusEnum.Info;
    }
    switch (status) {
      case ActorStatusEnum.Waiting:
        return BadgeStatusEnum.Info;
      case ActorStatusEnum.Completed:
        return BadgeStatusEnum.Success;
      case ActorStatusEnum.Pending:
        return BadgeStatusEnum.Warning;
      case ActorStatusEnum.Error:
      case ActorStatusEnum.TriggeredSpecialStatus:
        return BadgeStatusEnum.Danger;
      // As default (primary)/info
      default:
        return BadgeStatusEnum.Info
    }
  }

  copyToClipboard(Guid: string) {
    this.requestStatusService.copyToClipboard(Guid);
  }
}

