import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AwaitingOtherPeopleModalOptions, PerformedActionAwaitingOtherPeopleModal } from '../components/performaction/modals/performed-action-awaiting-other-people/performed-action-awaiting-other-people.modal';
import { DocumentActor } from '../models/actors';
import { AwaitingFilingModalOptions, PerformedActionAwaitingFilingModal } from '../components/performaction/modals/performed-action-awaiting-filing/performed-action-awaiting-filing.modal';
import { WithdrawRequestModal, WithdrawRequestModalOptions } from './withdraw-request/withdraw-request.modal';
import { ViewXbrlOrTxtModal, ViewXbrlOrTxtModalOptions } from './view-xbrl/view-xbrl-or-txt.modal';
import { DocumentModel } from '../models/document.model';
import { ViewXmlModal, ViewXmlModalOptions } from './view-xbrl/view-xml.modal';
import { ToastService } from '../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { CloudDocumentModalOptions } from './cloud-document/base-cloud-document.modal';
import { CloudDocumentUploadModal, } from './cloud-document/cloud-document-upload.modal';
import { CloudDocumentExportModal } from './cloud-document/cloud-document-export.modal';
import { DocumentTypeEnum } from '../constants/document-type.enum';
import { CreditSummaryModel } from '../models/CreditSummary.model';
import { CreditSummaryExplanationModal, CreditSummaryExplanationModalOptions } from './credit-summary-explanation/credit-summary-explanation.modal';
import { RequestModel, StatusRequest } from '../models/requests';
import { Subscription } from 'rxjs';
import { StatusViewLogModal, StatusViewLogModalOptions } from './status-view-log/status-view-log.modal';

@Injectable({
    providedIn: 'root'
})
export class ModalOpenerService {

    modalSubscriptionShown: Subscription = new Subscription();
    modalSubscriptionClosed: Subscription = new Subscription();

    constructor(private ngbModal: NgbModal, private toastService: ToastService, private translateService: TranslateService) { }

    ngOnDestroy(): void {
        if (this.modalSubscriptionShown) {
            this.modalSubscriptionShown.unsubscribe();
        }
        if (this.modalSubscriptionClosed) {
            this.modalSubscriptionClosed.unsubscribe();
        }
    }

    public Open_ViewXblrOrTxt(request: RequestModel, document: DocumentModel): void {
        const modalRef = this.ngbModal.open(ViewXbrlOrTxtModal, ViewXbrlOrTxtModalOptions);
        modalRef.componentInstance.request = request;
        modalRef.componentInstance.documentId = document.id;
        modalRef.componentInstance.documentType = document.documentType;
    }

    public Open_ViewXml(requestId: string, document: DocumentModel): void {
        if (document.documentType == DocumentTypeEnum.DetachedSignature) {
            // i cannot do this mate
            this.toastService.showWarning(this.translateService.instant("Toasts.InvalidFileFormatForViewingTitle"), this.translateService.instant("Toasts.InvalidFileFormatForViewingContent"));
            return;
        }
        const modalRef = this.ngbModal.open(ViewXmlModal, ViewXmlModalOptions);
        modalRef.componentInstance.requestId = requestId;
        modalRef.componentInstance.documentId = document.id;
        modalRef.componentInstance.documentType = document.documentType;
    }

    /**
     * Returns a NgbModalRef for ComponentInstance: WithdrawRequestModal
     */
    public Open_WithDrawnRequest(requestIds: string[]): NgbModalRef {
        const modalRef = this.ngbModal.open(WithdrawRequestModal, WithdrawRequestModalOptions);
        (modalRef.componentInstance as WithdrawRequestModal).selectedRequestIds = requestIds;
        return modalRef;
    }


    public Open_CreditSummaryExplanation(creditSummary: CreditSummaryModel): void {
        const modalRef = this.ngbModal.open(CreditSummaryExplanationModal, CreditSummaryExplanationModalOptions);
        modalRef.componentInstance.creditSummary = creditSummary;

    }

    public Open_CloudDocumentUpload(requestId: string, enabledPlugins: any[], supportedDocumentTypes: string[] | undefined, allowMultiselect: boolean = true): NgbModalRef {
        const modalRef = this.ngbModal.open(CloudDocumentUploadModal, CloudDocumentModalOptions);
        modalRef.componentInstance.requestId = requestId;
        modalRef.componentInstance.enabledPlugins = enabledPlugins;
        modalRef.componentInstance.acceptedDocumentTypes = supportedDocumentTypes;
        modalRef.componentInstance.allowMultiselect = allowMultiselect;
        return modalRef;
    }

    public Open_CloudDocumentExport(enabledPlugins: any[]): NgbModalRef {
        const modalRef = this.ngbModal.open(CloudDocumentExportModal, CloudDocumentModalOptions);
        modalRef.componentInstance.enabledPlugins = enabledPlugins;
        modalRef.componentInstance.allowMultiselect = false;
        return modalRef;
    }

    public Open_StatusViewLog(request: StatusRequest): NgbModalRef {
        const modalRef = this.ngbModal.open(StatusViewLogModal, StatusViewLogModalOptions);
        modalRef.componentInstance.request = request;
        return modalRef;
    }
}
