
/**
 * Should match BE EventLogTypeEnum
 * summaries are in BE (Entities-EventLog-Enum)
 */
export enum EventLogTypeEnum {

    // Request Events
    /** 
    * When there isn't an Specific event, just a general Logging.
    **/
    Generic = "Generic",
    /** 
    * The request was created
    **/
    Created = "Created",
    /** 
    * The request has been 'Send' (made active and the actors have been invited)
    **/
    Send = "Send",
    /** 
    * The request has been 'Completed'
    **/
    Completed = "Completed",
    /** 
    * The request has been 'Purged' by user action
    **/
    RequestPurgedByUser = "RequestPurgedByUser",

    // Owner Events
    /** 
    * The request has been withdrawn
    **/
    Withdrawn = "Withdrawn",
    /** 
    * A reminder has been send to an actor
    **/
    ReminderSend = "ReminderSend",
    /** 
    * The deadline of an actor has been edited
    **/
    DeadlineEdited = "DeadlineEdited",
    /** 
    * The deadline days of an actor has been edited
    **/
    DeadlineDaysEdited = "DeadlineDaysEdited",
    /** 
    * The request has been downloaded (NOT AS ACTOR ACTION)
    **/
    RequestDownloaded = "RequestDownloaded",
    /** 
    * The request has been Restored from the purge status
    **/
    RequestRestored = "RequestRestored",
    /** 
    * An actor on this request has been updated
    **/
    RequestUpdatedActor = "RequestUpdatedActor",

    // System events
    /**
     * The Request expired and became 'Purged' based on time
     */
    RequestPurgedSystem = "RequestPurgedSystem",

    // Actor Events
    /** 
    * The actor 'Logged in' / Authenticated
    **/
    ActorAuthenticated = "ActorAuthenticated",
    /** 
    * The actor completed a Sign action
    **/
    ActorSigned = "ActorSigned",
    /** 
    * The actor completed a Approve action
    **/
    ActorApproved = "ActorApproved",
    /** 
    * The actor completed a Download action
    **/
    ActorDownloaded = "ActorDownloaded",
    /** 
    * The actor completed a Adopt action
    **/
    ActorAdopted = "ActorAdopted",
    /** 
    * The actor completed a Linking action
    **/
    ActorLinked = "ActorLinked",

    /** 
    * The actor completed a File action
    **/
    ActorFiled = "ActorFiled",
    /** 
    * The actor Rejected the request
    **/
    ActorRejected = "ActorRejected",
    /** 
    * The actor got struck by an aligator
    **/
    ActorAligatored = "ActorAligatored",
    /**
     * The Actor's action expired
     */
    ActorInviteExpired = "ActorInviteExpired",

    // Workflows
    /** 
    * The request was filed to the external receiver
    **/
    RequestFiledToReceiver = "RequestFiledToReceiver",
    /** 
    * The filing result was succesfull
    **/
    RequestFilingSuccesfull = "RequestFilingSuccesfull",
    /** 
    * The filing result was a failure
    **/
    RequestFilingFailure = "RequestFilingFailure",

    // Email Activities
    /** 
    * Email activity read, the email was delivered to
    **/
    EmailActivityRegistered_Delivered = "EmailActivityRegistered_Delivered",

    /** 
    * Email activity read, the email was Clicked by
    **/
    EmailActivityRegistered_Clicked = "EmailActivityRegistered_Clicked",
    /** 
    * Email activity read, the email was Opened by
    **/
    EmailActivityRegistered_Opened = "EmailActivityRegistered_Opened",

    /** 
    * Email activity read, the email was 'Processed' for
    **/
    EmailActivityRegistered_Processed = "EmailActivityRegistered_Processed",

    /** 
    * Email activity read, but the event was something other than the expected
    **/
    EmailActivityRegistered_Other = "EmailActivityRegistered_Other",

    /** 
    * Starting umbrella for any ERROR email activity read and added to the request
    **/
    EmailActivityFailure = "EmailActivityFailure",
}

