<div class="mb-1" *ngIf="driveData">
    <ul class="d-flex list-group cloudlocationslist cursor-pointer">
        <li class="list-group-item subheader cloudlocations d-flex justify-content-between align-items-center">
          <div class="col-9 text-truncate">
              <strong class="text-decoration-none text-primary" (click)="getRootAccess()" translate>CloudLocations.Root</strong> 
              <ng-container *ngIf="parentFolder">
                <span class="ms-2">
                / <a class="text-decoration-none text-primary ms-1" (click)="backToDirectory()" *ngIf="selectedDirectory.length > 0">{{parentFolder.name}}</a> 
                </span>
              </ng-container>
              <span class="text-secondary ms-2" *ngIf="currentFolder">/ <span class="ms-1">{{currentFolder.name}}</span></span>
          </div>
          <div class="col-3">
            <strong translate>CloudLocations.Size</strong>
          </div>
        </li>

        <ng-container *ngFor="let node of driveData; int let i = index">     
            <ng-container *ngIf="node.isFolder == true">
                <li class="list-group-item cloudlocations d-flex justify-content-between align-items-center">
                    <div class="col-12 text-truncate">
                        <i class="bi bi-folder2 me-2"></i>
                        <a class="text-decoration-none text-primary" (click)="showFiles(node.parent,node.id,node.name)">{{node.name}}</a>
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="showFilesInFolder && node.isFolder == false && ((node.name.substring(node.name.lastIndexOf('.')).toLowerCase()).replace('.', '') == 'pdf' || (node.name.substring(node.name.lastIndexOf('.')).toLowerCase()).replace('.', '') == 'xbrl')">
                <li class="list-group-item cloudlocations d-flex justify-content-between align-items-center" id="{{node.id}}">
                <div class="col-9 text-truncate">
                    <input type="checkbox" (change)="onCheckItem($event, node.id)" class="mt-1 d-inline-block form-check-input checkbox" name="item" id="checkbox_{{node.id}}" value="">
                    <a class="text-decoration-none text-reset" (click)="importFile(node.parent, node.id)">{{node.name}}</a>
                    </div>
                <div class="col-3 text-truncate">
                    <span>{{ node.size | byteSizePipe }}</span>
                </div>
                </li>
            </ng-container>
        </ng-container>
        <li *ngIf="!showFilesInFolder && !parentFolderHasChildren()" class="list-group-item cloudlocations d-flex justify-content-between align-items-center height33px">
        </li>
      </ul>
</div>

<div class="d-table w-100">
    <span class="float-start"><button class="btn btn-sm btn-outline-primary mt-4 me-2" (click)="closeDialog()" translate>Button.Close</button></span>
    <span class="float-end" *ngIf="driveData">
        {{this.externalFiles.lengths}}
        <button class="btn btn-sm btn-primary mt-4" *ngIf="showFilesInFolder" [disabled]="(!this.externalFiles || this.externalFiles.length == 0) ? true : false" (click)="importFilesAction(this.dirId)" translate>Button.Add</button>
        <button class="btn btn-sm btn-primary mt-4" *ngIf="!showFilesInFolder && currentFolderId" (click)="exportFilesAction()" translate>Button.SaveFilesHere</button>
    </span>
</div>
