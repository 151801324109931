import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { Workflow } from "../models/workflow";
import { WorkflowListItem } from "../models/workflowlistitem";
import { PlatformSettings } from "../constants/settings.enum";

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  private possibleWorkFlows: WorkflowListItem[] = [
    // GENERIC
    { "id": "bc2bfdf3-b079-49a2-8b93-717b8ebaa189", fileName: 'zelf-ondertekenen', workflowType: WorkFlowTypeEnum.Generic, translationKey: "WorkFlowRequests.RequestTypeNames.SignYourself", bySettingName: PlatformSettings.Allow_WF_SignYourself, isDefaultWorkflow: false },
    { isDefaultWorkflow: true, "id": "de65f26a-a02e-4ca1-9356-d8cd2b83477a", fileName: 'ondertekenverzoek', workflowType: WorkFlowTypeEnum.Generic, translationKey: "WorkFlowRequests.RequestTypeNames.SignatureRequest", bySettingName: PlatformSettings.Allow_WF_SignatureRequest },
    { "id": "79831102-fd26-43e0-aebb-70f09e8f5e13", fileName: 'veilig-verzenden', workflowType: WorkFlowTypeEnum.Generic, translationKey: "WorkFlowRequests.RequestTypeNames.SecureSharing", bySettingName: PlatformSettings.Allow_WF_SecureFileSharing, isDefaultWorkflow: false },
    // TAX
    { "id": "eb34eb49-7828-4eef-afd0-41771544199b", fileName: 'inkomstenbelasting', workflowType: WorkFlowTypeEnum.Tax, translationKey: "WorkFlowRequests.RequestTypeNames.IncomeTax", bySettingName: PlatformSettings.Allow_WF_IncomeTax, isDefaultWorkflow: false },
    { "id": "35927385-d6e4-4bff-877e-fd8f57dc1ba2", fileName: 'vennootschapbelasting', workflowType: WorkFlowTypeEnum.Tax, translationKey: "WorkFlowRequests.RequestTypeNames.CorporateTax", bySettingName: PlatformSettings.Allow_WF_CorporateTax, isDefaultWorkflow: false },
    { "id": "eca3964f-a51c-4f91-8e01-018ae6d80f2a", fileName: 'omzetbelasting', workflowType: WorkFlowTypeEnum.Tax, translationKey: "WorkFlowRequests.RequestTypeNames.Vat", bySettingName: PlatformSettings.Allow_WF_VAT, isDefaultWorkflow: false },
    { "id": "b57cc769-34be-4713-8378-bb1dca5f185e", fileName: 'opgave-icp', workflowType: WorkFlowTypeEnum.Tax, translationKey: "WorkFlowRequests.RequestTypeNames.EuVat", bySettingName: PlatformSettings.Allow_WF_EUVAT, isDefaultWorkflow: false },
    // Chamber of Commerce
    { "id": "d9acd49e-6c4c-44fd-b524-f9c2fb5f5dfc", fileName: 'deponeren', workflowType: WorkFlowTypeEnum.ChamberOfCommerce, translationKey: "WorkFlowRequests.RequestTypeNames.FilingDefault", bySettingName: PlatformSettings.Allow_WF_Filing, isDefaultWorkflow: false },
    { "id": "92e3a74c-9080-4734-a07d-7ff6ce320a41", fileName: 'deponeren-met-verklaring', workflowType: WorkFlowTypeEnum.ChamberOfCommerce, translationKey: "WorkFlowRequests.RequestTypeNames.FilingAudit", bySettingName: PlatformSettings.Allow_WF_FilingWithReport, isDefaultWorkflow: false },
    { "id": "094f1703-0d2a-4567-91d6-947271ac1036", fileName: 'linking-and-signing', workflowType: WorkFlowTypeEnum.ChamberOfCommerce, translationKey: "WorkFlowRequests.RequestTypeNames.LinkingAndSigning", bySettingName: PlatformSettings.Allow_WF_LinkingAndSigning, isDefaultWorkflow: false },
    // Sbr Nexus
    { "id": "3f4f37c1-9256-4bee-b04c-db14991bc7ff", fileName: 'sbr-banken', workflowType: WorkFlowTypeEnum.SbrNexus, translationKey: "WorkFlowRequests.RequestTypeNames.SbrBankDefault", bySettingName: PlatformSettings.Allow_WF_SbrBank, isDefaultWorkflow: false },
    { "id": "ecf83848-0bf8-4d06-b1f6-a2b5e5077b32", fileName: 'sbr-banken-met-verklaring', workflowType: WorkFlowTypeEnum.SbrNexus, translationKey: "WorkFlowRequests.RequestTypeNames.SbrBankAudit", bySettingName: PlatformSettings.Allow_WF_SbrBankWithReport, isDefaultWorkflow: false },
    { "id": "6a4f7399-b020-471c-8ebc-27489f5e7bc0", fileName: 'noab-rapportage', workflowType: WorkFlowTypeEnum.SbrNexus, translationKey: "WorkFlowRequests.RequestTypeNames.NoabBankAudit", bySettingName: PlatformSettings.Allow_WF_NoabBankAudit, isDefaultWorkflow: false },
    // WWft
    { "id": "83691318-87f4-438f-bd8c-ead2042b5dbc", fileName: 'wwft', workflowType: WorkFlowTypeEnum.Wwft, translationKey: "WorkFlowRequests.RequestTypeNames.Wwft", bySettingName: PlatformSettings.Allow_WF_Wwft, isDefaultWorkflow: false },
  ];
  constructor(private httpClient: HttpClient) { }

  async getWorkflowList(): Promise<WorkflowListItem[]> {
    // This will be changed to a backend call, once the workflows are registered in the database.
    return this.possibleWorkFlows;
  }
  /**
   * For those times that somehow (agent as example) we end up WITHOUT a workflow(Guid), which workflow is the GOLD standard and should be used.
   * We return Guid instead of workflow so the guid can be used the same way as a set workflow guid would
   */
  getDefaultWorkflowGuid() {
    // [0] is not fancy, but there should ALWAYS BE ONE, now it works with multiple, and if somehow the singular TRUE is removed thats fine
    return this.possibleWorkFlows.filter(workflow => workflow.isDefaultWorkflow)[0].id;
  }
  async getWorkflowById(workflowId: string): Promise<Workflow> {
    let matchedWorkflow = this.possibleWorkFlows.filter(wf => wf.id == workflowId)[0];
    // This will be changed to a backend call, once the workflows are registered in the database.
    let filePath = `assets/workflows/${matchedWorkflow.fileName.toLowerCase()}.json`
    let workflow = await lastValueFrom(this.httpClient.get(filePath));
    return workflow as Workflow;
  }
}
export enum WorkFlowTypeEnum {
  Generic = 1,
  Tax = 2,
  ChamberOfCommerce = 3,
  SbrNexus = 4,
  Wwft = 5
}
