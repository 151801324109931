<ng-container *ngIf="dateSigned">
    {{ dateSigned | valueByTypePipe : DataTypeEnum.DateTime }}
</ng-container>
<ng-container *ngIf="!dateSigned && deadline">
    {{ deadline | valueByTypePipe : DataTypeEnum.DateTime }}
</ng-container>
<ng-container *ngIf="!dateSigned && !deadline && deadlineDays">
    <span *ngIf="deadlineDays === 1" translate="Request.Status.DaysToReadableText.Selected_Day"
        [translateParams]="{days: deadlineDays}">
    </span>
    <span *ngIf="deadlineDays > 1 && deadlineDays < 31"
        translate="Request.Status.DaysToReadableText.Selected_Days"
        [translateParams]="{days: deadlineDays}">
    </span>
    <span *ngIf="deadlineDays === 31" translate="Request.Status.DaysToReadableText.Selected_Month"
        [translateParams]="{month: 1}">
    </span>
    <span *ngIf="deadlineDays > 31" translate="Request.Status.DaysToReadableText.Selected_Months"
        [translateParams]="{months: deadlineDays === 62 ? 2 : 3}">
    </span>
</ng-container>