import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonenumber31To0',
  pure: false
})
export class PhoneNumber31To0Pipe implements PipeTransform {

  transform(value: string | null) {
    if(value == null) {
      return null;
    }

    let replacedValue = value.replace("+31", "0")
    return replacedValue
  }
}