import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token: string | undefined;
  constructor(public oidcSecurityService: OidcSecurityService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.oidcSecurityService.getAccessToken().subscribe((token) => this.token = token);
    
    if (this.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`
        }
      });
    }

    let slot=document.cookie.split('; ').find(row => row.startsWith("slot="))?.split('=')[1];
    if(slot)
      request=request.clone({
        setParams: {"x-ms-routing-name":slot}
      });
    
    return next.handle(request);
  }
}
