export const environment = {
  production: true,
  apiUrl: "https://platformapi.pkisigning.io",
  identityUrl: "https://identity.pkisigning.io",
  signingApiUrl: "https://engine.pkisigning.io",
  platformname: "Productie",
  enableTaxPaymentIntegration:true,
  applicationInsightsKey: "db02ead0-9161-41f0-b70b-523f4138daf7",
  applicationFullName: "PlatformV2PKIsigning"
};
