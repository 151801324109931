import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Crumb } from 'src/app/models/crumb';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CloudLocationsService } from 'src/app/services/cloudlocations.service';
import { PageService } from 'src/app/services/pages.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-cloud-document-list',
  templateUrl: './cloud-document-list.component.html',
  styleUrls: ['./cloud-document-list.component.css']
})
export class CloudDocumentListComponent {
  @Input() showFilesInFolder: boolean = true;
  @Input() provider!: string;
  @Input() source!: string;
  @Input() driveData: any;
  @Input() requestId!: string;
  @Input() allowMultiselect: boolean = true;
  @Output() setUrl: EventEmitter<string> = new EventEmitter<string>();
  @Output() getRoot: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() acceptedDocumentTypes: string[] | undefined = [];
  public cloudProviderName!: string;
  isLoading = true;
  IsInArray = false; 
  directoryInArray: boolean = false;
  driverLevel = 0;
  dirId: any;
  externalFiles: any;
  selectedDirectory: Crumb[] = [];
  translations = <any>{};
  parentFolder: any = "";
  parentFolderId: string = "";
  currentFolder: any = "";
  currentFolderId: string = "";
  foldersAvailable: number = 0;
  
  constructor(
    public activeModal: NgbActiveModal,
    private cloudLocationsService: CloudLocationsService,
    private translateService: TranslateService
  ) 
  {
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }

  getTranslations() {
    this.translateService.get([
      'CloudLocations.ProviderConnectError',
      'CloudLocations.Afas',
      'CloudLocations.Msgraph',
      'CloudLocations.SharePoint',
      'CloudLocations.OneDrive',
      'CloudLocations.Teams',
      'CloudLocations.Pinkwebdossier',
      'Toasts.Error'
    ]).subscribe(translation => {
      this.translations.ProviderConnectError = translation['CloudLocations.ProviderConnectError'];
      this.translations.Afas = translation['CloudLocations.Afas'];
      this.translations.Msgraph = translation['CloudLocations.Msgraph'];
      this.translations.SharePoint = translation['CloudLocations.SharePoint'];
      this.translations.OneDrive = translation['CloudLocations.OneDrive'];
      this.translations.Teams = translation['CloudLocations.Teams'];
      this.translations.Pinkwebdossier = translation['CloudLocations.Pinkwebdossier'];
      this.translations.error = translation['Toasts.Error'];
    });
  }

  closeDialog() {
    this.externalFiles = [];
    this.selectedDirectory = [];
    this.close.emit();
  }

  getRootAccess() {
    this.removeAllCrumbs();
    this.getRoot.emit();
  }
  
  showFiles(parent:any,id:any,name:any) {
    this.parentFolderId = parent;
    this.currentFolderId = id;
    for (let i: number = 0; i < this.selectedDirectory.length; i++) {
      if(this.selectedDirectory[i].id == id) {
        this.IsInArray = true;
        this.driverLevel = i;
        break;
      }
    }
    if(this.IsInArray) {
      this.cloudLocationsService.getParentChildContents(this.provider,this.source,parent,id).subscribe(data => {
        this.driveData = data;
        this.parentDirectory();
        for (let i = this.driverLevel + 1, j = this.selectedDirectory.length; j > i; j--) {
          this.selectedDirectory.pop();
        }
      });
    } else {
      this.directoryInArray = false;
      if (parent && parent!=="") {
        this.cloudLocationsService.getParentChildContents(this.provider,this.source,parent,id).subscribe(data => {
          this.driveData = data;
          this.parentDirectory();
          this.selectedDirectory.forEach(item => { if (item.id === id) { this.directoryInArray = true; }});
          if (this.driveData) {
            if ((this.selectedDirectory[this.selectedDirectory.length - 1].id !== id) && (!this.directoryInArray)) {
              this.addCrumb(name, id, parent);
              this.isLoading = false;
            }
          }
        });
      } else {
        this.cloudLocationsService.getChildContents(this.provider,this.source,id).subscribe(data => {
          this.driveData = data;
          this.parentDirectory();
          if (this.driveData) {
            if (this.selectedDirectory.length < 1) {
              this.addCrumb(name, id, "");
              this.isLoading = false;
            }
          }
        });
      }
    }
    this.IsInArray = false;
  }

  parentDirectory() {
    if (this.driveData) {
      for (let k: number = 0; k < this.driveData.length; k++) {
        if (this.driveData[0]) { 
          if (this.driveData[0].parent) { this.dirId = this.driveData[0].parent; } else { this.dirId = this.driveData[0].id; }
        }
      }
      this.externalFiles = [];
    }
  }

  addCrumb(directory:string, id:string, parent:string) {
    this.selectedDirectory.push(
      new Crumb(directory, id, parent)
    );
    this.breadCrumb();
  }

  removeCrumb(){
    this.selectedDirectory.pop();
    this.breadCrumb();
  }

  removeAllCrumbs() {
    this.selectedDirectory = [];
    this.breadCrumb();
  }

  breadCrumb() {
    this.parentFolder = this.selectedDirectory[this.selectedDirectory.length - 2];
    this.currentFolder = this.selectedDirectory[this.selectedDirectory.length - 1];
  }

  onCheckItem(event:any, value:string) {
    let checkbox = (event.target as HTMLInputElement);
    if (checkbox.checked) {
      document.getElementById(value)!.classList.add('active');
      if (!this.allowMultiselect) {
        this.uncheckAllSelectedFiles();
      }
      this.externalFiles.push(value);
    } 
    if (!checkbox.checked) {
      document.getElementById(value)!.classList.remove('active');
      let index = this.externalFiles.indexOf(value);
      if (index > -1) {
        this.externalFiles.splice(index, 1);
      }
    }
  }

  private uncheckAllSelectedFiles() {
    this.externalFiles.forEach((file: string) => {
      (document.getElementById("checkbox_" + file) as HTMLInputElement).checked = false;
    })
    this.externalFiles = [];
  }

  backToDirectory() {
    let previousDirectory = this.selectedDirectory[this.selectedDirectory.length - 2];
    if (previousDirectory) {
      let parent = previousDirectory.parent;
      let id = previousDirectory.id;
      this.parentFolderId = parent;
      this.currentFolderId = id;
      if(parent && parent!=="")
        this.cloudLocationsService.getParentChildContents(this.provider,this.source,parent,id).subscribe(
          data => {
          this.driveData = data;
          this.parentDirectory();
          this.isLoading = false;
          this.removeCrumb();
        });
        else
        this.cloudLocationsService.getChildContents(this.provider,this.source,id).subscribe(data => {
          this.driveData = data;
          this.parentDirectory();
          this.isLoading = false;
          this.removeCrumb();
        });
    } else {
      this.getRoot.emit();
    }
  }

  async importFile(dirId: string, fileId:string) {
    this.externalFiles.push(fileId);
    await this.importFilesAction(dirId);
  }
  
  async importFilesAction(id:string) {
    let name = this.driveData.filter((d: any) => d.id == this.externalFiles[0])[0].name;
    this.close.emit({provider: this.provider, id: id, externalFiles: this.externalFiles, name: name });
  }
  
  async exportFilesAction() {
    this.close.emit({provider: this.provider, parentId: this.parentFolderId, folderId: this.currentFolderId});
  }

  parentFolderHasChildren() {
    return this.driveData.filter((folder: any) => folder.isFolder).length > 0;
  }
}

