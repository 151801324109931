import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { BuilderRequest, DashboardRequest, RequestModel, StatusRequest } from '../models/requests';
import { DocumentModel } from '../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class RequestApiClient {

  constructor(private httpClient: HttpClient) { }


  async getRequest(dossierId: string) {
    return this.httpClient.get(environment.apiUrl + "/requests/"+ dossierId + "");
  }

  /**
   * Dit is nog dezelfde call als hieronder maar moet worden gebruikt voor het builder.
   */
  async getRequestForBuilder(dossierId: string): Promise<Observable<BuilderRequest>> {
    let request: Observable<BuilderRequest> = this.httpClient.get<BuilderRequest>(environment.apiUrl + "/requests/"+ dossierId + "/DashboardItem").pipe(
      map((req: BuilderRequest) => {
        let documents = req.documentInfo.filter(doc => !doc.documentType.toLocaleLowerCase().endsWith("html"));
        req.documentInfo = documents;
        return req;
      })
    );
    
    return request;
  }

  /**
   * Dit is nog dezelfde call als hieronder maar moet worden gebruikt voor het status.
   */
  async getRequestForStatus(dossierId: string): Promise<Observable<StatusRequest>> {
    let request: Observable<StatusRequest> = this.httpClient.get<StatusRequest>(environment.apiUrl + "/requests/"+ dossierId + "/DashboardItem").pipe(
      map((req: StatusRequest) => {
        let documents = req.documentInfo.filter(doc => !doc.documentType.toLocaleLowerCase().endsWith("html"));
        req.documentInfo = documents;
        return req;
      })
    );
    
    return request;
  }

  /**
   * Dit is nog dezelfde call als hierboven maar moet worden gebruikt voor de viewer.
   */
  getRequestForViewer(dossierId: string) : Observable<RequestModel> {
    return this.httpClient.get<RequestModel>(environment.apiUrl + "/requests/"+ dossierId + "/DashboardItem");
  }

  async downloadRequest(dossierId: string) {
    return this.httpClient.get(environment.apiUrl + "/requests/"+ dossierId + "/Download", { observe:'response', responseType: 'blob' });
  }

  async purgeRequest(dossierId: string) {
    let headers = new Headers();
    return this.httpClient.patch(environment.apiUrl + "/requests/"+ dossierId + "", { headers: headers });
  }

  async deleteRequest(dossierId: string) {
    return this.httpClient.delete(environment.apiUrl + "/requests/"+ dossierId + "");
  }
  
  restoreRequest(dossierId: string, name: string) {
    return this.httpClient.patch(environment.apiUrl + "/requests/"+ dossierId + "/Unrecycle", {"name": name});
  }
  extendDeadlinesIfNeeded(requestGuid: string) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + requestGuid + "/ExtendCleanupDeadlines", {});
  }

  getPaymentUrl(requestGuid: string) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + requestGuid + "/GetPaymentUrl", {}, { responseType: 'text'})
  }
}