import { Injectable } from "@angular/core";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: 'root'
})
/**
 * Services that stores stuff in Local Storage, with easy USUABLE functions
 */
export class LocalStorageService {

  /** Set the signingImage */
  public get signingImage(): string | null {
    return this._signingImage;
  };

  /** Holds the signingImage */
  public set signingImage(value: string | null) {
    this._signingImage = value;
    if (!value) localStorage.removeItem("SigningImage");
    else localStorage.setItem("SigningImage", this.signingImage!);
  };

  public get hasSigningImage() {
    return this._signingImage != null;
  }

  /** Set a Version number
   * This verifies it with the currently stored, and if CHANGED shows the new version popup
  */
  public verifyStoredVersionNumber(versionNumber: string, urlForPatchNotes :string) {
    this._storedVersionNumber = versionNumber;
    let previousStoredVersionNumber = localStorage.getItem("StoredVersionNumber");
    if (previousStoredVersionNumber != versionNumber) {
      this.toastService.DisplayNewVersion(versionNumber,urlForPatchNotes);
      localStorage.setItem("StoredVersionNumber", this._storedVersionNumber!);
    }

  };




  private _signingImage: string | null = null;
  private _storedVersionNumber: string | null = null;

  constructor(private toastService: ToastService) {
    // get initial signingImage
    let signingImage = localStorage.getItem("SigningImage");
    if (signingImage) {
      this._signingImage = signingImage!;
    }


    // get Version number of previous time
    let storedVersionNumber = localStorage.getItem("StoredVersionNumber");
    if (storedVersionNumber) {
      this._storedVersionNumber = storedVersionNumber!;
    }
  }

}