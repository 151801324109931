<div class="force-min-height container d-flex align-items-center">
    <div
        class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 standard-container-width container-fuid mx-auto bg-white p-0 shadow default-boxrounded">
        <div class="w-100 d-flex  mx-4">
            <img id="LogoHeader" src="{{whiteLabelProvider.IdentityLogoImageSrc}}" class="my-4" alt="Logo" />
        </div>
        <h3 class="mx-4 mt-1 mb-2" translate="Activation.AccountActivation">
        </h3>
        <div>
            <p class="mt-3 mx-4 lh-base" translate="Activation.WelcomeToPKIsigning">
            </p>
        </div>
        <div class="mt-3 mx-4">
            <div class="row">
                <form [formGroup]="form" (ngSubmit)="save()">
                    <input formControlName="userId" type="hidden">
                    <input formControlName="code" type="hidden">

                    <div class="mb-3 col-12">
                        <label for="firstname" class="form-label" translate="Activation.FirstName">
                        </label>
                        <input type="text" id="firstname" class="form-control" formControlName="firstname"
                            autocomplete="off" placeholder="{{ 'Activation.EnterFirstName' | translate}}">
                        <app-formcontrol-error-messages [forControl]="firstNameControl">
                        </app-formcontrol-error-messages>
                    </div>

                    <div class="mb-3 col-12">
                        <label for="prefix" class="form-label" translate="Activation.Preposition">
                        </label>
                        <input type="text" id="prefix" class="form-control" formControlName="prefix"
                            placeholder="{{ 'Activation.EnterPreposition' | translate}}" autocomplete="off">
                        <app-formcontrol-error-messages [forControl]="prefixControl">
                        </app-formcontrol-error-messages>
                    </div>

                    <div class="mb-3 col-12">
                        <label for="lastname" class="form-label" translate="Activation.LastName">
                        </label>
                        <input type="text" id="lastname" class="form-control"
                            placeholder="{{ 'Activation.EnterLastName' | translate}}" formControlName="lastname"
                            popupClass="dropdown-menu-person-dialog" autocomplete="off">
                        <app-formcontrol-error-messages [forControl]="lastNameControl">
                        </app-formcontrol-error-messages>
                    </div>

                    <div class="mb-3 col-12">
                        <label for="mobile" class="form-label" translate="Activation.Mobile">
                        </label>
                        <ngx-intl-tel-input class="phone-input" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Netherlands"
                            [phoneValidation]="true" [separateDialCode]="false"
                            [numberFormat]="PhoneNumberFormat.National" id="mobile" formControlName="mobile"
                            (keyup)="mobileTouched($event)"
                            (focusout)="mobileTouched($event)"
                            #mobileElement>
                        </ngx-intl-tel-input>
                        <app-formcontrol-error-messages *ngIf="phonenumberIsTouched" [forControl]="phoneControl"></app-formcontrol-error-messages>
                    </div>

                    <div class="mb-3 col-12">
                        <label for="password" class="form-label" translate="Activation.Password">
                        </label>
                        <input type="password" id="password" class="form-control"
                            placeholder="{{ 'Activation.EnterPassword' | translate}}" formControlName="password"
                            popupClass="dropdown-menu-person-dialog" autocomplete="off">
                        <app-formcontrol-error-messages [forControl]="passwordControl"></app-formcontrol-error-messages>
                    </div>

                    <div class="mb-3 col-12">
                        <label for="confirmPassword" class="form-label" translate="Activation.RetypePassword">
                        </label>
                        <input type="password" id="confirmPassword" class="form-control"
                            placeholder="{{ 'Activation.EnterPassword' | translate}}" formControlName="confirmPassword"
                            popupClass="dropdown-menu-person-dialog" autocomplete="off">
                        <app-formcontrol-error-messages
                            [forControl]="confirmPasswordControl"></app-formcontrol-error-messages>
                    </div>

                    <div class="mb-3 col-12">
                        <button type="submit" class="w-100 btn btn-primary ms-1 mb-3 order-2 float-end "
                            [disabled]="isAwaitingActivation" translate="Button.Confirm"></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>