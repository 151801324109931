<div class="modal-body modal-font p-3">   
    <div class="row row-cols-12 mb-2">
        <div class="fw-bolder col-12 text-truncate">            
            <span class="badge bg-primary perform-action-dialog-header-badgenumber rounded-pill me-2">
                <span ngbAutofocus class="align-middle text-white">{{expectedActionNumber}}</span>
            </span>            
            <span>{{"Request.Actions.Linking" | translate}} {{requestName}}</span>
        </div>
    </div> 

    <app-user-identification-details></app-user-identification-details>

    <div class="mt-4 d-table w-100">
        <span class="float-start">
            <button type="button" class="btn btn-sm btn-outline-primary" (click)="back();" translate="Button.Back"></button>
        </span>
        <span class="float-end">
            <button type="button" class="btn btn-sm btn-success ms-1 order-1 float-end" id="signBtn" (click)="clickLink();" [disabled]="!buttonEnabled" translate="Button.Link"></button>
        </span>
    </div>
</div>