<footer
  class="footer w-auto text-left ms-2 ms-sm-2 ms-md-4 ms-lg-5 ms-xl-5 ms-xxl-5 me-2 me-sm-2 me-md-4 me-lg-5 me-xl-5 me-xxl-5">
  <div class="w-100 pt-4">

    <!-- If display releasenotes true, show version and link -->
    <ng-container *ngIf="displayReleaseNotes()">
      <a (click)="ClickedOnVersion()">v{{version}}</a>
    </ng-container>

    <!-- If display releasenotes false, don't show link, only version -->
    <ng-container *ngIf="!displayReleaseNotes()">
      v{{version}}
    </ng-container>
    
  </div>
</footer>