@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './request-status-update-deadline-date.modal.html',
  providers: [NgbInputDatepickerConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class RequestStatusUpdateDeadlineDateModal implements OnInit {

  /** Initial set days */
  @Input() deadline: Date = new Date();
  /** Full Name of the person that you are changing */
  @Input() fullName: string = "";
  @Output() newPickedDeadlineEventEmitter: EventEmitter<Date> = new EventEmitter();
  /** allow Selecting of Earlier Deadlines than input? */
  @Input() useInitialAsMinDeadline = false;


  @ViewChild('mobileElement') mobileElement: any;
  form: UntypedFormGroup;
  minDate: { year: number; month: number; day: number; };
  minDeadline: Date = new Date(new Date().setDate(new Date().getDate() + 1));
  model: NgbDateStruct | undefined;
  date!: string;
  time!: string;
  minDateMobile!: string;
  startDate: { year: number; month: number; day: number; };


  constructor(private formBuilder: UntypedFormBuilder, public activeModal: NgbActiveModal) {
    this.form = this.formBuilder.group(
      {
        deadline: new UntypedFormControl('')
      });
    this.startDate = { year: this.minDeadline.getFullYear(), month: this.minDeadline.getMonth() + 1, day: this.minDeadline.getDate() };
    this.minDate = { year: this.minDeadline.getFullYear(), month: this.minDeadline.getMonth() + 1, day: this.minDeadline.getDate() };
  }


  ngOnInit(): void {
    this.form.controls['deadline'].setValue(this.deadline);

    let deadline = new Date(this.deadline);
    let hours = ("0" + deadline.getHours()).slice(-2);
    let minutes = ("0" + deadline.getMinutes()).slice(-2);
    this.time = hours + ":" + minutes;
    this.date = (new Date(deadline)).toISOString().substring(0, 10);
    this.startDate = { year: deadline.getFullYear(), month: deadline.getMonth() + 1, day: deadline.getDate() };
    this.minDateMobile = (new Date(deadline)).toISOString().substring(0, 10);

    if (this.useInitialAsMinDeadline) {
      this.minDate = { year: this.deadline.getFullYear(), month: this.deadline.getMonth() + 1, day: this.deadline.getDate() };
    }

  }

  hours(i: number) {
    return new Array(i);
  }

  changeDeadline(event: Event) {
    let dateString: string = String(event);
    let dateStringArray: string[] = dateString.split('-');
    this.deadline = new Date(Number(dateStringArray[2]), Number(dateStringArray[1]) - 1, Number(dateStringArray[0]), new Date(this.deadline).getHours(), new Date(this.deadline).getMinutes());
  }

  changeDeadlineMobile(event: Event) {
    let dateString: string = (event.target as HTMLInputElement).value;
    let dateStringArray: string[] = dateString.split('-')
    this.deadline = new Date(Number(dateStringArray[0]), Number(dateStringArray[1]) - 1, Number(dateStringArray[2]), new Date(this.deadline).getHours(), new Date(this.deadline).getMinutes());
  }

  changeDeadlineTime(event: Event) {
    let timeString: string = (event.target as HTMLInputElement).value;
    let timeStringArray: string[] = timeString.split(':');
    this.deadline = new Date(new Date(this.deadline).getFullYear(), new Date(this.deadline).getMonth(), new Date(this.deadline).getDate(), Number(timeStringArray[0]), Number(timeStringArray[1]));
  }

  changeDeadlineTimeMobile(event: Event) {
    let timeString: string = (event.target as HTMLInputElement).value;
    let timeStringArray: string[] = timeString.split(':');
    this.deadline = new Date(new Date(this.deadline).getFullYear(), new Date(this.deadline).getMonth(), new Date(this.deadline).getDate(), Number(timeStringArray[0]), Number(timeStringArray[1]));
  }

  back() {
    this.activeModal.close();
  }

  save() {
    this.newPickedDeadlineEventEmitter.emit(this.deadline);
  }
}
