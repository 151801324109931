import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DocumentActor } from 'src/app/models/actors';


export const ActionSummaryModalOptions: NgbModalOptions = {
  keyboard: false,
  centered: true,
  size: 'lg'
};

@Component({
  templateUrl: './action-summary.modal.html'
})
export class ActionSummaryModal {

  /** */
  @Input() myActors!: DocumentActor[];
  @Input() documents!: any[];
  @Input() requestStatus!: string;
  @Input() requestName!: string;

  get allActorsCompleted(): boolean {
    return this.myActors && this.myActors.every(actor => actor.hasSigned);
  }
  constructor(public activeModal: NgbActiveModal) {
  }

  close() {
    this.activeModal.close();
  }
}
