import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { AppComponent } from '../../components/app/app.component';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './onboarding-completed.page.html'
})
export class OnboardingCompletedPage implements OnInit {
  url: SafeUrl = "";

  constructor(private router: Router, private loggingService: LoggingService, public whiteLabelProvider: AppComponent) {
    // Must not be empty. It's approved by a comment.
  }

  async ngOnInit() {
    this.url = "https://" + window.location.host.toLowerCase();
  }

  login() {
    this.router.navigate(["/dashboard"])
      .catch(error => {
        this.loggingService.logException(error);
      });
  }
}

