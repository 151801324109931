import { Component } from '@angular/core';
import { User } from 'src/app/models/user';
import { ViewerDataService } from '../viewer-data.service';

@Component({
  selector: 'app-user-identification-details',
  templateUrl: './user-identification-details.component.html'
})
export class UserIdentificationDetailsComponent {
  ipAdress!: string;
  user!: User;
  
  constructor(private viewerDataService: ViewerDataService) {
    this.viewerDataService.getIpAdress$().subscribe(userIpAdress =>{
      this.ipAdress = userIpAdress;
    });
    this.user = this.viewerDataService.get_User;
  }
}
