import { Component } from '@angular/core';
import packageInfo from '../../../../../package.json';
import { PKIUrls } from 'src/app/constants/pkiurls.constants';
import { AppComponent } from '../../app/app.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  public version: string = packageInfo.version;
  public releasenotesUrl: string = PKIUrls.PatchNotes;

    constructor(public appComponent: AppComponent, private localStorageService: LocalStorageService) {
    this.releasenotesUrl = this.displayReleaseNotes() ? (this.appComponent.releasenotesUrl ?? PKIUrls.PatchNotes) : PKIUrls.PatchNotes;
    this.localStorageService.verifyStoredVersionNumber(this.version, this.releasenotesUrl);
  }

  displayReleaseNotes() {
    return this.appComponent.releasenotes;
  }

  public ClickedOnVersion() {
    if (this.displayReleaseNotes()) {
      var urlToUse = this.releasenotesUrl;
      window.open(urlToUse, '_blank');
    }
  }
}
