import { Component, Input } from '@angular/core';
import { ActorActionEnum } from 'src/app/constants/actor-action.enum';
import { DocumentTypeEnum } from 'src/app/constants/document-type.enum';
import { StatusEnum } from 'src/app/constants/status.enum';
import { DocumentActor } from 'src/app/models/actors';
import { ActorActionsApiClient } from 'src/app/services/api-clients/actor-actions.api-client';
import { ViewerDataService } from '../viewer-data.service';
import { ViewerModalService } from '../viewer-modal.service';

@Component({
  selector: 'app-action-summary',
  templateUrl: './action-summary.component.html'
})
export class ActionSummaryComponent {

  @Input() actions!: DocumentActor[];
  @Input() documents!: any[];
  @Input() requestStatus!: string;
  currentActor!: DocumentActor;

  constructor(private viewerDataService: ViewerDataService, private viewerModalService: ViewerModalService, private actorActionsApiClient: ActorActionsApiClient) {
  }
  GenerateActionId(forActor: DocumentActor) {
    return forActor.action + '_' + forActor.id;
  }

  ngOnInit(): void {
    this.viewerDataService.getCurrentActor$().subscribe(currentActor => {
      this.currentActor = currentActor;
    });
  }
  /**
   * Verifies for the action what kind of Orb colour it must be, based on Action And/Or Request
   */
  public getColourClassForAction(action: DocumentActor): string {

    // you have not yet done THIS action, but the request itself is rejected
    if (!this.isActionSigned(action) && this.isRequestDeclined) {
      return "bg-danger";
    }

    // you have not yet done THIS action, also not rejected the request but the request itself is Pending
    if (!this.isActionSigned(action) && this.isRequestPending) {
      return "bg-warning";
    }

    // you have not yet done THIS action, but the request is neither Pending nor Rejected
    if (!this.isActionSigned(action)) {
      return "bg-primary";
    }

    // You did this action
    if (this.isActionSigned(action)) {
      return "bg-success";
    }

    // fallback: same as you need to do this aciton
    return "bg-primary";

  }


  /**
   *  YOU STILL NEED TO DO THE ACTION - display action as current tense
   */
  public displayActionAsCurrentTense(action: DocumentActor): boolean {
    return !this.isActionSigned(action);
  }


  /**
   *  You did the action - display action as current tense
   */
  public displayActionAsPastTense(action: DocumentActor): boolean {
    // inversed of current tense. but otherwise, you DID the action
    return !this.displayActionAsCurrentTense(action);
  }
  public getMatchingDocName(action: any) {
    return this.documents.find(p => p.id == action.documentId)?.name
  }

  private isActionSigned(action: DocumentActor): boolean {
    return action.hasSigned;
  }

  private get isRequestDeclined(): boolean {
    return this.requestStatus == null || this.requestStatus === StatusEnum.Declined;
  }

  private get isRequestPending(): boolean {
    return !this.isRequestDeclined && (this.requestStatus === StatusEnum.Filing || this.requestStatus === StatusEnum.PendingDigipoort || this.requestStatus === StatusEnum.PendingSbrNexus);
  }
}
