import { Component, Directive, Input } from '@angular/core';
import { BaseTableClass } from './base_table.class';
import { ActorStatusEnum } from 'src/app/models/api/actor-status-information.model';

@Directive()
export class StatusActionsTableComponent extends BaseTableClass {
  @Input() override tableData: any;

  public hasDeclineMessage = (index: any, item: any): Boolean => item.declineMessage != null;

  public isWaiting(actorStatus: string) {
    return actorStatus === ActorStatusEnum.Waiting;
  }
}
@Component({
  selector: 'mobile-status-actions-table',
  templateUrl: './mobile_table_status_actions.component.html',
  styleUrls: ['./table.component.scss']
})

export class StatusActionsTableMobileComponent extends StatusActionsTableComponent {

}
@Component({
  selector: 'desktop-status-actions-table',
  templateUrl: './desktop_table_status_actions.component.html',
  styleUrls: ['./table.component.scss']
})

export class StatusActionsTableDesktopComponent extends StatusActionsTableComponent {

}