export enum ViewerHubEnum {
    RequestUpdated = 'RequestUpdated',
    ActionUpdated = 'ActionUpdated',
    DocumentLockedForSigning = 'DocumentLockedForSigning',
    DocumentUnlocked = 'DocumentUnlocked',
    SignStarted = 'SignStarted',
    SignFailed = 'SignFailed',
    SignDone = 'SignDone',
    SignStatus = 'SignStatus',
    RegisterForRequestUpdates = 'RegisterForRequestUpdates',
    UnRegisterForRequestUpdates = 'UnRegisterForRequestUpdates'
}

export enum ViewerHubEventEnum {
    Error = 'Error',
    RequestUpdated = ViewerHubEnum.RequestUpdated,
    ActionUpdated = ViewerHubEnum.ActionUpdated,
    DocumentLockedForSigning = ViewerHubEnum.DocumentLockedForSigning,
    DocumentUnlocked = ViewerHubEnum.DocumentUnlocked,
}