import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Subscription } from 'rxjs/internal/Subscription';
import packageInfo from '../../../package.json';
import { LoggingService } from './logging.service';
import { ViewerHub } from '../viewer.hub';
import { ViewerHubEventEnum } from '../constants/viewerHub.enum';
import { ViewerDocumentModel } from '../models/api/viewer-document-model.model';
import { ViewerRequestModel } from '../models/api/viewer-request-model.model';

@Injectable({
    providedIn: 'root'
})
export class ViewerHubService implements OnDestroy {
    public version: string = packageInfo.version;
    public viewerRequest$: BehaviorSubject<ViewerRequestModel> = new BehaviorSubject({} as ViewerRequestModel);
    public viewerDocument$: BehaviorSubject<ViewerDocumentModel> = new BehaviorSubject({} as ViewerDocumentModel);
    public documentsToLock$: BehaviorSubject<any> = new BehaviorSubject({});
    public documentsToUnlock$: BehaviorSubject<string> = new BehaviorSubject("");
    public actionUpdated$: BehaviorSubject<string> = new BehaviorSubject("");
    public loadingobserver: Subject<boolean> = new Subject<boolean>();
    private viewerHubSubscription: Subscription = new Subscription();
    public loading: boolean = true;

    constructor(private loggingService: LoggingService, public viewerHub: ViewerHub) {
        this.viewerHubSubscription = this.viewerHub.viewerEvent.subscribe({
            next: (data: any[]) => {
                try {
                    this.viewerHubEventObserver(data);
                } catch (e) {
                    console.error(e);
                }
            }, error: (error: any) => {
                this.loggingService.logException(error);
            }
        });
    }

    ngOnDestroy() {
        if (this.viewerHubSubscription) {
            this.viewerHubSubscription.unsubscribe();
        }
    }

    public async start(requestGuid: string) {
        await this.viewerHub.start();
        this.RegisterForRequestUpdates(requestGuid);
        this.loading = false;
        this.loadingobserver.next(false);
    }

    /**
     * NEVER call this without also calling the STOPListening version
     * @param requestGuid 
     * @returns 
     */
    public RegisterForRequestUpdates(requestGuid: string) {
        this.viewerHub.registerForRequestUpdates(requestGuid);
    }

    public SignStarted(documentGuid: string, inviteGuid: string) {
        this.viewerHub.signStarted(documentGuid, inviteGuid);
    }

    public SignFailed(documentGuid: string, inviteGuid: string) {
        this.viewerHub.signFailed(documentGuid, inviteGuid);
    }

    public SignDone(documentGuid: string, inviteGuid: string) {
        this.viewerHub.signDone(documentGuid, inviteGuid);
    }

    public SignStatus(documentGuid: string) {
        this.viewerHub.signStatus(documentGuid);
    }

    private viewerHubEventObserver(data: any[]) {
        if (data[0] === ViewerHubEventEnum.Error) return;
        switch (data[0]) {
            case ViewerHubEventEnum.RequestUpdated:
                let request: ViewerRequestModel = data[1];
                this.viewerRequest$.next(request);
                break;
            case ViewerHubEventEnum.ActionUpdated:
                this.actionUpdated$.next(data[1]);
                break;
            case ViewerHubEventEnum.DocumentLockedForSigning:
                let documentToLockDetails = {
                    documentGuid: data[1],
                    expectedSeconds: data[2].expectedSeconds,
                    forInviteId: data[2].forInviteId
                }
                this.documentsToLock$.next(documentToLockDetails);
                break;
            case ViewerHubEventEnum.DocumentUnlocked:
                this.documentsToUnlock$.next(data[1]);
                break;
            default:
            //Nothing to do here...
        }
    }
}