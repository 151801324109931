import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewerDataService } from '../../viewer-data.service';
import { ActorActionsApiClient } from 'src/app/services/api-clients/actor-actions.api-client';

@Component({
  templateUrl: './perform-action-linking.modal.html'
})
export class PerformActionLinkingModal implements OnInit {

  @Output('close') close = new EventEmitter();

  requestName = this.viewerDataService.get_RequestName;
  public buttonEnabled: boolean = true;

  /**
   * Number of the current actor action
   */
  expectedActionNumber: number = 1;
  currentActorInviteId!: string;

  constructor(public activeModal: NgbActiveModal, private viewerDataService: ViewerDataService, private actorActionsApiClient: ActorActionsApiClient) {
    // Must not be empty. It's approved by a comment.
    this.viewerDataService.getCurrentActor$().subscribe(currentActor => {
      this.currentActorInviteId = currentActor.id;
      this.expectedActionNumber = currentActor.badgeNumber;
    });
  }

  ngOnInit(): void {
    this.buttonEnabled = true;
  }

  back() {
    this.activeModal.close();
    this.close.emit();
  }
  clickLink() {
    this.buttonEnabled = false;
    this.actorActionsApiClient.link(this.viewerDataService.get_RequestGuid, this.currentActorInviteId).subscribe(() => {
      this.viewerDataService.actorCompletedAction(this.currentActorInviteId);
      this.activeModal.close();
    });
  }

}
