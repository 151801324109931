<div class="container-fuid bg-white default-boxrounded dialog-withdraw p-3">
    <div class="row">
        <div class="col-7">
            <h2 class="" translate>Dialog.WithdrawTitle</h2>
        </div>
    </div>
    <div class="py-3">
        <span translate="Dialog.WithdrawDescription">
            <app-info-button labelName="Dialog.WithdrawInfoButton">
            </app-info-button></span>

        <div class="mt-4">
            <span class="float-start" translate>Dialog.WithdrawReason</span>
            <span class="float-end">{{withdrawReasonControl.value?.length}}/{{MAX_ALLOWED_LENGTH}}</span>
        </div>
        <div *ngIf="showNotDescriptiveTooltip" class="mt-4">
            <span class="pt-1 fst-italic float-start" translate="Dialog.WithdrawReasonTooltip"
                [translateParams]="{minAmountOfWords:MIN_AMOUNT_OF_WORDS}">
            </span>
        </div>
        <textarea ngbAutoFocus class="textarea border mt-2" placeholder="{{ 'Dialog.WithdrawReasonPlaceholder' | translate }}"
            [formControl]="withdrawReasonControl" [maxlength]="MAX_ALLOWED_LENGTH">
        </textarea>
        <app-formcontrol-error-messages [forControl]="withdrawReasonControl"></app-formcontrol-error-messages>
    </div>
    <div class="mt-2 mt-3 d-table w-100">
        <span class="float-start">
            <button type="button" class="btn btn-sm btn-outline-primary" (click)="closeModal()" translate>Button.Back
            </button>
        </span>
        <span class="float-end">
            <button type="button" class="btn btn-sm btn-danger" (click)="verifyAndTriggerWithdraw()" [disabled]="!isFormValid"
                translate>Dialog.Withdraw
            </button>
        </span>
    </div>
</div>