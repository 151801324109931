<ng-container *ngFor="let item of tableData">

  <div class="mb-3">
    <div class="rounded-corners">
    <table class="mat-mdc-table">
      <tr class="mat-mdc-header-row mobile">
        <th class="mat-mdc-header-cell mdc-data-table__header-cell" colspan="2">
          <span [ngClass]="{'text-muted': isWaiting(item.actorStatus)}">
          <app-badge-status-display [badgeStatus]="convertedStatusToBadgeStatus(item.actorStatus)"
            class="badge-status-table-mobile"></app-badge-status-display><span
            class="float-start mobile-title">{{item.fullName}}</span>
          </span>
          <span class="float-end" *ngIf="isActionAllowed()">
            <button type="button" class="btn bg-transparent border-0 p-0 float-end" data-bs-toggle="dropdown"
              aria-expanded="false">
              <i class="bi bi-three-dots bi-large"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('editPerson', item.inviteId)"
                  id="editPersonButton" [ngClass]="{ 'disabled': !item.allowedToEdit }"
                  translate="Request.Actions.EditPerson">
                </a>
              </li>
              <li>
                <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('resendRequest', item.inviteId)"
                  id="sendReminderButton" [ngClass]="{ 'disabled': !item.allowedToResendInvite }"
                  translate="Request.Actions.SendReminder"></a>
              </li>
              <li *ngIf="item.allowedToChangeDeadline">
                <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('changeDeadline', item.inviteId)"
                  Id="changeDeadlineButton" translate="Request.Actions.ChangeDeadline">
                </a>
              </li>
              <li *ngIf="!item.allowedToChangeDeadline">
                <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('changeDeadlineDays', item.inviteId)"
                  [ngClass]="{ 'disabled': !item.allowedToChangeDeadlineDays}"
                  translate="Request.Actions.ChangeDeadlineDays" Id="changeDeadlineDaysButton">
                </a>
              </li>
            </ul>
          </span>
        </th>
      </tr>
      <ng-container *ngFor="let column of displayedColumns">
        <tr class="mat-mdc-row mobile cols-12" *ngIf="column.showMobile" [ngClass]="{'text-muted': isWaiting(item.actorStatus)}">
          <td class="mat-mdc-cell mdc-data-table__cell col-4 fw-bold" translate="Table.{{column.transTag}}"></td>
          <ng-container *ngIf="column.columnName !== 'deadline'">
            <td class="mat-mdc-cell mdc-data-table__cell col-8">
              {{ item[column.columnName] | valueByTypePipe : column.type }}
            </td>
          </ng-container>
          <ng-container *ngIf="column.columnName === 'deadline'">
            <td class="mat-mdc-cell mdc-data-table__cell col-8">
              <app-deadline [deadline]="item.deadline" [dateSigned]="item.dateSigned" [deadlineDays]="item.deadlineDays"></app-deadline>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </table>
    </div>

    <div class="break-words mt-3" *ngIf="item.declineMessage">
      <div class="alert alert-danger p-1 white-space" role="alert">
        {{item.declineMessage}}
      </div>
    </div>
  </div>

</ng-container>