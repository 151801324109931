import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './request-status-update-deadline-days.modal.html'
})
export class RequestStatusUpdateDeadlineDaysModal {
  /** Initial set days */
  @Input() deadlineDays: number = 1;
  /** Full Name of the person that you are changing */
  @Input() fullName: string = "";
  @Output() newPickedDaysEventEmitter: EventEmitter<number> = new EventEmitter();


  constructor(public activeModal: NgbActiveModal) {
  }


  calculateLatestDeadline(days: number) {
    return "";
  }
  changeDays(pickedDays: number) {
    this.deadlineDays = pickedDays;
  }


  back() {
    this.activeModal.close();
  }

  save() {
    this.newPickedDaysEventEmitter.emit(this.deadlineDays);
    this.activeModal.close();
  }
}
