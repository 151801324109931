<div class="modal-body m-0 m-lg-3">
    <div class="h-100 d-flex align-items-center text-center">
        <div class="w-100 d-block my-4">
            <div class="w-100 mb-4">
                <div class="spinner-border text-primary" role="status"></div>
            </div>
            <div class="w-100 mt-2">
                <span ngbAutofocus class="waitingtext"><strong>{{ displayMessage }}</strong></span>
            </div>
        </div>
    </div>
</div>