import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './unauthorized.page.html'
})
export class UnauthorizedPage implements OnInit {

  private idToken: any;

  constructor(private sanitizer: DomSanitizer, private authenticationService: AuthenticationService, private oidcSecurityService: OidcSecurityService) { }

  ngOnInit() {
    if (this.authenticationService.currentUserValue) {
      this.oidcSecurityService.getIdToken().subscribe((result) => this.idToken = result);
      this.sanitizer.bypassSecurityTrustResourceUrl(environment.identityUrl +
        "/connect/endsession?id_token_hint=" +
        this.idToken);
      this.authenticationService.logout();
    }
  }
}
