import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppComponent } from '../../app/app.component';
import { PKIUrls } from 'src/app/constants/pkiurls.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  public knowledgeBaseUrl: string = PKIUrls.KnowledgeBase;
  public supportUrl: string = PKIUrls.CreateSupportTicket;
  public giveFeedbackUrl: string = PKIUrls.GiveFeedback;
  
  user!: User;
  amountOfStatusPageMessages: number = 0;
  oidcAuthority: string | undefined;
  identityurl!: string;
  currentLanguage!: any;
  usersubscription: Subscription;

  constructor(private authenticationService: AuthenticationService,
  public oidcSecurityService: OidcSecurityService, public whiteLabelProvider: AppComponent) {
    this.oidcSecurityService.getConfiguration().subscribe((result) => this.oidcAuthority = result.authority);
    this.usersubscription = this.authenticationService.currentUser.subscribe((userData) => {
      this.user = userData;
    });
  }


  ngOnInit(): void {
    this.identityurl = this.oidcAuthority ? this.oidcAuthority : environment.identityUrl;
    this.knowledgeBaseUrl = this.whiteLabelProvider.knowledgebase ? (this.whiteLabelProvider.knowledgebaseUrl ?? PKIUrls.KnowledgeBase) : PKIUrls.KnowledgeBase;
    this.supportUrl = this.whiteLabelProvider.support ? (this.whiteLabelProvider.supportUrl ?? PKIUrls.CreateSupportTicket) : PKIUrls.CreateSupportTicket;
  }

  logOut() {
    this.authenticationService.logout();
  }
}
