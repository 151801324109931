import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { map, share, shareReplay } from 'rxjs/operators';
import { SettingModel } from '../models/setting.model';

@Injectable({
    providedIn: 'root'
})

export class OrganisationSettingsApiClient {

    private API_ENDPOINT = environment.apiUrl + "/organisation/";
    private _organisationSettingsCache!: Observable<any[]> | null;

    constructor(private httpClient: HttpClient) { }

    getOrganisationSettings(id: string) {
        if (sessionStorage.getItem('organisationGuid') === id) { 
            return this.getOrganisationSettingsCache(id);
        } else {
            return this.getOrganisationSettingsNonCache(id);
        }
    }

    getOrganisationSettingsCache(id: string) {
        if (!this._organisationSettingsCache) {
          this._organisationSettingsCache = this.httpClient.get<any[]>(this.API_ENDPOINT + id + "/settings").pipe(
            map(data => data
            ),
            shareReplay(1)
            );
        }
        return this._organisationSettingsCache;
    }

    getOrganisationSettingsNonCache(id: string) {
        return this.httpClient.get<any[]>(this.API_ENDPOINT + id + "/settings");
    }

    postOrganisationSettings(id: string, body: any) {
        if (sessionStorage.getItem('organisationGuid') === id) {
            this._organisationSettingsCache = null;
        }
        return this.httpClient.post(this.API_ENDPOINT + id + "/settings", body)
    }

    getReservedCredits(organisationGuid: string): Observable<number> {
        return this.httpClient.get<number>(this.API_ENDPOINT + organisationGuid + "/activeReservedCredits");
    }
    getIdin(organisationGuid: string) {
        return this.httpClient.get(this.API_ENDPOINT + organisationGuid + "/useidin");
    }

    getDetachedCertificate(organisationGuid: string) {
        return this.httpClient.get(this.API_ENDPOINT + organisationGuid + "/detachedcertificate");
    }

    postIdin(organisationGuid: string, body: any) {
        return this.httpClient.post(this.API_ENDPOINT + organisationGuid + "/useidin", body);
    }

    postDetachedCertificate(organisationGuid: string, body: any) {
        return this.httpClient.post(this.API_ENDPOINT + organisationGuid + "/detachedcertificate", body);
    }

    getEnableSpecialistRequestWorkflows(organisationGuid: string) {
        let settingKey = "EnableSpecialistRequestWorkflows";
        return this.httpClient.get<SettingModel<boolean>>(this.API_ENDPOINT + organisationGuid + "/setting/" + settingKey);
    }

    postEnableSpecialistRequestWorkflows(organisationGuid: string, settingValue: boolean) {
        let settingKey = "EnableSpecialistRequestWorkflows";
        return this.httpClient.post(this.API_ENDPOINT + organisationGuid + "/setting/" + settingKey, {
            settingValue: settingValue
        } as SettingModel<boolean>);
    }
}
