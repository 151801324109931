import { Component, Input } from '@angular/core';
import { BaseTableClass } from './base_table.class';
import { BaseTableVerticalClass } from './base_table_vertical.class';
import { DocumentForOverviewModel } from 'src/app/models/api/document-for-overview.model';

@Component({
  selector: 'desktop-default-table',
  templateUrl: './desktop_table_default.component.html',
  styleUrls: ['./table.component.scss']
})
export class DefaultTableComponent extends BaseTableClass {
  public hasErrorMessage = (index: any, item: DocumentForOverviewModel): Boolean => item.logErrorMessage != null;
}


@Component({
  selector: 'mobile-table-default',
  templateUrl: './mobile_table_default.component.html',
  styleUrls: ['./table.component.scss']
})
export class DefaultMobileTableComponent extends BaseTableVerticalClass {
  @Input() tableName!: string;
  @Input() override tableData: any;
}

