<div class="container-fuid bg-white default-boxrounded">
    <div class="row">
        <div class="col-7">
            <h2 class="p-3" translate="Request.Status.ChangeDeadlineDaysModal.Title"> </h2>

        </div>
    </div>
    <div class="px-3">
        <div class="row">
            <span class="pb-3" translate="Request.Status.ChangeDeadlineDaysModal.ForPerson"
                [translateParams]="{personName: fullName}">
            </span>

            <div class="mb-3 col-12">
                <label class="pb-2" translate="Request.Status.ChangeDeadlineDaysModal.PickDays"></label>
                <div ngbDropdown class="col-12 float-start dropdown" ngDefaultControl name="deadline" id="deadline">
                    <button class="btn default actions dropdown-toggle text-truncate" type="button" id="dropdownMenu"
                        ngbDropdownToggle>
                        <span *ngIf="(deadlineDays) === 1"
                            translate="Request.Status.DaysToReadableText.Selected_Day"
                            [translateParams]="{days: deadlineDays}">
                        </span>
                        <span *ngIf="(deadlineDays) > 1 && (deadlineDays) < 31"
                            translate="Request.Status.DaysToReadableText.Selected_Days"
                            [translateParams]="{days: deadlineDays}">
                        </span>
                        <span *ngIf="(deadlineDays) === 31"
                            translate="Request.Status.DaysToReadableText.Selected_Month"
                            [translateParams]="{month: 1}">
                        </span>
                        <span *ngIf="(deadlineDays) > 31"
                            translate="Request.Status.DaysToReadableText.Selected_Months"
                            [translateParams]="{months: (deadlineDays) === 62 ? 2 : 3}">
                        </span>
                        <em class="bi bi-chevron-down float-end" aria-hidden="true"></em>
                    </button>
                    <div class="dropdownMenuTime col-12 actions-dropdown" ngbDropdownMenu
                        aria-labelledby="dropdownMenu">
                        <button ngbDropdownItem *ngFor="let days of [1, 2, 3, 4, 5, 6, 7, 10, 14, 21, 31, 62, 93]"
                            value={{deadlineDays}} (click)="changeDays(days)">
                            <span *ngIf="days === 1" translate="Request.Status.DaysToReadableText.Selected_Day"
                                [translateParams]="{days: days}">
                            </span>
                            <span *ngIf="days > 1 && days < 31"
                                translate="Request.Status.DaysToReadableText.Selected_Days"
                                [translateParams]="{days: days}">
                            </span>
                            <span *ngIf="days === 31" translate="Request.Status.DaysToReadableText.Selected_Month"
                                [translateParams]="{month: 1}">
                            </span>
                            <span *ngIf="days > 31" translate="Request.Status.DaysToReadableText.Selected_Months"
                                [translateParams]="{months: days === 62 ? 2 : 3}">
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="mb-3 w-100">
                <button type="button" class="btn btn-sm btn-outline-primary me-1 float-start" (click)="back()"
                    translate>Button.Back</button>
                <button type="submit" class="btn btn-sm btn-primary ms-1 order-2 float-end" (click)="save()"
                    [disabled]="!deadlineDays || deadlineDays <= 0" translate>Button.Save</button>
            </div>
        </div>
    </div>
</div>