import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoggingService } from 'src/app/services/logging.service';
import { environment } from 'src/environments/environment';
import { IdentityBrandingService } from 'src/app/services/identityBranding.service';


@Component({
  templateUrl: './login.page.html'
})
export class LoginPage implements OnInit {
  constructor(private authenticationService: AuthenticationService, private route: ActivatedRoute, private identityBrandingService: IdentityBrandingService,
    private oidcSecurityService: OidcSecurityService, private modalService: NgbModal, private loggingService: LoggingService) { }

  returnUrl!: string;
  ssoSettings = [];
  modalRef!: NgbModalRef;

  ngOnInit() {
    sessionStorage.clear();

    this.route.queryParams.subscribe(params => {
      this.returnUrl = params["returnUrl"];
    });

    this.authenticate();
  }


  authenticate() {
    let currentdomain: string = document.location.href.split('/')[2];
    let identityurl = environment.identityUrl;
    let sso: string;

    if (!currentdomain.includes("platform.pkisigning.io") && !currentdomain.includes("platform.pkisigning.nl") && !currentdomain.includes("localhost:4200")) {
      return this.identityBrandingService.getWhitelabelSettings(currentdomain).subscribe((wlData: any) => {
        for (let i: number = 0; i < wlData.length; i++) {
          switch (wlData[i].name) {
            case "domain":
              identityurl = "https://" + wlData[i].value;
              this.identityBrandingService.getSSo(currentdomain).subscribe((ssoData: any) => {
                sso = ssoData.ssoProvider;
                this.authorization(currentdomain, identityurl, sso);
              });
              break;
          }
        }
      });
    } else {
      return this.identityBrandingService.getSSo(currentdomain).subscribe((ssoData: any) => {
        sso = ssoData.ssoProvider;
        this.authorization(currentdomain, identityurl, sso);
      });
    }
  }

  authorization(currentdomain: string, identityurl: string, sso: string) {
    sessionStorage.setItem("returnUrl", this.returnUrl);
    if (sso) {
      this.oidcSecurityService.authorize("", { customParams: { 'acr_values': "idp:" + sso } });
    } else {
      this.oidcSecurityService.authorize();
    }
  }
}
