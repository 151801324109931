import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'readableDateComparedWithToday',
  pure: false
})
export class ReadableDateComparedWithTodayPipe implements PipeTransform {
  translations = <any>{};

  constructor(public translateService: TranslateService) {
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    })
  }

  getTranslations() {
    this.translateService.get([
      'Customdate.Today',
      'Customdate.Yesterday',
      'Customdate.Tomorrow',
      'Customdate.Justnow'
    ]).subscribe(translation => {
      this.translations.customdateToday = translation['Customdate.Today'];
      this.translations.customdateYesterday = translation['Customdate.Yesterday'];
      this.translations.customdateTomorrow = translation['Customdate.Tomorrow'];
      this.translations.customdateJustnow = translation['Customdate.Justnow'];
    });
  }

  transform(inputValue: any) {
    if (inputValue == null) return null;
    let formattedDate = formatDate(inputValue, 'medium', 'en-US');
    return this.convertToCustomDateformat(formattedDate);
  }

  isToday(date: any) {
    const today = new Date();
    if (today.toDateString() === date.toDateString()) {
      return true;
    }
    return false;
  }

  isYesterday(date: any) {
    const yesterday = new Date((new Date()).getTime() - (1000 * 60 * 60 * 24));
    if (yesterday.toDateString() === date.toDateString()) {
      return true;
    }
    return false;
  }

  convertToCustomDateformat(time: string): string {
    let date = new Date(time + (1000 * 60 * 60 * 24 * 1)),
      diff = (((new Date()).getTime() - date.getTime()) / 1000),
      daydiff = Math.floor(diff / 86400);
    let shortDate = formatDate(new Date(time), 'dd-MM-yyyy', 'en-US')
    let hourMinute = formatDate(new Date(time), 'H:mm', 'en-US')
    let day = formatDate(new Date(time), 'EEEE', 'en-US')

    if (diff > 0 && diff < 1800) {
      return "" + this.translations.customdateJustnow + "";
    } else if (diff > 1800 && diff < 86400 && this.isToday(date)) {
      return "" + this.translations.customdateToday + " " + hourMinute + "";
    } else if (this.isYesterday(date)) {
      return "" + this.translations.customdateYesterday + " " + hourMinute + "";
    } else if (daydiff == -1) {
      return "" + this.translations.customdateTomorrow + " " + hourMinute + "";
    } else if (daydiff < -1 && daydiff >= -6) {
      return this.translateService.instant("Customdate." + day + "") + " " + hourMinute;
    } else if (daydiff > 1 && daydiff <= 6) {
      return this.translateService.instant("Customdate." + day + "") + " " + hourMinute;
    } else {
      return shortDate + " " + hourMinute;
    }
  }
}
