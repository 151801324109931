/**
 * @summary Check if object available. else return null
 * @param array 
 * @param key 
 * @param value 
 * @returns 
 */
export function SingleOrDefault<T>(array: T[], key: keyof(T),value: any): T | null{
    if(!array || array.length === 0) return null;
    if(value === null || value === "" || value === undefined) return null;
    if(key === null || key === "" || key === undefined) return null;
    let indexOfArray = array.findIndex((s: T) => s[key] === value);
    if(indexOfArray > -1){
        return array[indexOfArray];
    } 
    return null;
}

/**
 * @summary Check if object available. When available return true, else return false
 * @param array 
 * @param key 
 * @param value 
 * @returns 
 */
export function ExistsIn<T>(array: T[], key: keyof(T),value: any): boolean {
    return !DoesNotExistsIn(array, key,value);
}

/**
 * @summary Check if object isn't available. When isn't available return true, else return false
 * @param array 
 * @param key 
 * @param value 
 * @returns 
 */
export function DoesNotExistsIn<T>(array: T[], key: keyof(T),value: any): boolean {   
    return SingleOrDefault(array, key,value) == null;
}

