<div class="dialog">
    <div class="mb-4" ngbAutofocus>
        <h3  translate=Request.View.Completed.AllDone class="mb-4"></h3>
        <span *ngIf="expires != undefined" translate="Request.View.Completed.availableUntil" [translateParams]="{Deadline: expires}"></span>
        <span *ngIf="expires == undefined" translate=Request.View.Completed.availableUntilNoDate></span>
    </div>
    <div class="row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-lg-12 row-cols-xl-12">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <div class="row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-xl-12 mb-2">
                <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-truncate">
                    <input class="form-check-input checkbox" (click)="toggleAll()" [checked]="masterToggle" type="checkbox"><span class="ms-1 bold" translate="Request.View.Completed.DownloadAll"></span>
                </div>
                <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 float-end">
                    <span class="float-end bold" translate=Request.View.Completed.Filesize></span>
                </div>
            </div>
            <div *ngFor="let document of documentsToShow">
                <div class="row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-xl-12 my-2">
                    <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-truncate">
                        <input class="form-check-input checkbox" [ngClass]="{'checkboxdisabled': masterToggle}" [checked]="document.isSelected" (click)="toggle(document)" type="checkbox"><span class="ms-1">{{document.name}}</span>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 float-end">
                        <span class="float-end" *ngIf="document.size != undefined">{{document.size | byteSizePipe}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-table w-100">

        <div *ngIf="showDcGenerateMessage" class="pb-4">
            <span class="spinner-border spinner-border-sm dc-generate text-primary" aria-hidden="true"></span>
            <span class="ms-1" translate="Request.View.PerformActionComplete.GeneratingDetachedCertificate"></span>
        </div>

        <span class="float-start">
            <button class="btn btn-sm btn-success order-1" type="button" (click)="downloadRequest()" [disabled]="!anyDocumentsSelected" translate="Button.Download"></button>
            <app-ideal-payment-button [requestId]="requestId" class="ms-2"></app-ideal-payment-button>
        </span>
    </div>
</div>