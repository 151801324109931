<div class="vh-100 container d-flex align-items-center">
    <div class="col-12 container-fuid mx-auto bg-white p-0 shadow default-boxrounded overlay-container">
        <div class="position-relative h-100">
            <div class="w-100 p-4 m-2">
                <div class="mb-4">
                    <h3 translate="Loggedout.YouHaveBeenSignedOut"></h3>
                </div>
                <p class="mt-3 lh-base">
                    <span translate="Loggedout.YouMayCloseThisTab"></span> <a href="{{'Loggedout.Link' | translate}}" class="text-body text-decoration-none" translate="Loggedout.LoginPage"></a>.
                </p>
            </div>
            <div class="position-absolute bottom-0 p-4 m-2">
                <a href="{{url}}">
                    <button type="button" class="btn btn-sm btn-primary mb-3" translate="OnboardingCompleted.GoToLogin"></button>
                </a>
            </div>
        </div>
    </div>
</div>
