<div class="dialog">
    <h3>{{"Request.Actions.Reject" | translate}} {{requestName}}</h3>
    <div class="pt-2 pb-3" translate=Dialog.RejectSignatureText></div>
    <span class="float-end mb-2">{{rejectionReasonControl.value?.length}}/{{MAX_ALLOWED_LENGTH}}</span>
    <span class="float-start mb-2" translate="Dialog.Reason"></span>
    <br />
    <textarea ngbAutoFocus class="rejectTexterea" placeholder="{{ 'Dialog.RejectionReason' | translate }}"
        [formControl]="rejectionReasonControl" [maxlength]="MAX_ALLOWED_LENGTH">
    </textarea>
    <app-formcontrol-error-messages [forControl]="rejectionReasonControl"></app-formcontrol-error-messages>
    <div class="mt-3 d-table w-100">
        <span class="float-start">
            <button class="btn btn-sm btn-outline-primary" type="button" (click)="close()" translate="Button.Back">
            </button>
        </span>
        <span class="float-end">
            <button class="btn btn-sm btn-danger" type="button" (click)="reject()" translate=Button.RejectNow
                [disabled]="!this.rejectionReasonControl.valid || !this.rejectionReasonControl.value">
            </button>
        </span>
    </div>
</div>