import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthorizedPage } from '../pages/authorized/authorized.page';
import { LoggedoutPage } from '../pages/logged-out/logged-out.page';
import { LoginPage } from '../pages/login/login.page';
import { PageNotFoundPage } from '../pages/page-not-found/page-not-found.page';
import { UnauthorizedPage } from '../pages/unauthorized/unauthorized.page';
import { InvitePage } from '../pages/invite/invite.page';
import { InviteNotFoundPage } from '../pages/invite-not-found/invite-not-found.page';
import { ActivateUserPage } from '../pages/activate-user/activate-user.page';
import { OnboardingCompletedPage } from '../pages/onboarding-completed/onboarding-completed.page';
import { AppLayout } from '../components/app/app.layout';
import { PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: 'view', loadChildren: async () => (await import('./performaction/performaction.module')).PerformactionModule },
  { path: 'download', loadChildren: async () => (await import('./performaction/performaction.module')).PerformactionModule },
  { path: 'invite/:id/:key', component: InvitePage },
  { path: 'invite-not-found', component: InviteNotFoundPage }, // this uses the unavailable modal from perform action, maybe combine this into 1 module? (that the module is loaded twice above is also very ODD)
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginPage },
  { path: 'loggedout', component: LoggedoutPage },
  { path: 'authorized', component: AuthorizedPage },
  { path: 'unauthorized', component: UnauthorizedPage }, // basically a logout page
  { path: 'activateuser/:userId/:code', component: ActivateUserPage },
  { path: 'onboardingcompleted', component: OnboardingCompletedPage },
  {
    path: '', component: AppLayout, children: [
      { path: 'settings', loadChildren: async () => (await import('../components/settings/settings.module')).SettingsModule },
      { path: 'build', loadChildren: async () => (await import('./builder/builder.module')).BuilderModule },
      { path: 'dashboard', loadChildren: async () => (await import('./dashboard/dashboard.module')).DashboardModule },
      { path: 'request', loadChildren: async () => (await import('./requeststatus/requeststatus.module')).RequeststatusModule },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: '**', component: PageNotFoundPage }
    ]
  }
];

const routerOptions: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  useHash: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }