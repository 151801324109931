<div class="container-fuid bg-white default-boxrounded">
    <div class="d-flex row-cols-12 mb-2">
        <b class="d-block d-lg-none px-3 pt-4 pb-2" [translate]="title"></b>
        <h3 class="d-none d-lg-block title" [translate]="title"></h3>
    </div>
    
    <div class="ps-4 pt-1">
        <div class="mb-0 col-12">
          <span translate=CloudLocations.PleaseSelectASource></span>
        </div>
    </div>

    <div class="p-4">
        <div class="mb-3 col-12">
            <div ngbDropdown class="dropdown w-100" ngDefaultControl [(ngModel)]="enabledPlugins" name="item" id="item">
                <button class="btn btn-sm dropdown-toggle form-control text-truncate default w-100" type="button" id="dropdownMenu" ngbDropdownToggle>
                  {{cloudProviderName}}
                  <em class="bi bi-chevron-down float-end" aria-hidden="true"></em>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenu">
                    <button class="dropdown-header w-100 ps-3" ngbDropdownItem translate="CloudLocations.Microsoft365"></button>
                    <button class="w-100" *ngIf="isOneDrive" (click)="changeProvider('msgraph', 'onedrive')" ngbDropdownItem translate="CloudLocations.OneDrive"></button>
                    <button class="w-100" *ngIf="isSharePoint" (click)="changeProvider('msgraph', 'sharepoint')" ngbDropdownItem translate="CloudLocations.SharePoint"></button>
                    <button class="w-100" *ngIf="isTeams" (click)="changeProvider('msgraph', 'teams')" ngbDropdownItem translate="CloudLocations.Teams"></button>
                </div>
            </div>
        </div>
        
        <div class="pt-2">
            <div class="mb-0 col-12">
                <a *ngIf="errorOccured" [href]="url" target="msGraph" rel="opener"><span translate="CloudLocations.ClickToAuthorize"></span></a>
            </div>
        </div>

        <app-cloud-document-list [provider]="provider" [source]="source" [showFilesInFolder]="showFiles" [driveData]="loadDriveData" [acceptedDocumentTypes]="acceptedDocumentTypes" [requestId]="requestId" [allowMultiselect]="allowMultiselect"  (setUrl)="getRequestId($event)" (getRoot)="checkAccess()" (close)="emitClose($event)"></app-cloud-document-list>
    </div>
</div>
