import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textfilter',
    pure: false
})
export class TextFilterPipe implements PipeTransform {
    transform(items: any[], searchText:any, columns:string[], filterUsers: any): any {
        let filteredItems: any[] = [];
        filterUsers.count = -1;
        if (!items) {
            return [];
        }
        if (!searchText) {
        return items;
        }
        searchText = searchText.toLocaleLowerCase();

        columns.forEach((colname: string) => {
            filteredItems = filteredItems.concat(items.filter(item => item[colname]?.toString().toLowerCase().indexOf(searchText) > -1));
        });
        filteredItems = filteredItems.filter((c, index) => {
            return filteredItems.indexOf(c) === index;
        })

        filterUsers.count = filteredItems.length;
        return filteredItems;
    }
}