<div class="modal-body m-0 m-lg-3">
    <div class="mb-4 lh-base">
        <h3 translate="Modals.CreditSummary.Title"></h3>
    </div>
    <div class="row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-lg-12 row-cols-xl-12 default-font-size">
        <ul class="list-group">
            <li class="p-2 list-group-item subheader d-block align-items-center">
                <div class="row w-100 d-flex p-1 align-items-center col-12">
                    <div class="col-6" translate="Modals.CreditSummary.CostTypeTitle"></div>
                    <div class="col-2" translate="Modals.CreditSummary.CountTitle"></div>
                    <div class="col-2" translate="Modals.CreditSummary.PriceTitle"></div>
                    <div class="col-2" translate="Modals.CreditSummary.TotalCostTitle"></div>
                </div>
            </li>
            <li class="list-group-item d-block p-2 nohover" *ngFor="let occurence of creditSummary.occurences">
                <div class="row w-100 d-flex p-1 align-items-center col-12">
                    <div class="col-6" translate="Modals.CreditSummary.CostTypes.{{occurence.costType}}"></div>
                    <div class="col-2">{{occurence.count}}</div>
                    <div class="col-2">{{occurence.price}}</div>
                    <div class="col-2">{{occurence.totalCost}}</div>
                </div>
            </li>
            <li class="list-group-item d-block p-2 nohover">
                <div class="row w-100 d-flex p-1 align-items-center col-12">
                    <div class="col-6 bold" translate="Modals.CreditSummary.TotalCostTitle"></div>
                    <div class="col-2"></div>
                    <div class="col-2"></div>
                    <div class="col-2">{{creditSummary.totalCosts}}</div>
                </div>
            </li>
        </ul>
    </div>
    <div class="d-table w-100 pt-2">
        <span class="float-end">
            <button class="btn btn-sm btn-primary" type="button" (click)="close()" translate="Button.Back"></button>
        </span>
    </div>
</div>