import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { ToastModel } from './toast.model';

@Component({
  selector: 'app-toasts',
  templateUrl: './toast.component.html'
})
export class ToastsContainer {
  autohide: boolean = true;
  toasts: ToastModel[] = [];
  
  constructor(public toastService: ToastService, private changeDetector: ChangeDetectorRef) {
    toastService.toastsUpdated.subscribe(() => {
      this.toasts = this.toastService.toasts.sort((a:ToastModel, b:ToastModel) => {
        return b.date - a.date;
      });
      // Use change detector, because toasts were not always showing.
      this.changeDetector.detectChanges();
    });
  }

  getCorrectDate(date: Date) {
    let dateFormat = formatDate(date, 'MMM dd, yyyy HH:mm', 'en-US');
    return dateFormat;
  }
}