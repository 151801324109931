import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from '@justin-s/ngx-intl-tel-input';
import { IdentityUserService } from 'src/app/services/identityUser.service';
import { lastValueFrom } from 'rxjs';
import { AcceptInvitation } from 'src/app/models/acceptInvitation';
import Validation from 'src/app/directives/validation.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { AppComponent } from '../../components/app/app.component';
import { CannotContainOnlySpaceValidator } from 'src/app/directives/cannot-contain-only-space-validator.directive';
import { OnlyLettersAndNumbersValidator } from 'src/app/directives/only-letters-and-numbers-validator.directive';
import { VerifyAsEmailValidator } from 'src/app/directives/verify-as-email-validator.directive';
import { PasswordValidator } from 'src/app/directives/password-validator.directive';
import { IsEqualValidator } from 'src/app/directives/is-equal-validator.directive';
import { MarkFormGroupTouchedAndDirty } from 'src/app/directives/mark-form-group-as-touched-dirty.function';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  templateUrl: './activate-user.page.html'
})
export class ActivateUserPage implements OnInit {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Netherlands];
  @ViewChild('mobileElement') mobileElement: any;
  userId: string | null = this.route.snapshot.params['userId'];
  code: string | null = this.route.snapshot.params['code'];
  userToActivate: AcceptInvitation = new AcceptInvitation;
  isAwaitingActivation: boolean = false;

  form: UntypedFormGroup;
  firstNameControl: FormControl;
  prefixControl: FormControl;
  lastNameControl: FormControl;
  phoneControl: FormControl;
  passwordControl: FormControl;
  confirmPasswordControl: FormControl;
  /** Because we use the phonenumber component, it marks itself as touched on startup, we do not want the validations right away so im handling this manual with this bool */
  phonenumberIsTouched = false;
  constructor(private toastService: ToastService, private formBuilder: UntypedFormBuilder, private userService: IdentityUserService, private router: Router, private loggingService: LoggingService, private route: ActivatedRoute, public whiteLabelProvider: AppComponent) {

    this.firstNameControl = new FormControl('', [Validators.required, Validators.maxLength(50), OnlyLettersAndNumbersValidator(), CannotContainOnlySpaceValidator()]);
    this.prefixControl = new FormControl('', [OnlyLettersAndNumbersValidator(), Validators.maxLength(10)]);
    this.lastNameControl = new FormControl('', [Validators.required, Validators.maxLength(50), OnlyLettersAndNumbersValidator(), CannotContainOnlySpaceValidator()]);
    this.phoneControl = new FormControl('', [Validators.required
      // PHONE NUMBER VALIDATIONS ARE HANDLED BY EXTERNAL INPUT THINGIE: ngx-intl-tel-input 
    ]);
    this.passwordControl = new FormControl('', [Validators.required, Validators.maxLength(50), PasswordValidator()]);
    this.confirmPasswordControl = new FormControl('', [Validators.required, Validators.maxLength(50), PasswordValidator()]);
    this.form = this.formBuilder.group(
      {
        userId: new UntypedFormControl(true),
        code: new UntypedFormControl(true),
        firstname: this.firstNameControl,
        prefix: this.prefixControl,
        lastname: this.lastNameControl,
        mobile: this.phoneControl,
        password: this.passwordControl,
        confirmPassword: this.confirmPasswordControl,
      },
      {
        validator: IsEqualValidator('password', 'confirmPassword'),
      }
    );
  }

  async ngOnInit() {
    await this.getUser();
  }

  mobileTouched(event: any) {
    this.phonenumberIsTouched = true;
    // assign the custom invalid class to mobile
    if (this.phoneControl.invalid) {
      (event.target as HTMLInputElement).classList.add('is-invalid');
      return;
    }
    (event.target as HTMLInputElement).classList.remove('is-invalid');

  }
  async getUser() {
    let userToActivateSubscription = await this.userService.getUserToActivate(this.userId!, this.code!);
    this.userToActivate = <AcceptInvitation>await lastValueFrom(userToActivateSubscription);
    this.form.controls['userId'].setValue(this.userId);
    this.form.controls['code'].setValue(this.code);
    this.form.controls['firstname'].setValue(this.userToActivate.voornaam);
    this.form.controls['prefix'].setValue(this.userToActivate.tussenvoegsels);
    this.form.controls['lastname'].setValue(this.userToActivate.achternaam);
    this.form.controls['mobile'].setValue(this.userToActivate.mobiel);
  }

  async save() {
    this.isAwaitingActivation = true;
    if (this.form.invalid) {
      MarkFormGroupTouchedAndDirty(this.form);
      this.phonenumberIsTouched = true;
      this.toastService.showInvalidFields();
      this.isAwaitingActivation = false;
      return;
    }
    this.userToActivate.userId = this.form.controls['userId'].value;
    this.userToActivate.code = this.form.controls['code'].value;
    this.userToActivate.voornaam = this.form.controls['firstname'].value;
    this.userToActivate.tussenvoegsels = this.form.controls['prefix'].value;
    this.userToActivate.achternaam = this.form.controls['lastname'].value;
    this.userToActivate.mobiel = this.form.controls['mobile'].value.e164Number;
    this.userToActivate.password = this.form.controls['password'].value;
    this.userToActivate.confirmPassword = this.form.controls['confirmPassword'].value;

    this.isAwaitingActivation = true;
    (await this.userService.ActivateUser(this.userToActivate)).subscribe({
      error: () => {
        this.isAwaitingActivation = false;
      },
      next: () => {
        this.router.navigate(["/onboardingcompleted"])
          .catch(error => {
            this.loggingService.logException(error);
          });
      }
    });
  }
}

