import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './logged-out.page.html'
})
export class LoggedoutPage implements OnInit {
  url: SafeUrl = "";

  ngOnInit(): void {
    this.url = "https://" + window.location.host.toLowerCase();
  }

}
