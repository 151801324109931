// number-input.component.ts
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const NUMBER_INPUT_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PageSizePickerComponent),
    multi: true,
};

@Component({
    selector: 'app-page-size-picker',
    templateUrl: './page-size-picker.component.html',
    providers: [NUMBER_INPUT_ACCESSOR],
})
export class PageSizePickerComponent implements ControlValueAccessor {
    
    @Input() itemsPerPage: number = 10; // default value must not be 0 else it resets to first page
    @Input() collectionSize: number = 10000; // default value must not be 0 else it resets to first page
    @Input() currentPageNumber: number = 0;
    @Output() pageChangedTo: EventEmitter<number> = new EventEmitter<number>();

    public disabled = false;

    public pageSizeOptions = [10, 25, 50, 100];


    public emitPageChanged(newPageNumber: number) {
        this.pageChangedTo.emit(newPageNumber);
    }

    public changePagesize(newPageSize: number) {
        this.writeValue(newPageSize);
    }

    // CVA stuff     
    onChange: any = () => { };
    onTouch: any = () => { };

    writeValue(value: number): void {
        if (value != null && value != undefined) {
            this.itemsPerPage = value;
            this.onChange(value);
            this.onTouch(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }


    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}

