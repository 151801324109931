/**
 * The Extensions that are always allowed for 'Safe Transfer'
 * Example: pdf, docx
 */
export const DefaultAllowedSafeTransferExtensions: string[] = ['pdf', 'xbrl', 'xps', 'xsd', 'doc', 'docx'];


export enum PlatformSettings {

  ShowLocation = 'ShowLocation',
  ShowReason = 'ShowReason',
  UseInternalStamp = 'UseInternalStamp',
  UseExternalStamp = 'UseExternalStamp',
  UseInternalDraw = 'UseInternalDraw',
  UseExternalDraw = 'UseExternalDraw',
  UseInternalPicture = 'UseInternalPicture',
  UseExternalPicture = 'UseExternalPicture',
  OrganisationLanguage = 'OrganisationLanguage',
  EmailSender = 'EmailSender',
  ShowRecentlyContacts = 'ShowRecentlyContacts',
  SigningNote = 'SigningNote',
  EmailInviteReminderSchedule = 'EmailInviteReminderSchedule',
  EmailOwnerDocumentReminderSchedule = 'EmailOwnerDocumentReminderSchedule',
  XbrlRenderClient = 'xbrlRenderClient',
  AllowedDocumentExtensions = 'allowedDocumentExtensions',

  // Workflows
   Allow_WF_SignYourself = 'Allow_WF_SignYourself',
   Allow_WF_SignatureRequest = 'Allow_WF_SignatureRequest',
   Allow_WF_SecureFileSharing = 'Allow_WF_SecureFileSharing',
   Allow_WF_IncomeTax = 'Allow_WF_IncomeTax',
   Allow_WF_CorporateTax = 'Allow_WF_CorporateTax',
   Allow_WF_VAT = 'Allow_WF_VAT',
   Allow_WF_EUVAT = 'Allow_WF_EUVAT',
   Allow_WF_Filing = 'Allow_WF_Filing',
   Allow_WF_FilingWithReport = 'Allow_WF_FilingWithReport',
   Allow_WF_LinkingAndSigning = 'Allow_WF_LinkingAndSigning',
   Allow_WF_SbrBank = 'Allow_WF_SbrBank',
   Allow_WF_SbrBankWithReport = 'Allow_WF_SbrBankWithReport',
   Allow_WF_NoabBankAudit = 'Allow_WF_NoabBankAudit',
   Allow_WF_Wwft = 'Allow_WF_Wwft',
   

  // Email Notify settings:
  EmailOwnerDigipoortStatus = 'EmailOwnerDigipoortStatus',
  EmailOwnerDocumentExpires = 'EmailOwnerDocumentExpires',
  EmailOwnerSignDone = 'EmailOwnerSignDone',
  EmailOwnerSignReminder = 'EmailOwnerSignReminder',
  EmailSignerExSignReminder = 'EmailSignerExSignReminder',
  EmailSignerIDigipoortStatus = 'EmailSignerIDigipoortStatus',
  EmailSignerISignDone = 'EmailSignerISignDone',
  EmailSignerISignReminder = 'EmailSignerISignReminder',
  EmailOwnerSignExpired = 'EmailOwnerSignExpired',

  // AFAS:
  UseAFAS = 'UseAFAS',
  AfasConnectorBaseUrl = 'AfasConnectorBaseUrl',
  AfasConnectorToken = 'AfasConnectorToken',
  AfasFTP = 'AfasFTP',
  AfasFTPMultipleFilesOffered = 'AfasFTPMultipleFilesOffered',
  AfasFTPUser = 'AfasFTPUser',
  AfasFTPUserId = 'AfasFTPUserId',
  UseClientOnline = 'UseClientOnline',
  PinkwebUrl = 'PinkwebUrl',
  PinkwebUsername = 'PinkwebUsername',
  PinkwebPassword = 'PinkwebPassword',
  UseSimplicateCrmData = 'UseSimplicateCrmData',
  SimplicateApiKey = 'SimplicateApiKey',
  SimplicateApiSecret = 'SimplicateApiSecret',
  SimplicateApiSubdomain = 'SimplicateApiSubdomain',
  DigipoortEnvironment = "DigipoortEnvironment",
  SBRNexusEnvironment = "SBRNexusEnvironment",
  AansluitSuiteAanleverKenmerk = "AansluitSuiteAanleverKenmerk",

  // Salesforce:
  UseSalesforceCrmData = 'UseSalesforceCrmData',
  SalesforceApiKey = 'SalesforceApiKey',
  SalesforceApiSecret = 'SalesforceApiSecret',
  SalesforceApiSubdomain = 'SalesforceApiSubdomain',
}

export enum IdentitySettings {

}



export enum OrganisationPlugins {
  AFAS = 'AFAS',
  AFASInSite = 'AFASInSite',
  PinkwebAccordering = 'PinkwebAccordering',
  PinkwebDossier = 'PinkwebDossier',
  AuditCase = 'AuditCase',
  CaseWare = 'CaseWare',
  MAP = 'MAP',
  SharePoint = 'SharePoint',
  OneDrive = 'OneDrive',
  Teams = 'Teams'
}
export enum organisationClaims {
  AzureBlobStorage = "azureblobstorage",
  TwoFactorRequired = "twofactorrequired",
  SSORequired = "ssorequired",
  SSOProvider = "sso",
  GuestValidationMethod = "GuestValidationMethod"
}
export enum PluginSettings {
  EnablePlugin_SharePoint = 'EnablePlugin_SharePoint',
  EnablePlugin_OneDrive = 'EnablePlugin_OneDrive',
  EnablePlugin_Teams = 'EnablePlugin_Teams'
}
/**
 * SOME of these are ALSO Settings (WF), others are ONLY Permissions
 */
export enum PermissionsEnum {
  // allow switching of workflows
  Perm_WF_SignYourself = "Perm_WF_SignYourself",
  Perm_WF_SignatureRequest = "Perm_WF_SignatureRequest",
  Perm_WF_SecureFileSharing = "Perm_WF_SecureFileSharing",
  Perm_WF_IncomeTax = "Perm_WF_IncomeTax",
  Perm_WF_CorporateTax = "Perm_WF_CorporateTax",
  Perm_WF_VAT = "Perm_WF_VAT",
  Perm_WF_EUVAT = "Perm_WF_EUVAT",
  Perm_WF_Filing = "Perm_WF_Filing",
  Perm_WF_FilingWithReport = "Perm_WF_FilingWithReport",
  Perm_WF_LinkingAndSigning = "Perm_WF_LinkingAndSigning",
  Perm_WF_SbrBank = "Perm_WF_SbrBank",
  Perm_WF_SbrBankWithReport = "Perm_WF_SbrBankWithReport",
  Perm_WF_NoabBankAudit = "Perm_WF_NoabBankAudit",
  Perm_WF_Wwft = "Perm_WF_Wwft",
  
  // allow selection of Signature options
  Perm_Stamp = "Perm_Stamp",
  Perm_Drawing = "Perm_Drawing",
  Perm_Pictures = "Perm_Pictures",
  // Generic
  Perm_Two_Factor_Authentication = "Perm_Two_Factor_Authentication",
  Perm_Generate_DC = "Perm_Generate_DC",
  Perm_Force_AAD = "Perm_Force_AAD",
  // Allow enabling of intergrations/Services
  Perm_Afas = "Perm_Afas",
  Perm_Sharepoint = "Perm_Sharepoint",
  Perm_OneDrive = "Perm_OneDrive",
  Perm_SalesForce = "Perm_SalesForce",
  Perm_Simplicate = "Perm_Simplicate",
  Perm_Digipoort = "Perm_Digipoort",
  Perm_SBRNexus = "Perm_SBRNexus",
  Perm_XbrlRenderService = "Perm_XbrlRenderService",
  // Available Identity Providers
  Perm_IdP_Sms = "Perm_IdP_Sms",
  Perm_IdP_Whatsapp = "Perm_IdP_Whatsapp",
  Perm_IdP_IDIN = "Perm_IdP_IDIN",
  Perm_IdP_Vidua_Authentication = "Perm_IdP_Vidua_Authentication",
  Perm_IdP_Vidua_Identification = "Perm_IdP_Vidua_Identification",
}
export enum PermissionGroup {
  Setting = "Setting",
  WorkflowTypes = "WorkflowTypes",
  AvailableSignatureDisplayOptions = "AvailableSignatureDisplayOptions",
  TwoFactorAuthentication = "TwoFactorAuthentication",
  IdentificationProviders = "IdentificationProviders",
  DetachedCertificate = "DetachedCertificate",
  SSO = "SSO",
  PlatformAPIIntegrations = "PlatformAPIIntegrations",
  DocumentIntegrations = "DocumentIntegrations",
  AddressbookIntegrations = "AddressbookIntegrations",
  Services = "Services",
}