import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class BlobHelper {
    base64toBlob(b64Data: any, contentType: string, sliceSize: any) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
    
        let byteCharacters = atob(b64Data);
        let byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize);
    
          let byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
    
          let byteArray = new Uint8Array(byteNumbers);
    
          byteArrays.push(byteArray);
        }
    
        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
      }
}