import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import CloudLocation from '../models/cloudlocations';

@Injectable({
  providedIn: 'root'
})
export class CloudLocationsService {

  constructor(private http: HttpClient) { }

    configUrl = environment.apiUrl + "/api/external";
    httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    getContents(provider:string, source: string) {
      return this.http.get<CloudLocation>(this.configUrl+"/"+provider+"/"+source);
    }

    getChildContents(provider:string, source: string, id: string) {
        return this.http.get<CloudLocation>(this.configUrl+"/"+provider+"/"+source+"/"+id);
    }

    getParentChildContents(provider:string, source: string, parent: string, id: string) {
      return this.http.get<CloudLocation>(this.configUrl+"/"+provider+"/"+source+"/"+parent+"/"+id);
    }

    async importChildContents(provider:string, parentid: string, id:string, allowRendering: boolean = true, workgroupId:string|null, clearancelevel:number|null, requestId:string|null, placeholderId?: number, workflowId?: string) {
      return this.http.post(this.configUrl+"/"+provider+"/import/"+parentid+"/"+id+"", 
      {
        "WorkgroupId": workgroupId, 
        "Clearancelevel": clearancelevel, 
        "RequestId": requestId, 
        "PlaceholderId": placeholderId, 
        "WorkflowId": workflowId,
        "AllowRendering": allowRendering
      }, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        reportProgress: true,
        observe: "events"
      });
    }
    
    saveParentExternalContent(provider:string, parentId: string, id:string, dossierId:string) {
      return this.http.post(this.configUrl+"/"+provider+"/store/"+parentId+"/"+id+"", { "id":""+dossierId+"" }, this.httpOptions);
    }

    addDocumentToCurrentRequest(requestId: string, documentIds: any) {
        return this.http.post(environment.apiUrl + "/requests/" + requestId + "/ImportDocuments", documentIds);
      }
}
