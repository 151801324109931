import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { AcceptInvitation } from '../models/acceptInvitation';
import { Observable } from 'rxjs';
import { WorkgroupInformationModel } from '../models/workgroup-information.model';

@Injectable({
  providedIn: 'root'
})

export class IdentityUserService {

  constructor(private http: HttpClient) { }

  getUser(userId: string): Observable<User> {
    return this.http.get<User>(environment.identityUrl + "/api/user/" + userId + "");
  }

  async addUser(user: User) {
    return this.http.post(environment.identityUrl + "/api/user/", user, { responseType: 'text' });
  }

  async updateUser(userId: string, user: User) {
    return this.http.put(environment.identityUrl + "/api/user/" + userId + "", user);
  }

  async deleteUser(userId: string) {
    return this.http.delete(environment.identityUrl + "/api/user/" + userId + "");
  }

  getUserWorkgroups(userId: string): Observable<WorkgroupInformationModel[]> {
    return this.http.get<WorkgroupInformationModel[]>(environment.identityUrl + "/api/User/" + userId + "/Workgroups");
  }

  async getUserToActivate(userId: string, code: string) {
    let params = new HttpParams();
    params = params.append('code', code);

    return this.http.get(environment.identityUrl + "/api/User/" + userId + "/Activate", { params: params });
  }

  async ActivateUser(model: AcceptInvitation) {
    return this.http.post(environment.identityUrl + "/api/User/" + model.userId + "/Activate", model);
  }

  async resendActivationEmail(userId: string) {
    return this.http.post(environment.identityUrl + "/api/User/" + userId + "/ResendInvite", { userId: userId});
  }
}
