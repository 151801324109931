import { Component, Input } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DocumentActor } from 'src/app/models/actors';


export const AwaitingFilingModalOptions: NgbModalOptions = {
  keyboard: false,
  centered: true,
  size: 'lg',
  backdrop: 'static'
};

@Component({
  templateUrl: './performed-action-awaiting-filing.modal.html'
})
export class PerformedActionAwaitingFilingModal {
  @Input() actions!: DocumentActor[];
  @Input() documents!: any[];
  @Input() requestStatus!: string;
  @Input() requestId!: string;
  @Input() requestType!: string;
  /**
   * For this summary we always want to display a custom action in the summary that it is being awaiting
   */
  displayCustomFilingAction = true;
  
  /**
   * For the extra custom action when filing, it needs a badge number
   */
  get maxBadgeNumberPlusOne(): number {
    return (Math.max(...this.actions.map(o => o.badgeNumber)) + 1);
  }
}
