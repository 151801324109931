<div class="container-fuid w-100 sub-header d-flex align-items-center">
  <div class="w-100 ms-2 ms-sm-2 ms-md-2 ms-lg-5 ms-xl-5 ms-xxl-5 me-2 me-sm-2 me-md-2 me-lg-5 me-xl-5 me-xxl-5">
    <nav aria-label="breadcrumb" class="align-items-center p-2">
      <ol class="breadcrumb">
        <li *ngFor="let breadCrumb of breadCrumbs; let isLastBreadCrumb = last;" class="breadcrumb-item">
          <ng-container *ngIf="isLastBreadCrumb">
            <label translate="SettingsBreadCrumb.{{breadCrumb.translationKey}}"></label>
          </ng-container>
          <ng-container *ngIf="!isLastBreadCrumb">
            <a *ngIf="breadCrumb.displayTitle" [routerLink]="breadCrumb.redirectRoute">{{breadCrumb.displayTitle}}
            </a>
            <a *ngIf="!breadCrumb.displayTitle" [routerLink]="breadCrumb.redirectRoute"
              translate="SettingsBreadCrumb.{{breadCrumb.translationKey}}">
            </a>
          </ng-container>
        </li>

        <!-- NO BREADCRUMBS SUPPLIED - DEFAULT TO BUILDER STANDARD-->
        <ng-container *ngIf="!breadCrumbs">
          <!-- MAIN DASHBOARD -->
          <li class="breadcrumb-item"><a routerLink="/dashboard" translate="dashboard.title"></a></li>
          <!-- secondary if given, else default to a 'General request'-->
          <ng-container *ngIf="secondCrumb">
            <li class="breadcrumb-item text-truncate" aria-current="page">{{secondCrumb}}</li>
          </ng-container>
          <ng-container *ngIf="!secondCrumb">
            <li class="breadcrumb-item text-truncate" aria-current="page" translate="Request.Header.GeneralRequest">
            </li>
          </ng-container>
        </ng-container>

      </ol>
    </nav>
  </div>
</div>