import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PanZoomModel } from 'ngx-panzoom';
import { DocumentApiClient } from 'src/app/services/document.apiclient';



export const ViewXmlModalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  size: 'xl',
  windowClass: 'renderedModal'
};

@Component({
  templateUrl: './view-xml.modal.html'
})
export class ViewXmlModal implements OnInit {

  @Input() documentId!: string;
  @Input() requestId!: string;
  @Input() documentType!: string;

  // IMPORTANT semi inputs, generated from inputs
  // xbrlTemplate!: string;
  totalPageCount: number = 1;


  translations: {
    columnTime: string;
    columnStatusCode: string;
    columnReference: string;
    columnDescription: string;
  } = <any>{};

  constructor(private activeModal: NgbActiveModal, private sanitizer: DomSanitizer, private documentService: DocumentApiClient, private translateService: TranslateService) { }

  getTranslations() {
    this.translateService.get([
      'Request.Rendered.ColumnTime',
      'Request.Rendered.ColumnStatusCode',
      'Request.Rendered.ColumnReference',
      'Request.Rendered.ColumnDescription'
    ]).subscribe(translation => {
      this.translations.columnTime = translation['Request.Rendered.ColumnTime'];
      this.translations.columnStatusCode = translation['Request.Rendered.ColumnStatusCode'];
      this.translations.columnReference = translation['Request.Rendered.ColumnReference'];
      this.translations.columnDescription = translation['Request.Rendered.ColumnDescription'];
    });
  }

  innerHtml!: string;
  currentPageNumber: number = 1;
  pageWidth: number = 0;
  pageHeight: number = 0;
  isMobile: any;
  setPlaceholder: boolean = false;

  pageElement!: HTMLElement;
  panZoomModelData!: PanZoomModel;
  panleft: number = 0;
  pantop: number = 0;
  scrollHeight!: any;

  xbrlTemplate = 'assets/temp_html_pages/xbrl.html';
  xbrlTemp!: string;
  renderedDocument!: SafeHtml;

  ngOnInit() {
    this.renderXml();
  }

  close() {
    this.activeModal.close();
  }

  private async renderXml() {
    try {

      (await this.documentService.getRenderedHtml(this.requestId, this.documentId))
        .subscribe({
          error: (error: HttpErrorResponse) => console.error(error),
          next: (xmlDocumentData: any) => {
            const xml = new DOMParser().parseFromString(xmlDocumentData, 'text/xml');

            const headingLines: string[] = [];

            // Iterate through each child node to be able to add the heada
            xml.documentElement?.childNodes.forEach((node) => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                // Add the element name and value to the array
                headingLines.push(`${node.nodeName}: ${node.textContent}`);
              }
            });

            // Join the lines with <br> tags
            const xmlString = headingLines.join('<br>');

            this.renderedDocument = this.sanitizer.bypassSecurityTrustHtml(xmlString);
          }
        });
    } catch (error) {
      console.error('Error rendering XML:', error);
    }

  }

  private transformXml(xml: Document): DocumentFragment {
    const xsltProcessor = new XSLTProcessor();
    // Add your XSLT stylesheet here if needed
    return xsltProcessor.transformToFragment(xml, document);
  }

  private xmlToString(input: Document | DocumentFragment): string {
    if (window.XMLSerializer) {
      return new XMLSerializer().serializeToString(input).replace(/\n/g, '<br>');
    } else {
      throw new Error('Failed to convert input to string.');
    }
  }
}

// private async renderXml() {
//   let temp: HTMLElement = document.createElement('div');
//   (await this.documentService.getRenderedHtml(this.requestId, this.documentId))
//     .subscribe({
//       error: (error: HttpErrorResponse) => console.error(error),
//       next: (xmlDocumentData: any) => {

//         let xml = new DOMParser().parseFromString(xmlDocumentData, "text/xml");
//         let textie  = this.xmlToString(this.transformXml(xml));

//         this.renderedDocument = this.sanitizer.bypassSecurityTrustHtml(textie);
//       }
//     });
// }
// private transformXml(xml: any) {
//   let xsltProcessor;
//   if (typeof xml === "string") xml = this.stringToXml(xml);
//   if (window.XSLTProcessor) {
//     xsltProcessor = new XSLTProcessor();
//     return xsltProcessor.transformToFragment(xml, document);
//   } else {
//     throw new Error("Failed to transform XML.");
//   }
// }
// private xmlToString(input: any) {
//   if (window.XMLSerializer) {
//     return (new XMLSerializer()).serializeToString(input);
//   } else if (input.xml) {
//     return input.xml;
//   } else {
//     throw new Error("Failed to convert input to string.");
//   }
// }
// private stringToXml(input: any) {
//   if (window.DOMParser) {
//     return (new DOMParser()).parseFromString(input, "text/xml");
//   } else {
//     throw new Error("Failed to convert input to XML document.");
//   }
// }

// private setElementContent(element: any, content: any) {
//   element.innerHTML = '';
//   if (content.nodeType) {
//     element.appensdChild(content);
//   } else {
//     element.innerHTML = content;
//   }
// }
// }


