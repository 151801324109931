<div class="modal-body m-0 m-lg-3" style="z-index: 9999;">
    <div class="mb-4">
        <h1 ngbAutofocus translate="Request.View.Rejected.RequestRejected"></h1>
    </div>
    <div class="row row-cols-12 default-font-size">
        <app-action-summary [actions]="actions" [documents]="documents" [requestStatus]="requestStatus"></app-action-summary>
    </div>
    <div class="alert alert-danger p-2 break-words default-font-size white-space" role="alert">
        <span>{{rejectionReason}}</span>
    </div>
    <div class="default-font-size" translate="Request.View.Rejected.MayCloseTab"></div>
</div>