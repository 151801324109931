import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ClipboardService {

  translations: any = <any>{};

  constructor(private toastService: ToastService, private translateService: TranslateService) { }

  getTranslations() {
    this.translateService.get([
      'Toasts.ClipboardError',
      'Toasts.ClipboardNotSupported',
      'Toasts.Error'
    ]).subscribe(translation => {
      this.translations.ClipboardError = translation['Toasts.ClipboardError'];
      this.translations.ClipboardNotSupported = translation['Toasts.ClipboardNotSupported'];
      this.translations.error = translation['Toasts.Error'];
    });
  }
  /** 
   * Tries to copy the data to the clipboard   
   * This call shows Toasts for Succes and Errors
   */
  Copy(dataToCopy: string) {
    try {
      if (!navigator.clipboard) {
        this.toastService.showError(this.translations.error, this.translations.ClipboardNotSupported);
      }
      navigator.clipboard.writeText(dataToCopy);
      this.toastService.showCopied();
    }
    catch (error: any) {
      this.toastService.showError(this.translations.error, this.translations.ClipboardError);
    }

    return
  }
}
