import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trim]'
})
export class TrimDirective {

  @Output() ngModelChange = new EventEmitter();

  constructor(private el: ElementRef,
    private control: NgControl) {
  }

  /**
   * Trim the input value on focus out of input component
   */
  @HostListener('focusout') onFocusOut() {
    (this.el.nativeElement as HTMLInputElement).value = (this.el.nativeElement as HTMLInputElement).value.trim();
    this.ngModelChange.emit(this.el.nativeElement.value)
    this.control.control?.setValue(this.el.nativeElement.value)
    this.control.control?.updateValueAndValidity();
  }

}

@Directive({
  selector: 'input#frmField,input#inputRequest,input:not([type=checkbox])[formControlName],textarea[formControlName],input:not([type=checkbox])[formControl],textarea[formControl],input:not([type=checkbox])[ngModel],textarea[ngModel],[ngDefaultControl]',
  host: {
    '(blur)': 'getFormControlValue()',
    '(focus)': 'getFormControlValue()'
  }
})
export class InputTrimDirective {
  constructor(private el: ElementRef) { }

  getFormControlValue() {
    let value = this.el.nativeElement.value;
    let valueTrim = value.replace(/(?:^(?:&nbsp;)+)|(?:(?:&nbsp;)+$)/g, '');
    valueTrim = valueTrim.trim();

    if (value !== valueTrim) {
      this.el.nativeElement.value = valueTrim;
    }
  }

}
