<div class="float-end me-1 mt-0" [ngClass]="{'disabledDiv': disabled }">
    <div class="btn-group ms-3 me-0 float-end">
        <button type="button" class="btn btn-sm btn-primary">{{itemsPerPage}}</button>
        <button type="button"
            class="btn btn-sm btn-primary dropdown-toggle dropdown-toggle-split align-items-center nr-per-page border-start-0"
            data-bs-toggle="dropdown" aria-expanded="false"></button>
        <ul class="dropdown-menu">
            <li *ngFor="let pageSizeOption of pageSizeOptions;">
                <a class="dropdown-item" (click)="changePagesize(pageSizeOption)">{{pageSizeOption}}</a>
            </li>
        </ul>
    </div>
    <ngb-pagination class="align-items-center float-end mt-0" [collectionSize]="collectionSize" #numPages
        [pageSize]="itemsPerPage" [(page)]="currentPageNumber" (pageChange)="emitPageChanged(currentPageNumber)"
        [maxSize]="5" [rotate]="true" [ellipses]="false">
        <ng-template ngbPaginationPrevious><span translate="pagination.previous"></span></ng-template>
        <ng-template ngbPaginationNext><span translate="pagination.next"></span></ng-template>
    </ngb-pagination>
</div>