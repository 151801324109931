<div class="modal-body m-0 m-lg-3">
    <div class="mb-4 lh-base">
        <h3>{{requestName}}</h3>
        <app-greet-user></app-greet-user>
        <!-- all YOUR actors are completed-->
        <ng-container *ngIf="allActorsCompleted">
            <p class="default-font-size">
                <span ngbAutofocus translate="Request.View.Summary.CompletedAndWaiting"></span>
            </p>
        </ng-container>
        <!-- You have Actors to complete:-->
        <ng-container *ngIf="!allActorsCompleted">
            <p class="default-font-size">
                <span ngbAutofocus translate="Request.View.Summary.PleaseReviewAndComplete"></span>
                <b> {{myActors[0].deadline | date:'dd-MM-yyyy HH:mm zzzz'}}</b>
            </p>
        </ng-container>
    </div>
    <div class="row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-lg-12 row-cols-xl-12 default-font-size mb-4">
        <!-- Show the actual Action summary-->
        <app-action-summary [actions]="myActors" [documents]="documents" [requestStatus]="requestStatus">
        </app-action-summary>
    </div>
    <div class="d-table w-100">
        <span class="float-start">
            <button class="btn btn-sm btn-primary" type="button" (click)="close()" translate="Button.Continue"></button>
        </span>
    </div>
</div>