<tag-input [ngModel]="valueAsTagModels" [separatorKeyCodes]="separatorKeys" [placeholder]="''"
    [secondaryPlaceholder]="''" (onAdd)="onItemAdded($event)" (onRemove)="onItemRemove($event)" theme='bootstrap'
    [validators]="validators" [errorMessages]="customErrors">
</tag-input>
<!--Only letters pattern validation  -->
<div class="invalid-feedback d-block" *ngIf="OnlyLettersAllowedError" translate="Error.OnlyLettersAllowed">
</div>
<!--Only letters and numbers pattern validation  -->
<div class="invalid-feedback d-block" *ngIf="OnlyLettersAndNumbersAllowedError" translate="Error.OnlyLettersAndNumbersAllowed">
</div>
<!--Only lowercase allowed pattern validation  -->
<div class="invalid-feedback d-block" *ngIf="OnlyLowerCaseAllowed" translate="Error.OnlyLowerCaseAllowed">
</div>