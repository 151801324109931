<div class="modal-body m-0 m-lg-3">
    <div class="mb-2 lh-base">
        <h3 translate="Dialog.Log"></h3>
    </div>
    <div class="row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-lg-12 row-cols-xl-12 default-font-size">
        <mobile-status-log></mobile-status-log>
    </div>
    <div class="d-table w-100 pt-2">
        <span class="float-end">
            <button class="btn btn-sm btn-primary" type="button" (click)="closeModal()" translate="Button.Close"></button>
        </span>
    </div>
</div>
