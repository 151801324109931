import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreditSummaryModel } from 'src/app/models/CreditSummary.model';


export const CreditSummaryExplanationModalOptions: NgbModalOptions = {
    keyboard: false,
    centered: true,
    size: 'lg'
};

@Component({
    templateUrl: './credit-summary-explanation.modal.html'
})
export class CreditSummaryExplanationModal {

    @Input() creditSummary!: CreditSummaryModel;
    constructor(public activeModal: NgbActiveModal) {
    }

    close() {
        this.activeModal.close();
    }
}