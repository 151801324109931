export enum RequestStatusActionsEnum {
  editPerson = "editPerson",
  resendRequest = "resendRequest",
  changeDeadline = "changeDeadline",
  changeDeadlineDays = "changeDeadlineDays",
  openPreview = "openPreview",
  // Request Events
  /** <summary>
  /// The download action.
  **/
  Download = "Download",
  /** <summary>
  /// The export action
  **/
  Export = "Export",
  /** <summary>
  /// The purge action.
  **/
  Purge = "Purge",
  /** <summary>
  /// The delete action.
  **/
  Delete = "Delete",
  /** <summary>
  /// The withdrawn action.
  **/
  Withdrawn = "Withdrawn",
  /** <summary>
  /// The restore action.
  **/
  Restore = "restore",
  /** <summary>
  /// The become an owner action.
  **/
  BecomeOwner = "BecomeOwner",
  /** <summary>
  /// The view log action.
  **/
  Viewlog = "Viewlog",
  /** <summary>
  /// The open preview action.
  **/
  OpenPreview = "OpenPreview"
}