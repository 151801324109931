<ng-container *ngIf="inputFormControl.invalid && (inputFormControl.dirty || inputFormControl.touched)">
    <!-- MIN length message -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['minlength']"
        [translateParams]="{MinLengthNumber:inputFormControl.errors?.['minlength'].requiredLength}"
        translate="Error.MinLength">
    </div>
    <!-- MAX length message -->

    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['maxlength']"
        [translateParams]="{MaxLengthNumber:inputFormControl.errors?.['maxlength'].requiredLength}"
        translate="Error.MaxLength">
    </div>
    <!-- Required message -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['required']" translate="Error.Required">
    </div>

    <!-- Contains Forbidden 'Word' (string) -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['containsForbiddenWord']"
        translate="Error.ContainsForbiddenWord"
        [translateParams]="{ForbiddenWord:inputFormControl.errors?.['containsForbiddenWord'].forbiddenWord}">
    </div>
    
    <!-- Minimum "Word" count -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['shortComingOnWords']"
        translate="Error.ShortComingOnWords"
        [translateParams]="{MinimumCount:inputFormControl.errors?.['shortComingOnWords'].minimum}">
    </div>
    <!--Only letters and number pattern validation  -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['OnlyLettersAndCharactersAllowed']"
        translate="Error.OnlyLettersAndNumbersAllowed">
    </div>
    <!--Only numbers pattern validation  -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['OnlyNumbersAllowed']"
        translate="Error.OnlyNumbersAllowed">
    </div>
    
    <!--Does not pass our Email Validation  -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['IsNotEmail']" translate="Error.IsNotEmail">
    </div>
    <!--Does not pass our Phonenumber Validation  -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['IsNotPhonenumber']"
        translate="Error.IsNotPhonenumber">
    </div> <!--Does not pass THE Phonenumber INPUT Validation  -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['validatePhoneNumber']"
        translate="Error.IsNotPhonenumber">
    </div>
    <!--Does not pass our Password Validation  -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['IsNotAcceptedPassword']"
        translate="Error.IsNotAcceptedPassword">
    </div>
    
    <!-- Cannot contain only WhiteSpace -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['cannotContainSpace']"
        translate="Error.RequiredNoWhiteSpace">
    </div>
    <!-- Is Not Equal To -->
    <div class="invalid-feedback d-block" *ngIf="inputFormControl.errors?.['IsNotEqual']"
        translate="Error.IsNotEqual">
    </div>


</ng-container>