import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-greet-user',
    templateUrl: './greet-user.component.html'
})
export class GreetUserComponent implements OnInit, OnDestroy {

    translations: any = <any>{};
    public userfullName!: string;
    private usersubscription!: Subscription;
    greeting!: string;
    greetingTranslationKey: string = "Request.View.Summary.Goodday";
    translationParameters = {};
    constructor(private authenticationService: AuthenticationService) {
    }
    ngOnDestroy(): void {
        if (this.usersubscription) {
            this.usersubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        // we want to greet user by name so get the username:
        this.usersubscription = this.authenticationService.currentUser.subscribe((userData) => {
            this.userfullName = userData.fullName;
        });

        // now verify the moment of Day for a better message
        let date = new Date();
        let hrs = date.getHours();
        let mins = date.getMinutes();
        let momentOfDayKey = "GooddayUser";
        if (hrs >= 5 && ((hrs == 5 && mins >= 30) || (hrs > 5 && hrs < 12))) {
            momentOfDayKey = 'GoodmorningUser';
        } else if (hrs >= 12 && hrs < 18) {
            momentOfDayKey = 'GoodafternoonUser';
        } else if ((hrs >= 18 && hrs < 24) || hrs >= 0) {
            momentOfDayKey = 'GoodeveningUser';
        }
        // create the key
        this.greetingTranslationKey = "Request.View.Summary." + momentOfDayKey
        this.translationParameters = { userName: this.userfullName };
    }

}
