import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { documentAdoption } from '../models/documentAdoption';
import { Observable } from 'rxjs';
import { WithdrawRequestsRequestModel } from '../modals/withdraw-request/withdraw-requests-request.model';
import { CreditSummaryModel } from '../models/CreditSummary.model';

@Injectable({
  providedIn: 'root'
})
export class DossierService {

  constructor(private httpClient: HttpClient) { }


  GetCreditSummary(requestGuid: string): Observable<CreditSummaryModel> {
    return this.httpClient.get<CreditSummaryModel>(environment.apiUrl + "/requests/" + requestGuid + "/creditsummary");
  }
  withdrawRequests(request: WithdrawRequestsRequestModel) {
    return this.httpClient.post(environment.apiUrl + "/requests/WithdrawInvites", request);
  }

  async createDossier(name: string, workgroupId: string, clearancelevel: any, workflowId?: string) {
    return this.httpClient.post(environment.apiUrl + "/requests", { "Name": name, "WorkgroupId": workgroupId, "Clearancelevel": clearancelevel, "WorkflowId": workflowId });
  }

  async updateDossier(dossierId: string, name: string, workgroupId: string, clearancelevel: any) {
    return this.httpClient.put(environment.apiUrl + "/requests/" + dossierId + "", { "Name": name, "WorkgroupId": workgroupId, "Clearancelevel": clearancelevel });
  }

  async setProperty(dossierId: string, key: string, value: string) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/properties", { "Key": key, "Value": value });
  }

  async sendRequest(dossierId: string) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/Send", {});
  }

  async startWorkflow(dossierId: string) {
    return this.httpClient.post(environment.apiUrl + "/" + dossierId + "/StartWorkflow", {});
  }


  downloadReviewcopy(dossierId: string) {
    return this.httpClient.get(environment.apiUrl + "/requests/" + dossierId + "/Reviewcopy", { observe: 'response', responseType: 'blob' });
  }

  async downloadRequest(dossierId: string) {
    return this.httpClient.get(environment.apiUrl + "/requests/" + dossierId + "/Download", { observe: 'response', responseType: 'blob' });
  }

  async sign(dossierId: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/Sign", {}, httpOptions);
  }

  signDone(dossierId: string, signDoneSession: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/SignDone", signDoneSession, httpOptions);
  }

  getReceivers(dossierId: string) {
    return this.httpClient.get(environment.apiUrl + "/requests/" + dossierId + "/GetReceivers");
  }

  async ChangeOwner(dossierId: string) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/setDossierOwner", {})
  }

  updateWorkgroupForRequests(workgroupId: string, clearancelevel: number = 1, requestids: string[]) {
    return this.httpClient.put(environment.apiUrl + "/requests/workgroup", { workgroupId, clearancelevel, requestids })
  }
}
