
/**
 *  A small helper regarding strings
 */
export class StringHelper {

    /**
     * Verifies 'Magic strings' with the expected model
     * Usefull to prevent invalid updates, and instead sortha reference the model
     * @param name property name as a string
     * @returns the name again, but now it has type checking
     */
    public TypedNameProperty<T>(name: keyof T) {
        return name;
    }

    /**
     * Validates the input string to only contain letters and/or Numbers
     * @param inputString 
     * @returns Boolean whether it is either empty, whitespace, null or ONLY letters and numbers
     */
    public IsOnlyLettersAndNumbers(inputString: string | null): boolean {
        if (!inputString || inputString == "" || inputString.trim() == "") {
            return true;
        }
        let regex = new RegExp("^['()\\sa-zA-ZÀ-ÿ\u00f1\u00d10-9.-]*$");
        let regexResult = regex.test(inputString);
        return regexResult;
    }

    /**
     * Validates the input string to only contain letters
     * @param inputString 
     * @returns Boolean whether it is either empty, whitespace, null or ONLY letters
     */
    public IsOnlyLetters(inputString: string | null): boolean {
        if (!inputString || inputString == "" || inputString.trim() == "") {
            return true;
        }
        let regex = new RegExp("^[a-zA-Z]*$");
        let regexResult = regex.test(inputString);
        return regexResult;
    }

    /**
     * This function can take a 'File(name)' or atleast a string which should be a full filename: 'jaarrekening 2027.pdf' and parse it to a valid Requestname
     * @param inputString Any string but works best with file names (file.pdf, jaarrekening 2024.xbrl, gekke.naam.test.pdf)
     * @param minLength if the given name is shorter than this (Default 4) it adds __ before the string
     * @param maxLength if the given name is longer than this (default 495 (DB has 500 but lets not cut it close)) is cuts the string after maxlength
     * @returns 
     */
    public ParseFileNameToRequestName(inputString: string | null, minLength: number = 4, maxLength = 495) {
        var stringToVerify = inputString;
        if (!stringToVerify || stringToVerify == "" || stringToVerify.trim() == "") {
            stringToVerify = "Verzoek"; // Tijd voor de engelse om maar een nederlands woord te leren
        }

        if (stringToVerify.indexOf('.') != -1) {
            // this is some magic code that splits on '.' (since we expect file.extension)
            // but since we want to skip the LAST extension and still function with 'test.file.test.pdf'
            // we do some slice magic here, IF end == -1, it takes MAX-1. so an array with 5 dots would slice to: (0,4) 4=(5-1)
            stringToVerify = stringToVerify.split('.').slice(0, -1).join('.');
        }


        if (stringToVerify.length < minLength) {
            // this basically makes: '_____Na' and than takes the minlength of the end: '__na' it cuts of the beginning so the important part is retained
            return ('___________' + stringToVerify).slice((minLength * -1)); // IF THIS IS ALSO SHORTER THAN MINLENGTH SHAME ON US FOR CREATING A MONSTER THAT ONLY THROWS
        }
        if (stringToVerify.length > maxLength) {
            return stringToVerify.slice(0, maxLength);
        }

        return stringToVerify;
    }

    /**
     * Validates the input string to only contain  Numbers
     * @param inputString 
     * @returns Boolean whether it is either empty, whitespace, null or ONLY numbers
     */
    public IsOnlyNumbers(inputString: string | null): boolean {
        if (!inputString || inputString == "" || inputString.trim() == "") {
            return true;
        }
        let regex = new RegExp("^[0-9]*$");
        let regexResult = regex.test(inputString);
        return regexResult;
    }

    /**
     * Validates the input string to our Email Validation/RegEx
     * @param inputString 
     * @returns Boolean whether it is either empty, whitespace, null or An EMAIL
     */
    public IsEmail(inputString: string | null): boolean {
        if (!inputString || inputString == "" || inputString.trim() == "") {
            return true;
        }
        let regex = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,15}$");
        let regexResult = regex.test(inputString);
        return regexResult;
    }
    /**
     * Validates the input string to our Email Validation/RegEx
     * @param inputString 
     * @returns Boolean whether it is either empty, whitespace, null or An EMAIL
     */
    public IsAcceptedPassword(inputString: string | null): boolean {
        if (!inputString || inputString == "" || inputString.trim() == "") {
            return true;
        }
        let regex = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,100}");
        let regexResult = regex.test(inputString);
        return regexResult;
    }
    /**
     * Counts the words in the given String
     * @param inputString 
     * @returns the 'word' count, any amount of text seperated with Spaces is a word. returns zero for Null or Empty Strings
     */
    public countWords(inputString: string | null): number {
        if (!inputString) {
            return 0;
        }

        let splitWords = inputString.split(' ');
        let noEmptyOrWhiteSpaceWords = splitWords.filter(x => x != null && x != " " && x != "");
        let countedWords = noEmptyOrWhiteSpaceWords.length;
        return countedWords;
    }

    /**
     * Replaces html br thingies for display \r\n storage
     * Works with: ReplaceRN 
     * @param inputString 
     * @returns the same string with BR replaced for \r\r, or empty for NULL
     */
    public ReplaceBrToRN(inputString: string | null): string {
        if (!inputString) {
            return "";
        }

        return inputString.replace(/<br \/>/g, '\r\n');
    }
    /**
     * Replaces \r\n newline markup thingies for database <br> storage
     * Works with: ReplaceBr 
     * @param inputString 
     * @returns the same string with \r\n replaced for BR, or empty for NULL
     */
    public ReplaceRNToBr(inputString: string | null): string {
        if (!inputString) {
            return "";
        }

        return inputString.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
}