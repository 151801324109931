import { Component, Input } from '@angular/core';
import { DataTypeEnum } from 'src/app/constants/data-type.enum';

@Component({
  selector: 'app-deadline',
  templateUrl: './deadline.component.html'
})
export class DeadlineComponent {

  public get DataTypeEnum() {
    return DataTypeEnum;
  }

  @Input() dateSigned!: Date;
  @Input() deadline!: Date;
  @Input() deadlineDays!: any;

  constructor() {
    // Must not be empty. It's approved by a comment.
  }
}