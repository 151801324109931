import { Injectable } from "@angular/core";
import { ViewerDataService } from "./viewer-data.service";
import { Router } from "@angular/router";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PersonService } from "src/app/services/person.service";
import { PerformActionAdoptModal } from "./modals/perform-action-adopt.modal/perform-action-adopt.modal";
import { PerformActionFileModal } from "./modals/perform-action-file.modal/perform-action-file.modal";
import { DisplayRequestUnavailableModal } from "./modals/display-request-unavailable.modal/display-request-unavailable.modal";
import { PerformedActionRejectModal } from "./modals/perform-action-reject.modal/perform-action-reject.modal";
import { PerformActionApproveModal } from "./modals/perform-action-approve.modal/perform-action-approve.modal";
import { ActionSummaryModal, ActionSummaryModalOptions } from "./modals/action-summary/action-summary.modal";
import { PerformedActionRejectedModal, RejectedModalOptions } from "./modals/performed-action-rejected/performed-action-rejected.modal";
import { PerformedActionAwaitingFilingModal, AwaitingFilingModalOptions } from "./modals/performed-action-awaiting-filing/performed-action-awaiting-filing.modal";
import { PerformedActionAwaitingOtherPeopleModal, AwaitingOtherPeopleModalOptions } from "./modals/performed-action-awaiting-other-people/performed-action-awaiting-other-people.modal";
import { PerformActionPendingModal } from "./modals/perform-action-pending/perform-action-pending.modal";
import { formatDate } from '@angular/common';
import { DisplayRequestcompletedModal } from "./modals/display-request-completed/display-request-completed.modal";
import { PerformedActionWithdrawnModal } from "./modals/performed-action-withdrawn/performed-action-withdrawn.modal";
import { PerformActionLinkingModal } from "./modals/perform-action-linking.modal/perform-action-linking.modal";

declare function startConfetti(): void;
declare function stopConfetti(): void;

/**
 * THE service for the viewer that can open MODALS (actions)
 * So all Components of the Viewer can open Modals related to the VIEWER request without actually needing all INFO
 */

@Injectable()
export class ViewerModalService {


    constructor(public viewerDataService: ViewerDataService, public router: Router, public personService: PersonService,
        private modalService: NgbModal) {

    }

    /**
     *  Opens an Modal that the request is Completed
     * @returns ModalRef of @see DisplayRequestcompletedModal
     */
    public openRequestcompletedModal(): NgbModalRef | null {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            // THIS takes priority
            this.modalService.dismissAll();
        }
        startConfetti();
        setTimeout(() => {
            stopConfetti();
        }, 5000);
        let ngbModalOptions: NgbModalOptions = {
            backdrop: false,
            windowClass: 'dialog-bg-white',
            keyboard: false,
            centered: true,
            size: 'md'
        };
        // grab properties
        let request = this.viewerDataService.request;
        const modalRef = this.modalService.open(DisplayRequestcompletedModal, ngbModalOptions);
        (modalRef.componentInstance as DisplayRequestcompletedModal).expires = formatDate(request.expires ? request.expires : "", "dd-MM-y HH:mm:ss zzzz", "en-US");
        return modalRef;
    }

    /**
     *  Opens an Modal that the request is UNAVAILABLE
     */
    public openRequestUnavailable() {
        // this shouldnt check open modals, but close them and open this with priority:
        this.modalService.dismissAll();
        // options:
        let ngbModalOptions: NgbModalOptions = {
            keyboard: false,
            centered: true,
            size: 'md',
            backdrop: 'static',
            fullscreen: true,
            windowClass: 'overlay-fullscreen'
        };
        this.modalService.open(DisplayRequestUnavailableModal, ngbModalOptions);
    }

    /**
     *  Opens an Modal for Rejection the request As Actor
     * Allows handling of the rejection by the Caller
     * @returns ModalRef of @see PerformedActionRejectModal
     */
    public openActorReject(): NgbModalRef | null {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            return null;
        }
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            size: 'md'
        };


        // get the shared info
        let requestName = this.viewerDataService.get_RequestName;

        const modalRef = this.modalService.open(PerformedActionRejectModal, ngbModalOptions);
        (modalRef.componentInstance as PerformedActionRejectModal).requestName = requestName;
        return modalRef;
    }


    /**
     *  Opens an Modal for Approving the request As Actor
     * @returns ModalRef of @see PerformActionApproveModal
     */
    public openActorApprove(): NgbModalRef | null {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            return null;
        }
        let ngbModalOptions: NgbModalOptions = {
            keyboard: false,
            centered: true,
            size: 'md',
            backdrop: 'static'
        };


        const approveModalReference = this.modalService.open(PerformActionApproveModal, ngbModalOptions);
        return approveModalReference;
    }

    /**
     *  Opens an Modal for Linking the Document/request As Actor
     * @returns ModalRef of @see PerformActionLinkingModal
     */
    public openActorLinking(): NgbModalRef | null {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            return null;
        }
        let ngbModalOptions: NgbModalOptions = {
            keyboard: false,
            centered: true,
            size: 'md',
            backdrop: 'static'
        };


        const linkingModalReference = this.modalService.open(PerformActionLinkingModal, ngbModalOptions);
        return linkingModalReference;
    }

    /**
     *  Opens a Pending Modal with custom TRANSLATED message
     */
    public openPendingDialog(translatedDisplayMessage: string) {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            // THIS takes priority
            this.modalService.dismissAll();
        }

        let ngbModalOptions: NgbModalOptions = {
            keyboard: false,
            centered: true,
            size: 'md',
            backdrop: 'static',
            windowClass: "pending"
        };

        const modalRef = this.modalService.open(PerformActionPendingModal, ngbModalOptions);
        (modalRef.componentInstance as PerformActionPendingModal).displayMessage = translatedDisplayMessage;
    }

    /**
      *  Opens a modal for when everything for the USER is done, but there are other actors 
     */
    public openWaitingOtherPeople() {

        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            // THIS takes priority
            this.modalService.dismissAll();
        }

        // get the shared info
        let requestStatus = this.viewerDataService.get_RequestStatus;
        let documents = this.viewerDataService.documents;
        let actions = this.viewerDataService.userActors;

        const modalRef = this.modalService.open(PerformedActionAwaitingOtherPeopleModal, AwaitingOtherPeopleModalOptions);

        modalRef.componentInstance.actions = actions;
        modalRef.componentInstance.documents = documents;
        modalRef.componentInstance.requestStatus = requestStatus;
    }

    /**
      *  Opens a modal for when everything is done, but the request is BEING filed 
      * (Depends on request status)
     */
    public openWaitingFiling() {

        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            // THIS takes priority
            this.modalService.dismissAll();
        }

        // get the shared info
        let requestId = this.viewerDataService.get_RequestGuid;
        let requestStatus = this.viewerDataService.get_RequestStatus;
        let requestType = this.viewerDataService.get_RequestType;
        let documents = this.viewerDataService.documents;
        let actions = this.viewerDataService.userActors;


        const modalRef = this.modalService.open(PerformedActionAwaitingFilingModal, AwaitingFilingModalOptions);
        modalRef.componentInstance.requestId = requestId;
        modalRef.componentInstance.actions = actions;
        modalRef.componentInstance.documents = documents;
        modalRef.componentInstance.requestStatus = requestStatus;
        modalRef.componentInstance.requestType = requestType;
    }


    /**
      *  Opens an Modal for the action summary
     */
    public OpenActionSummary(): void {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            return;
        }

        const modalRef = this.modalService.open(ActionSummaryModal, ActionSummaryModalOptions);

        // get the shared info
        let requestName = this.viewerDataService.get_RequestName;
        let requestStatus = this.viewerDataService.get_RequestGuid;
        let documents = this.viewerDataService.documents;
        let actions = this.viewerDataService.userActors;

        (modalRef.componentInstance as ActionSummaryModal).myActors = actions;
        (modalRef.componentInstance as ActionSummaryModal).documents = documents;
        (modalRef.componentInstance as ActionSummaryModal).requestStatus = requestStatus;
        (modalRef.componentInstance as ActionSummaryModal).requestName = requestName;
    }

    /**
         *  Opens an Modal After a request has been rejected
     * @param reasonForRejection Display reason why rejected
     */
    public OpenRejected(reasonForRejection: string): void {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            // THIS takes priority
            this.modalService.dismissAll();
        }

        // get the shared info
        let requestStatus = this.viewerDataService.get_RequestGuid;
        let documents = this.viewerDataService.documents;
        let actions = this.viewerDataService.userActors;

        const modalRef = this.modalService.open(PerformedActionRejectedModal, RejectedModalOptions);

        (modalRef.componentInstance as PerformedActionRejectedModal).requestStatus = requestStatus;
        (modalRef.componentInstance as PerformedActionRejectedModal).documents = documents;
        (modalRef.componentInstance as PerformedActionRejectedModal).actions = actions;
        (modalRef.componentInstance as PerformedActionRejectedModal).rejectionReason = reasonForRejection;

    }


    /**
         *  Opens an Modal After a request has been withdrawn
     * @param reasonForWithdraw Display reason why withdrawn
     */
    public OpenWithdrawn(reasonForWithdraw: string): void {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            // THIS takes priority
            this.modalService.dismissAll();
        }

        // get the shared info
        let requestStatus = this.viewerDataService.get_RequestGuid;
        let documents = this.viewerDataService.documents;
        let actions = this.viewerDataService.userActors;

        const modalRef = this.modalService.open(PerformedActionWithdrawnModal, RejectedModalOptions);

        (modalRef.componentInstance as PerformedActionWithdrawnModal).requestStatus = requestStatus;
        (modalRef.componentInstance as PerformedActionWithdrawnModal).documents = documents;
        (modalRef.componentInstance as PerformedActionWithdrawnModal).actions = actions;
        (modalRef.componentInstance as PerformedActionWithdrawnModal).withdrawReason = reasonForWithdraw;

    }


    /**
     *  Opens an Modal for Filing the request As Actor
     * @returns ModalRef of @see PerformActionFileModal
     */
    public openActorFile(): NgbModalRef | null {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
        }
        // set the default modal options
        let ngbModalOptions: NgbModalOptions = {
            keyboard: false,
            centered: true,
            size: 'md',
            backdrop: 'static'
        };
        // get the shared info
        let requestName = this.viewerDataService.get_RequestName;
        let requestType = this.viewerDataService.get_RequestType;
        let requestGuid = this.viewerDataService.get_RequestGuid;
        let userModel = this.viewerDataService.get_User;
        // open specific modal
        let performActionModal = this.modalService.open(PerformActionFileModal, ngbModalOptions);
        performActionModal.componentInstance.requestId = requestGuid;
        performActionModal.componentInstance.requestType = requestType;
        performActionModal.componentInstance.jobname = requestName;
        performActionModal.componentInstance.user = userModel;
        return performActionModal;
    }


    /**
     *  Opens an Modal for Adopting the request As Actor
     * @returns ModalRef of @see PerformActionAdoptModal
     */
    public openActorAdopt(): NgbModalRef | null {
        // lets not 'patience' these modals
        if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
        }
        // set the default modal options
        let ngbModalOptions: NgbModalOptions = {
            keyboard: false,
            centered: true,
            size: 'md',
            backdrop: 'static'
        };
        // get the shared info
        let requestName = this.viewerDataService.get_RequestName;
        let userModel = this.viewerDataService.get_User;
        // open specific modal
        let performActionModal = this.modalService.open(PerformActionAdoptModal, ngbModalOptions);
        performActionModal.componentInstance.jobname = requestName;
        performActionModal.componentInstance.user = userModel;
        return performActionModal;

    }

}