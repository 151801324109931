import { Component, Input } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DocumentActor } from 'src/app/models/actors';


export const AwaitingOtherPeopleModalOptions: NgbModalOptions = {
  keyboard: false,
  centered: true,
  size: 'lg',
  backdrop: 'static'
};

@Component({
  templateUrl: './performed-action-awaiting-other-people.modal.html'
})
export class PerformedActionAwaitingOtherPeopleModal  {
  @Input() actions!: DocumentActor[];
  @Input() documents!: any[];
  @Input() requestStatus!: string;
}
