export enum ActorActionEnum {
    Unspecified = "Unspecified",
    /**
     * a Sign action, places a Signature on the document
     * Attaches the Actor as an signer on the DC and places the signature on the document
     */
    Sign = "Sign",
    /**
     * 'Approves' the document, this can be of any type.
     * attaches the user as an Approver on the DC
     */
    Approve = "Approve",
    
    /**
     * 'Links' the document, this can be of any type.
     * attaches the user/Document on the DC
     */
    Linking = "Linking",
    /**
     * An Actor that must download/View the request as action before completing the Request
     */
    Download = "Download",

    /**
     * Sets properties on the document, or attaches it to the document for certain financial flows (vaststellen)
     */
    Adopt = "Adopt",
    /**
     * Sends the Request and its documents to the 3rd party.
     */
    File = "File",

    /**
     * An Actor that CAN download the request AFTER completion
     */
    OptionalDownload = "OptionalDownload",
}

export function Is_Sign_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return ValidateAction(inputValidation as string, ActorActionEnum.Sign);
}

/**
 * Validates that input is a valid string, and that it is NOT the Sign action.
 * @param inputValidation 
 * @returns False: STRING is not a valid string, or its the 'Sign' Action, true: valid string and NOT 'Sign'
 */
export function Is_NON_Sign_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return !ValidateAction(inputValidation as string, ActorActionEnum.Sign);
}
/**
 * Validates that input is a valid string, and that it is the Sign OR Approve action.
 * @param inputValidation 
 * @returns False: STRING is not a valid string, or its Neither 'Sign'/'Approve' Action, true: valid string and 'Sign'/'Approve
 */
export function Is_Sign_OR_Approve_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return ValidateAction(inputValidation as string, ActorActionEnum.Sign) || ValidateAction(inputValidation as string, ActorActionEnum.Approve)|| ValidateAction(inputValidation as string, ActorActionEnum.Linking);
}
export function Is_Approve_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return (ValidateAction(inputValidation as string, ActorActionEnum.Approve) || ValidateAction(inputValidation as string, ActorActionEnum.Linking));
}

/**
 * IS either download or Optional download
 * @param inputValidation 
 * @returns 
 */
export function Is_ANY_Download_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return ValidateAction(inputValidation as string, ActorActionEnum.Download) || ValidateAction(inputValidation as string, ActorActionEnum.OptionalDownload);
}
/**
 * IS SPECIFICALLY Optional download
 * @param inputValidation 
 * @returns 
 */
export function Is_Download_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return ValidateAction(inputValidation as string, ActorActionEnum.Download);
}
/**
 * IS SPECIFICALLY Optional download
 * @param inputValidation 
 * @returns 
 */
export function Is_OptionalDownload_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return ValidateAction(inputValidation as string, ActorActionEnum.OptionalDownload);
}

/**
 * Validates that input is a valid string, and that it is NOT the download action.
 * @param inputValidation 
 * @returns False: STRING is not a valid string, or its the 'Download' Action, true: valid string and NOT 'Download'
 */
export function Is_NON_Download_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return !ValidateAction(inputValidation as string, ActorActionEnum.Download) && !ValidateAction(inputValidation as string, ActorActionEnum.OptionalDownload);
}
export function Is_Adopt_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return ValidateAction(inputValidation as string, ActorActionEnum.Adopt);
}

export function Is_File_Action(inputValidation: any): boolean {
    if (!ValidateStringInput(inputValidation)) {
        return false;
    }

    return ValidateAction(inputValidation as string, ActorActionEnum.File);
}

function ValidateAction(inputValidation: string, asAction: ActorActionEnum): boolean {
    return inputValidation.trim().toLowerCase() == asAction.toLowerCase();
}
function ValidateStringInput(inputValidation: any): boolean {
    if (!inputValidation) {
        return false;
    }
    if (inputValidation === "") {
        return false;
    }
    if (!(typeof inputValidation === 'string' || inputValidation instanceof String)) {
        return false;
    }
    return true;
}