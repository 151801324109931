import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeago',
  pure: false
})
export class TimeAgoPipe implements PipeTransform {
  translations = <any>{};
  dayName: string | undefined;

  constructor(public translateService: TranslateService) {
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    })
  }

  getTranslations() {
    this.translateService.get([
      'TimeAgo.JustNow',
      'TimeAgo.Minute',
      'TimeAgo.Minutes',
      'TimeAgo.Hour',
      'TimeAgo.Hours',
      'TimeAgo.Day',
      'TimeAgo.Days',
      'TimeAgo.Week',
      'TimeAgo.Weeks',
    ]).subscribe(translation => {
      this.translations.justNow = translation['TimeAgo.JustNow'];
      this.translations.minute = translation['TimeAgo.Minute'];
      this.translations.minutes = translation['TimeAgo.Minutes'];
      this.translations.hour = translation['TimeAgo.Hour'];
      this.translations.hours = translation['TimeAgo.Hours'];
      this.translations.day = translation['TimeAgo.Day'];
      this.translations.days = translation['TimeAgo.Days'];
      this.translations.week = translation['TimeAgo.Week'];
      this.translations.weeks = translation['TimeAgo.Weeks'];
    });
  }

  private checkPeriod(dateSeconds: number, intervalSeconds: number, translationOne: string, translationMany: string): string {
    let interval = dateSeconds / intervalSeconds;

    if (interval > 1 && interval < 2) {
        return Math.floor(interval) + translationOne;
    }
    else if (interval > 1) {
        return Math.floor(interval) + translationMany;
    }
    return '';
  }
  
  transform(value: any) {
    if(value == null) return null;
    let seconds = Math.floor((new Date().valueOf() - value.valueOf()) / 1000);

    let timeAgoValue = this.checkPeriod(seconds, 604800, this.translations.week, this.translations.weeks);
    if (timeAgoValue != '') return timeAgoValue;

    timeAgoValue = this.checkPeriod(seconds, 86400, this.translations.day, this.translations.days);
    if (timeAgoValue != '') return timeAgoValue;

    timeAgoValue = this.checkPeriod(seconds, 3600, this.translations.hour, this.translations.hours);
    if (timeAgoValue != '') return timeAgoValue;

    timeAgoValue = this.checkPeriod(seconds, 60, this.translations.minute, this.translations.minutes);
    if (timeAgoValue != '') return timeAgoValue;

    return this.translations.justNow;
  }
}
