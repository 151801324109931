<div class="modal-body m-0 m-lg-3" style="z-index: 9999;">
    <div class="mb-4">
        <h3 ngbAutofocus translate="Request.View.WaitingFiling.WaitingForFiling"></h3>
    </div>
    <div class="row row-cols-12 default-font-size">
        <app-action-summary [actions]="actions" [documents]="documents" [requestStatus]="requestStatus">
        </app-action-summary>
        <!-- when awaiting 'Filing/Publishing' display an additional custom message/warning as it is currently going on -->
            <div class="row row-cols-12 mb-2">
                <div class="fw-bolder col-12 col-lg-6 text-truncate">
                    <span class="badge perform-action-dialog-list-item rounded-pill me-2 bg-warning"><span class="align-middle text-white">{{maxBadgeNumberPlusOne}}</span>
                    </span>
                    <span translate="Request.View.WaitingFiling.WaitingForConfirmation"></span>
                </div>
                <div class="col-12 col-lg-6 text-truncate">
                </div>
            </div>
    </div>

    <p class="default-font-size" translate="Request.View.WaitingFiling.ReceivingDownloadlink"></p>

    <div class="d-table w-100 mt-4">
        <span class="float-end">
            <app-ideal-payment-button [requestId]="requestId"></app-ideal-payment-button>
        </span>
    </div>
</div>