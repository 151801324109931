import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Crumb } from 'src/app/models/crumb';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CloudLocationsService } from 'src/app/services/cloudlocations.service';
import { LoggingService } from 'src/app/services/logging.service';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import { BaseCloudDocumentModal } from './base-cloud-document.modal';
import CloudItem from 'src/app/models/clouditem';

export const CloudDocumentModalOptions: NgbModalOptions = {
  centered: true, 
  size: 'md', 
  backdrop: 'static'
};

@Component({
  templateUrl: './cloud-document.modal.html',
  styleUrls: ['./cloud-document.modal.css']
})
export class CloudDocumentExportModal extends BaseCloudDocumentModal implements OnInit {

  @Input() enabledPlugins: any;
  @Output() close = new EventEmitter<any>();
  protected override userGuid: string = "";
  public allowMultiselect: boolean = false;
  acceptedDocumentTypes: [] = [];
  public title = "CloudLocations.ExportTitle";
  public showFiles: boolean = false;

  constructor(protected override authenticationService: AuthenticationService, public override activeModal: NgbActiveModal, protected override cloudLocationsService: CloudLocationsService,
    protected override toastService: ToastService,
    protected override translateService: TranslateService,
    protected override translateParser: TranslateParser,
    protected override loggingService: LoggingService) {

    super(authenticationService, activeModal, cloudLocationsService, toastService, translateService, translateParser, loggingService);
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
    this.authenticationService.currentUser.subscribe((data) => {
      this.userGuid = data.sub;
    });
  }
  
  

  ngOnInit(): void {
    this.isSharePoint = this.enabledPlugins?.findIndex((item: { code: string; })=>item.code == "SharePoint")>-1;
    this.isOneDrive = this.enabledPlugins?.findIndex((item: { code: string; })=>item.code == "OneDrive")>-1;
    this.isTeams = this.enabledPlugins?.findIndex((item: { code: string; })=>item.code == "Teams")>-1;
    this.isAfas = this.enabledPlugins?.findIndex((item: { code: string; })=>item.code == "AFASInSite")>-1;
    this.isPinkwebdossier = this.enabledPlugins?.findIndex((item: { code: string; })=>item.code == "PinkwebDossier")>-1;
  }

  public override emitClose(item: CloudItem) {
    this.close.emit(item);
  }
}
