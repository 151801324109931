<div class="alert alert-success border-success w-100 mb-3 user-identification-details">
    <div class="row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-xl-12">
        <div class="col-1">
            <i class="bi bi-shield-fill-check text-success" aria-hidden="true"></i>
        </div>
        <div class="col-11">
            <div class="w-100 text-break">
                <span translate="Request.View.Summary.IdentifiedAs"></span>
                <div class="row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-xl-12 mt-4">
                    <div class="d-flex lh-1">
                        <span class="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2" translate="Request.View.Summary.Name"></span>
                        <span class="col-9 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10"><strong>{{user.fullName}}</strong></span>
                    </div>
                    <div class="d-flex lh-1">
                        <span class="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2" translate="Request.View.Summary.Email"></span>
                        <span class="col-9 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10"><strong>{{user.email}}</strong></span>
                    </div>
                    <div class="d-flex lh-1">
                        <span class="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2" translate=Request.View.Summary.Mobile></span>
                        <span class="col-9 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10"><strong>{{user.phoneNumber}}</strong></span>
                    </div>
                    <div class="d-flex lh-1">
                        <span class="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2" translate="Request.View.Summary.IP"></span>
                        <span class="col-9 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10"><strong>{{ipAdress}}</strong></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>