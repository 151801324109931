import { Pipe, PipeTransform } from '@angular/core';


/**
 * Parses the given Action to a Current tense TRANSLATION KEY.
 */
@Pipe({
    name: 'actionToTranslationKey',
    pure: false
})
export class ActionToTranslationKeyPipe implements PipeTransform {
    transform(actionAsString: string | null) {
        let mainTranslationKey = "dashboard.activitylist.actions.";
        let trimmedKey =  actionAsString?.trim();
        switch (trimmedKey) {
            case null: // ?.trim makes it undefined so we have both to be sure 
            case undefined:
            case "":
                return mainTranslationKey + "NotSet";
            default:
                return mainTranslationKey + trimmedKey;
        }
    }
}