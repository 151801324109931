import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BrandingColours } from '../models/branding';
import { Observable, ReplaySubject, map, share } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class IdentityBrandingService {

  private _wlsettings!: Observable<any[]>;

  constructor(private http: HttpClient) { }
  getBrandingStatusOrganisation(organisationGuid: string) {
    return this.http.get(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/brandingenabled");
  }  
  getBrandingStatusWhiteLabel(whitelabelGuid: string) {
    return this.http.get(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/brandingenabled");
  }
  getBrandingStatusSystem(systemGuid: string) {
    return this.http.get(environment.identityUrl + "/api/system/" + systemGuid + "/branding/brandingenabled");
  }

  async enableBrandingOrganisation(organisationGuid: string){
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/enable", {});
  }
  async enableBrandingWhiteLabel(whitelabelGuid: string){
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/enable", {});
  }
  async enableBrandingSystem(systemGuid: string){
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding/enable", {});
  }

  async disableBrandingOrganisation(organisationGuid: string){
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/disable", {});
  }
  async disableBrandingWhiteLabel(whitelabelGuid: string){
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/disable", {});
  }
  async disableBrandingSystem(systemGuid: string){
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding/disable", {});
  }

  async getBrandingOrganisation(organisationGuid: string){
    return this.http.get(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding");
  }
  async getBrandingWhiteLabel(whitelabelGuid: string){
    return this.http.get(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding");
  }
  async getBrandingSystem(systemGuid: string){
    return this.http.get(environment.identityUrl + "/api/system/" + systemGuid + "/branding");
  }

  async postBrandingOrganisation(organisationGuid: string, brandingPost: FormData){
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding", brandingPost);
  }
  async postBrandingWhiteLabel(whitelabelGuid: string, brandingPost: FormData){
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding", brandingPost);
  }
  async postBrandingSystem(systemGuid: string, brandingPost: FormData){
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding", brandingPost);
  }

  async getGeneralOrganisation(organisationGuid: string){
    return this.http.get(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/general");
  }
  async getGeneralWhiteLabel(whitelabelGuid: string){
    return this.http.get(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/general");
  }
  async getGeneralSystem(systemGuid: string){
    return this.http.get(environment.identityUrl + "/api/system/" + systemGuid + "/branding/general");
  }

  async postGeneralOrganisation(organisationGuid: string, generalData: any){
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/general", generalData);
  }
  async postGeneralWhiteLabel(whitelabelGuid: string, generalData: any){
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/general", generalData);
  }
  async postGeneralSystem(systemGuid: string, generalData: any){
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding/general", generalData);
  }

  async getColoursOrganisation(organisationGuid: string){
    return this.http.get(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/style");
  }
  async getColoursWhiteLabel(whitelabelGuid: string){
    return this.http.get(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/style");
  }
  async getColoursSystem(systemGuid: string){
    return this.http.get(environment.identityUrl + "/api/system/" + systemGuid + "/branding/style");
  }

  async postColoursOrganisation(organisationGuid: string, colours: BrandingColours){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/style", colours, httpOptions);
  }
  async postColoursWhiteLabel(whitelabelGuid: string, colours: BrandingColours){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/style", colours, httpOptions);
  }
  async postColoursSystem(systemGuid: string, colours: BrandingColours){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding/style", colours, httpOptions);
  }

  async deleteColourOrganisation(organisationGuid: string, colour: string){
    return this.http.delete(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/style/" + colour);
  }
  async deleteColourWhiteLabel(whitelabelGuid: string, colour: string){
    return this.http.delete(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/style/" + colour);
  }
  async deleteColourSystem(systemGuid: string, colour: string){
    return this.http.delete(environment.identityUrl + "/api/system/" + systemGuid + "/branding/style/" + colour);
  }
    
  async getEmailOrganisation(organisationGuid: string){
    return this.http.get(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/email");
  }
  async getEmailWhiteLabel(whitelabelGuid: string){
    return this.http.get(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/email");
  }
  async getEmailSystem(systemGuid: string){
    return this.http.get(environment.identityUrl + "/api/system/" + systemGuid + "/branding/email");
  }
  
  async postEmailOrganisation(organisationGuid: string, emailData: any){
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/email", emailData);
  }
  async postEmailWhiteLabel(whitelabelGuid: string, emailData: any){
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/email", emailData);
  }
  async postEmailSystem(systemGuid: string, emailData: any){
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding/email", emailData);
  }
  
  async setAdvancedModeOrganisation(organisationGuid: string, advancedMode: any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/setEmailAdvancedMode", advancedMode, httpOptions);
  }
  async setAdvancedModeWhiteLabel(whitelabelGuid: string, advancedMode: any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/setEmailAdvancedMode", advancedMode, httpOptions);
  }
  async setAdvancedModeSystem(systemGuid: string, advancedMode: any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding/setEmailAdvancedMode", advancedMode, httpOptions);
  }

  async generateEmailOrganisation(organisationGuid: string, emaildata: any){
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/generateEmail", emaildata, {responseType: 'text'});
  }
  async generateEmailWhiteLabel(whitelabelGuid: string, emaildata: any){
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/generateEmail", emaildata, {responseType: 'text'});
  }
  async generateEmailSystem(systemGuid: string, emaildata: any){
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding/generateEmail", emaildata, {responseType: 'text'});
  }

  async sendTestEmailOrganisation(organisationGuid: string, emailData: any){
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/SendTestEmail", emailData);
  }
  async sendTestEmailWhiteLabel(whitelabelGuid: string, emailData: any){
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/SendTestEmail", emailData);
  }
  async sendTestEmailSystem(systemGuid: string, emailData: any){
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding/SendTestEmail", emailData);
  }

  async postUrlsOrganisation(organisationGuid: string, urlData: any) {
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/branding/urls", urlData);
  }
  async postUrlsWhiteLabel(whitelabelGuid: string, urlData: any) {
    return this.http.post(environment.identityUrl + "/api/whiteLabel/" + whitelabelGuid + "/branding/urls", urlData);
  }
  async postUrlsSystem(systemGuid: string, urlData: any) {
    return this.http.post(environment.identityUrl + "/api/system/" + systemGuid + "/branding/urls", urlData);
  }

  getWhitelabelSettings(url: string) {
    if (!this._wlsettings) {
      this._wlsettings = this.http.get<any[]>(environment.identityUrl + "/whitelabel/" + url).pipe(
        map(data => data),
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false
        })
        );
    }
    return this._wlsettings;
  }

  getSSo(url: string) {
    return this.http.get<any[]>(environment.identityUrl + "/api/sso/" + url);
  }
}
