<div class="rounded-corners">
<table mat-table [dataSource]="tableData" cdkDropList cdkDropListOrientation="horizontal" multiTemplateDataRows>
  <!-- Loop columnsToDisplay array -->
  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column.columnName">
    <!-- HEADER -->
    <ng-container *ngIf="column.columnName !== 'actions'">
      <th mat-header-cell cdkDrag *matHeaderCellDef translate="Table.{{column.transTag}}"
        class="col-{{column.noOfColumns}}"></th>
    </ng-container>
    <ng-container *ngIf="column.columnName === 'actions'">
      <th mat-header-cell cdkDrag *matHeaderCellDef class="col-1"></th>
    </ng-container>

    <!-- BODY -->
    <ng-container
      *ngIf="column.columnName !== 'deadline' && column.columnName !== 'actions'">
      <td mat-cell *matCellDef="let actor" class="col-{{column.noOfColumns}}" [ngClass]="{'text-muted': isWaiting(actor.actorStatus)}">
        <app-badge-status-display *ngIf="column.columnName === 'fullName'" [badgeStatus]="convertedStatusToBadgeStatus(actor.actorStatus)"
          class="badge-status-table"></app-badge-status-display>
        {{ actor[column.columnName] | valueByTypePipe : column.type }}
      </td>
    </ng-container>
    <ng-container *ngIf="column.columnName === 'deadline'">
      <td mat-cell *matCellDef="let actor" class="col-{{column.noOfColumns }}" [ngClass]="{'text-muted': isWaiting(actor.actorStatus)}">
        <app-deadline [deadline]="actor.deadline" [dateSigned]="actor.dateSigned" [deadlineDays]="actor.deadlineDays"></app-deadline>
      </td>
    </ng-container>
    <ng-container *ngIf="column.columnName === 'actions'">
      <td mat-cell *matCellDef="let actor" class="col-{{column.noOfColumns}}">
        <span class="float-end">
          <button type="button" class="btn bg-transparent border-0" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi bi-three-dots"></i>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('editPerson', actor.inviteId)"
                id="editPersonButton" [ngClass]="{ 'disabled': !actor.allowedToEdit }"
                translate="Request.Actions.EditPerson">
              </a>
            </li>
            <li>
              <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('resendRequest', actor.inviteId)"
                id="sendReminderButton" [ngClass]="{ 'disabled': !actor.allowedToResendInvite }"
                translate="Request.Actions.SendReminder"></a>
            </li>
            <li *ngIf="actor.allowedToChangeDeadline">
              <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('changeDeadline', actor.inviteId)"
                Id="changeDeadlineButton" translate="Request.Actions.ChangeDeadline">
              </a>
            </li>
            <li *ngIf="!actor.allowedToChangeDeadline">
              <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('changeDeadlineDays', actor.inviteId)"
                [ngClass]="{ 'disabled': !actor.allowedToChangeDeadlineDays}"
                translate="Request.Actions.ChangeDeadlineDays" Id="changeDeadlineDaysButton">
              </a>
            </li>
          </ul>
        </span>
      </td>
    </ng-container>
  </ng-container>

  <ng-container matColumnDef="message">
    <td *matCellDef="let error" mat-cell [attr.colspan]="displayedColumns.length">
      <div class="break-words">
        <div class="alert alert-danger p-1 mt-0 white-space" role="alert">
          {{error.declineMessage}}
        </div>
      </div>
    </td>
  </ng-container>

  <!-- Display data and declare specific embedded component -->
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  <tr mat-row *matRowDef="let row; columns: ['message']; when: hasDeclineMessage"></tr>
</table>
</div>