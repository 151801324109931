import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/** The formcontrol must be set and not only contained whitespaces */
export function CannotContainOnlySpaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let formFieldValue = (control.value as string);
        if (!formFieldValue || formFieldValue.length === 0) {
            return null;
        }
        if (formFieldValue.length > 0 && !(formFieldValue.replace(/\s/g, '').length)) {
            return { cannotContainSpace: true }
        }
        return null;
    };
}