<div>
    <h3 class="title" translate="Request.Document.Dialog.EditDocument"></h3>

    <div class="p-4 pt-4">
        <button type="button" *ngIf="selectedPages.length != document.pages" class="btn btn-sm btn-secondary active" translate="Request.Document.Dialog.SelectAllPages" (click)="selectAll()" [disabled]="fieldsDisabled"></button>
        <button type="button" *ngIf="selectedPages.length == document.pages" class="btn btn-sm btn-secondary active" translate="Request.Document.Dialog.DeselectAllPages" (click)="deselectAll()" [disabled]="fieldsDisabled"></button>
    </div>

    <div class="p-4 pt-0 pb-0 pe-2 mb-3">
        <div class="edit-document-list" id="edit-document-list">
            <ng-container *ngFor="let pageSize of pageSizes; let i = index">
                <input type="checkbox" id="pageInput_{{i+1}}" (change)="changeSelection(i+1)" [checked]="selectedPages.indexOf(i+1) >= 0" [disabled]="fieldsDisabled"/>
                <label for="pageInput_{{i+1}}">
                    <div class="page me-2 mb-2" id="page_{{i+1}}">
                        <canvas *ngIf="!checkIfItemIsRendered(i+1);" [style.width.px]="pageSize.X" [style.height.px]="pageSize.Y" style="background-color: white;" id="{{i+1}}_indicator" class="page-preview"></canvas>
                        <img *ngIf="checkIfItemIsRendered(i+1);" [src]="getPageUrl(i+1)" id="page{{i+1}}" alt="Page{{i+1}}" class="page-preview">
                        <div *ngIf="!checkIfItemIsRendered(i+1);" id="spinner_{{i+1}}" class="spinner-border"></div>
                        <div class="text-center">{{i+1}}</div>
                    </div>
                </label>
            </ng-container>
        </div>
    </div>

    <div class="p-4 pt-2 pb-0">
        <label class="form-label" translate="Request.Document.Dialog.DocumentOptions"></label>
        <div class="input-group cols-12 cursor-pointer">

            <div class="dropdown col-9">
                <button class="form-control dropdown-group-left w-100 text-truncate" [disabled]="!selectedPages || selectedPages!.length == 0" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="float-start" translate="{{dropdownText}}"></span>
                    <em class="bi bi-chevron-down float-end" aria-hidden="true"></em>
                </button>
                <div class="dropdown w-auto d-flex">
                    <ul id="actionDropdown" class="dropdown-menu">
                        <li class="dropdown-item" [class]="{ 'disabled': !selectedPages || selectedPages!.length !== 1}" translate="Request.Document.Dialog.AddDocumentBeforePage" (click)="selectAddDocumentBeforePage()"></li>
                        <li class="dropdown-item" [class]="{ 'disabled': !selectedPages || selectedPages!.length !== 1}" translate="Request.Document.Dialog.AddDocumentAfterPage" (click)="selectAddDocumentAfterPage()"></li>
                        <li class="dropdown-item" [class]="{ 'disabled': !selectedPages || selectedPages!.length == 0 || pageSizes.length == 1 || pageSizes.length == selectedPages.length }" translate="Request.Document.Dialog.DeletePages" (click)="selectDeletePages()"></li>
                        <li class="dropdown-item" [class]="{ 'disabled': !selectedPages || selectedPages!.length == 0}" translate="Request.Document.Dialog.AddStationery" (click)="selectAddStationery()"></li>
                    </ul>
                </div>
            </div>

            <div class="col-3 border-0" *ngIf="selectedAction == null">
                <button class="btn dropdown-group-right btn-sm btn-success text-truncate col-12 h-100" type="button" [disabled]="true" aria-expanded="false" translate="Button.Action"></button>
            </div>

            <div class="dropdown col-3 border-0" *ngIf="showFileSelector()">
                <button class="btn dropdown-group-right btn-sm btn-success dropdown-toggle text-truncate col-12 h-100" type="button" data-bs-toggle="dropdown" aria-expanded="false" translate="Button.Choose"></button>
                <div class="dropdown">
                    <ul class="dropdown-menu w-auto">
                        <input type="file" class="file-input" style="display: none;" (change)="OnFileSelected($event)" accept=".pdf" #fileUpload>
                        <li class="dropdown-item" translate="Button.Upload" (click)="fileUpload.click()"></li>
                        <li *ngIf="showMSplugins" class="dropdown-item" translate="Button.FromCloud" (click)="addDocumentsCloudLocation()"></li>
                    </ul>
                </div>
            </div>

            <div class="col-3 border-0" *ngIf="showDeleteButton()">
                <button class="btn dropdown-group-right btn-sm btn-danger text-truncate col-12 h-100" type="button"  aria-expanded="false" translate="Button.Delete" (click)="deletePages()"></button>
            </div>

            <div class="col-3 border-0" *ngIf="showStationeryButton()">
                <button class="btn dropdown-group-right btn-sm btn-success text-truncate col-12 h-100" type="button"  aria-expanded="false" translate="Button.Choose" (click)="openStationeryModal()"></button>
            </div>
        </div>
    </div>

    <div class="p-4 pt-0 w-100 d-table d-block col-12">
        <button (click)="close()" type="submit" class="btn btn-sm btn-outline-primary float-start mt-4" translate="Button.Close"></button>
    </div>
</div>