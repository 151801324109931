import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-display-amount-based-translation',
    templateUrl: './display-amount-based-translation.component.html'
})
export class DisplayAmountBasedTranslation {
    @Input() mainTranslationKey: string = '';
    @Input() set inputValue(value: number) {
        // no amount or not set: Default
        if (!value) {
            this.translatedText = this.translateService.instant(this.mainTranslationKey + this.defaultAdditionalKey);
            return;
        }
        // 1 amount = SINGULAR
        if (value == 1) {
            this.translatedText = this.translateService.instant(this.mainTranslationKey + this.singularAdditionalKey, { amount: value });
            return;
        }

        // X amount = PLURAL
        if (value > 0) {
            this.translatedText = this.translateService.instant(this.mainTranslationKey + this.pluralAdditionalKey, { amount: value });
            return;
        }
        // fallback: 0 or negative: DEFAULT
        this.translatedText = this.translateService.instant(this.mainTranslationKey + this.defaultAdditionalKey);

    }
    public translatedText = "";

    private defaultAdditionalKey = '.Default';
    private singularAdditionalKey = '.Singular';
    private pluralAdditionalKey = '.Plural';

    constructor(private translateService: TranslateService) { }
}