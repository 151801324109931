import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DocumentActor } from '../models/actors';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(private httpClient: HttpClient) { }

  getDossierPersonWithActions(dossierId: string, dossierPersonId: string){
    return this.httpClient.get(environment.apiUrl + "/requests/"+ dossierId + "/persons/" + dossierPersonId);
  }

  async addDossierPerson(dossierId: string, dossierPerson: DocumentActor){
    return this.httpClient.post(environment.apiUrl + "/requests/"+ dossierId + "/persons", dossierPerson);
  }
  
  async updateDossierPerson(dossierId: string, dossierPerson: DocumentActor){
    return this.httpClient.put(environment.apiUrl + "/requests/"+ dossierId + "/persons/" + dossierPerson.dossierPersonId, dossierPerson)
  }

  async removeDossierPerson(dossierId: string, dossierPerson: DocumentActor){
    return this.httpClient.delete(environment.apiUrl + "/requests/"+ dossierId + "/persons/" + dossierPerson.dossierPersonId)
  }

  async changeOrder(dossierId: string, orderPersons: DocumentActor[]) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/persons/ChangeOrder", orderPersons);
  }
}
