<div class="vh-100 container">
    <div class="row h-100">
        <div class="col-sm-12 my-auto pt-4 text-center error-message">
            <div class="mb-4"><i class="bi bi-window-x error-icon"></i></div>
            <div class="error-404 mb-1" translate="General.Error.PageDoesntExistTitle"></div>
            <div class="error-404-smaller mb-1" translate="General.Error.PageDoesntExist"></div>
            <a routerLink="/" class="back-to-dashboard text-decoration-none cursor-pointer" translate="Button.BackToDashboard"></a>
        </div>
    </div>
</div>
