import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DossierService } from 'src/app/services/dossier.service';
import { ViewerDataService } from '../../viewer-data.service';
import { ActorActionsApiClient } from 'src/app/services/api-clients/actor-actions.api-client';

@Component({
  templateUrl: './perform-action-file.modal.html'
})
export class PerformActionFileModal implements OnInit {

  @Input() jobname!: string;
  @Input() requestType!: string;
  @Input() requestId!: string;
  @Input() isPdf!: boolean;
  @Output() fileClicked = new EventEmitter();
  @Output() closeClicked = new EventEmitter();
  public awaitingAction: boolean = false;
  public receiverfieldIsSet: boolean = false;

  public get disableButton(): boolean {
    return this.awaitingAction || !this.receiverfieldIsSet;
  }
  translations = <any>{};
  receivingEntity!: string;
  selectReceiver: boolean = false;
  receiverId!: string;
  receiverName!: string;
  receiverText: string = "Request.View.Summary.Choose";
  receivers = <any>[];


  /**
   * Number of the current actor action
   */
  expectedActionNumber: number = 1;
  currentActorInviteId!:string;

  constructor(public activeModal: NgbActiveModal, private translateService: TranslateService, private dossierService: DossierService, private viewerDataService: ViewerDataService,
    private actorActionsApiClient :ActorActionsApiClient
  ) {
    this.viewerDataService.getCurrentActor$().subscribe(currentActor => {
      this.currentActorInviteId = currentActor.id;
      this.expectedActionNumber = currentActor.badgeNumber;
    });
    
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }

  GetReceivers() {
    this.receivers = this.viewerDataService.receivers;
  }
  setReceiver(id: string, name: string) {
    this.receiverName = name;
    this.receiverId = id;
    if (id && name) {
      this.selectReceiver = false;
    } else {
      this.selectReceiver = true;
    }
    this.receiverfieldIsSet = !this.selectReceiver;
  }
  ngOnInit(): void {
   this.requestType = this.viewerDataService.get_RequestType;
   if (this.requestType === 'IncomeTaxDeclaration' ||
      this.requestType === 'IcpDeclaration' ||
      this.requestType === 'VatDeclaration' ||
      this.requestType === 'CorporateTaxDeclaration') {
      this.receivingEntity = this.translations.TaxAdministration;
    } else if (this.requestType === 'SbrNexusAnnualReport' ||
      this.requestType === 'SbrNexusAssurance' ||
      this.requestType === 'NoabReport') {
      this.receivingEntity = "SbrNexus";
      this.selectReceiver = true;
      this.GetReceivers();
    } else {
      this.receivingEntity = this.translations.KVK;
    }
    this.getTranslations();
    this.receiverfieldIsSet = !this.selectReceiver;
  }

  getTranslations() {
    this.translateService.get([
      'Request.View.ReceivingEntity.TaxAdministration',
      'Request.View.ReceivingEntity.KVK'
    ]).subscribe(translation => {
      this.translations.TaxAdministration = translation['Request.View.ReceivingEntity.TaxAdministration'];
      this.translations.KVK = translation['Request.View.ReceivingEntity.KVK'];
    });
  }

  back() {
    this.activeModal.close();
  }
  clickFile() {
    this.awaitingAction = true;
      this.actorActionsApiClient.file(this.viewerDataService.get_RequestGuid, this.currentActorInviteId, this.receiverId).subscribe(() => {
        this.viewerDataService.actorCompletedAction(this.currentActorInviteId);
        this.activeModal.close();
      });
  }

}
