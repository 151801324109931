
import { Component, Input } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DocumentActor } from 'src/app/models/actors';


export const WithdrawnModalOptions: NgbModalOptions = {
  keyboard: false,
  centered: true,
  size: 'lg',
  backdrop: 'static'
};

@Component({
  templateUrl: './performed-action-withdrawn.modal.html'
})
export class PerformedActionWithdrawnModal {
  @Input() actions!: DocumentActor[];
  @Input() documents!: any[];
  @Input() requestStatus!: string;
  @Input() withdrawReason!: string;
}
