import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TagModel } from 'ngx-chips/core/tag-model';
import { StringHelper } from 'src/app/directives/string-helper';

@Component({
    selector: 'app-tag-string-input',
    templateUrl: './tag-string-input.component.html'
})
export class TagStringInputComponent implements ControlValueAccessor {

    @Input() set values(input: string[]) {
        input.forEach(defaultValue => this.valueAsTagModels.push({ readonly: false, display: defaultValue }));
        this._values = input;
    }
    get values(): string[] {
        return this._values;
    }
    /**
     * Whether all input must be converted and checked on Lowercase
     */
    @Input() onlyLowerCase = false;
    /**
     * Whether all input must be Letters
     */
    @Input() onlyLetters = false;
    /**
     * Whether all input must be Letters or Numbers
     */
    @Input() onlyLettersAndNumbers = false;
    /** Sets unremovable default Values THESE ARE EXCLUDED FROM VALUES */
    @Input() set defaultValues(input: string[]) {
        if (input) {
            input.forEach(defaultValue => this.valueAsTagModels.unshift({ readonly: true, display: defaultValue }));
        }
    }

    @Output() valueChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

    public disabled = false;
    /**
     * Custom error for when only Letters are allowed
     */
    public OnlyLettersAllowedError = false;
    public OnlyLettersAndNumbersAllowedError = false;
    public OnlyLowerCaseAllowed = false;

    protected separatorKeys: number[] = [9, 32, 188] //keycodes: 9 = tab, 32 = spatie, 188 = komma
    private helper = new StringHelper();
    private _values: string[] = [];
    public customErrors = {
        'onlyLettersAllowed': 'Only Letters',
        'onlyLowerCase': 'Onlu Lower'
    };

    valueAsTagModels: TagModel[] = [];



    public OnlyLettersValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        this.OnlyLettersAllowedError = false;
        if (!this.onlyLetters) {
            return null;
        }
        if (!this.helper.IsOnlyLetters(control.value)) {
            this.OnlyLettersAllowedError = true;
            return {
                'onlyLettersAllowed': true
            };
        }

        return null;
    }

    public OnlyLettersAndNumbersValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        this.OnlyLettersAndNumbersAllowedError = false;
        if (!this.onlyLettersAndNumbers) {
            return null;
        }
        if (!this.helper.IsOnlyLettersAndNumbers(control.value)) {
            this.OnlyLettersAndNumbersAllowedError = true;
            return {
                'onlyLettersAndNumbersAllowed': true
            };
        }

        return null;
    }

    public onlyLowerCaseValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        this.OnlyLowerCaseAllowed = false;
        if (!this.onlyLowerCase) {
            return null;
        }
        var value = control.value;
        if (value != control.value.toLowerCase()) {
            this.OnlyLowerCaseAllowed = true;
            return {
                'onlyLowerCase': true
            };
        }

        return null;
    }
    public validators = [this.OnlyLettersValidator, this.OnlyLettersAndNumbersValidator, this.onlyLowerCaseValidator];

    onItemAdded(event: any) {
        var valueToUse = event.value;
        if (this.values.indexOf(event.value) > -1) {
            return;
        }
        let pillIndex = this.valueAsTagModels.findIndex((s: any) => s.display === valueToUse);
        if (pillIndex > -1) {
            return;
        }
        this.valueAsTagModels.push({ readonly: false, display: event.value });
        this.emitUpdatedModels();
    }

    onItemRemove(event: any) {
        let displayValueOfItemToRemove: string = event.value;
        let pillIndex = this.valueAsTagModels.findIndex((s: any) => s.display === displayValueOfItemToRemove);
        this.valueAsTagModels.splice(pillIndex, 1);
        this.emitUpdatedModels();
    }

    emitUpdatedModels() {
        this._values = this.valueAsTagModels.filter((d: any) => d.readonly === false).map((s: any) => s.display);
        this.valueChanged.emit(this._values);
        this.onChange.emit(this._values);
    }


    // CVA stuff     
    onChange: any = () => { };
    onTouch: any = () => { };

    writeValue(value: string[]): void {
        this._values = value;
        this.onChange(value);
        this.onTouch(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }


    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }



}
