<div class="container-fuid w-100 header-general d-flex align-items-center">
  <div class="w-100 ms-2 ms-sm-2 ms-md-4 ms-lg-5 ms-xl-5 ms-xxl-5 me-2 me-sm-2 me-md-4 me-lg-5 me-xl-5 me-xxl-5 justify-content-center align-items-center mx-auto p-0 row row-cols-2 row-cols-sm-2 row-cols-md-12 row-cols-lg-12 row-cols-xl-12 row-cols-xxl-12">
      <div class="p-0 py-2 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-start">
        <a class="navbar-brand" *ngIf="user && user.role && user.role.indexOf('Customer')>-1" routerLink="/">
          <img src="{{whiteLabelProvider.LogoImageSrc}}" alt="Logo" *ngIf="whiteLabelProvider.LogoImageSrc">
        </a>
        <img *ngIf="(!user || !user.role || user && user.role && user.role.indexOf('Customer')===-1) && whiteLabelProvider.LogoImageSrc" src="{{whiteLabelProvider.LogoImageSrc}}" alt="Logo">
      </div>
      <div class="p-0 py-2 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-end">
        <div class="btn-group" *ngIf="user && user.role != null">
          <button type="button" class="btn bg-transparent header-font dropdown-toggle header-user pe-0" data-bs-toggle="dropdown" aria-expanded="false">
            {{ user.fullName }}
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" [routerLink]="['/settings', 'account']" translate="General.Header.MyAccount"></a></li>
            <li class="d-none d-lg-block" *ngIf="user.isOrganisationAdmin || user.isWhitelabelAdmin || user.isSystemAdmin"><a class="dropdown-item" [routerLink]="['/settings', 'application', user.organisationGuid]" translate="General.Header.Settings"></a></li>
            <li class="d-none d-lg-block" *ngIf="whiteLabelProvider.knowledgebase"><a class="dropdown-item" [href]="knowledgeBaseUrl" target="_blank" rel="noopener" translate="General.Header.KnowledgeBase"></a></li>
            <li class="d-none d-lg-block" *ngIf="whiteLabelProvider.support"><a class="dropdown-item" [href]="supportUrl" target="_blank" rel="noopener" translate="General.Header.CreateSupportTicket"></a></li>
            <li class="d-none d-lg-block" *ngIf="user.isOrganisationAdmin"><a class="dropdown-item" [href]="giveFeedbackUrl" target="_blank" rel="noopener"  translate="General.Header.GiveFeedback"></a></li>
            <li><a class="dropdown-item" (click)="logOut()" translate="General.Header.Logout"></a></li>
          </ul>
        </div>
      </div>
  </div>
</div>