<div>
    <h3 class="title" translate="Request.Document.Dialog.ChooseStationeryType"></h3>

    <div class="d-flex row-cols-12 p-4 pt-4 pb-0 pe-0">
        <div class="col-4 edit-document-stationery">
            <div class="page-preview">
                <img *ngIf="previewBackground!=null" [src]="previewBackground" alt="Stationery" class="default-boxshadow w-100 previewBackground">
            </div>
        </div>
        <div class="col-8">
            <div class="edit-document-stationery-list me-2">
                <ul class="w-auto list-group me-2" (mouseleave)="showSelectedStationeryPreview()">
                    <li class="list-group-item subheader d-block align-items-center">
                        <div class="d-flex align-items-center">
                            <label for="flexCheckDefault" class="form-check-label" translate="Request.Document.Dialog.StationeryType"></label>
                        </div>
                    </li>
                    <form [formGroup]="myForm">
                        <li class="list-group-item d-block nohover align-items-center">
                            <label for="stationery_{{blankId}}" class="form-check-label w-100 h-100 d-flex align-items-center" (mouseover)="showPreview(null)" translate>
                                <input type="radio" id="stationery_{{blankId}}" class="form-check-input radio me-1 mb-1" [value]="blankId" formControlName="selectedStationery">
                                Request.Document.Dialog.Blank
                            </label>
                        </li>
                        <li class="list-group-item d-block nohover align-items-center" *ngFor="let stationeryItem of papertypes; let index = index">
                            <label for="stationery_{{stationeryItem.id}}" class="form-check-label w-100 h-100 d-flex align-items-center" (mouseover)="showPreview(stationeryItem)">
                                <input type="radio" id="stationery_{{stationeryItem.id}}" class="form-check-input radio me-1 mb-1" [value]="stationeryItem.id" formControlName="selectedStationery">
                                {{stationeryItem.name}}
                            </label>
                        </li>
                    </form>
                </ul>
            </div>
        </div>
    </div>

    <div class="p-4 pt-0 w-100 d-table d-block col-12">
        <button (click)="closeDialog()" type="submit" class="btn btn-sm btn-outline-primary float-start mt-4" translate="Button.Back"></button>
        <button (click)="chooseStationery()" type="submit" class="btn btn-sm btn-success float-end mt-4" translate="Button.Confirm"></button>
    </div>
</div>