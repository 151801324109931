import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byteSizePipe',
  pure: false
})
export class byteSizePipe implements PipeTransform {
  translations = <any>{};
  dayName: string | undefined;

  constructor() {
    // Must not be empty. It's approved by a comment.
  }

  transform(value: number) {
    if(value == null) return null;
    let scale = "B";
    if (value > 1024) {
        scale = "KB";
        value = value/1024;
        if(value > 1024){
            scale = "MB";
            value = value/1024;
        }
    }
    return Math.floor(value)+scale
  }
}