import { Component, Input } from '@angular/core';

export class BreadCrumb {
    public translationKey!: string;
    public redirectRoute!: string;
    public displayTitle?:string;
    
    constructor(translationKey: string, redirectUrl: string) {
        this.translationKey = translationKey;
        this.redirectRoute = redirectUrl;
    }
}

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {

    @Input() breadCrumbs!: BreadCrumb[];

    /** the secondary breadcrumb to Display, has a fallback when NULL */
    @Input() secondCrumb!: string | null;

}