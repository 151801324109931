import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StatusRequest } from 'src/app/models/requests';

export const StatusViewLogModalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  size: 'md'
};

@Component({
  templateUrl: './status-view-log.modal.html'
})
export class StatusViewLogModal {
  @Input() request!: StatusRequest;

  constructor(private activeModal: NgbActiveModal) {}

  public closeModal() {
    this.activeModal.close();
  }
}
