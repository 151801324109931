import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html'
})
export class InfoButtonComponent {
  @Input() labelName: string = '';
  @Input() url: string = '';
  @Input() placement: string = 'top';
  infoButtonStyle: InfoButtonStyle = InfoButtonStyle.Help;
  constructor() {
  }

  /**
   * True if set to Help or Unset
   * This is the default appearance for Tooltip/Info Helpers
   */
  public IsHelpStyle() {
    return !this.infoButtonStyle || this.infoButtonStyle == InfoButtonStyle.Help;
  }
  /**
   * True if set to Alert
   * if more styles are introduced than this can verify with checking type, otherwise not being Help/Default is enough
   */
  public IsAlertStyle() {
    return !this.IsHelpStyle(); // && this.infoButtonStyle == InfoButtonStyle.Help;
  }
}

@Component({
  selector: 'app-danger-button',
  templateUrl: './info-button.component.html'
})
export class DangerInfoButtonComponent extends InfoButtonComponent {
  constructor() {
    super();
    this.infoButtonStyle = InfoButtonStyle.Alert;
  }
}

export enum InfoButtonStyle {
  Help = 1,
  Alert = 2,
}