
import { Component, Input } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DocumentActor } from 'src/app/models/actors';


export const RejectedModalOptions: NgbModalOptions = {
  keyboard: false,
  centered: true,
  size: 'lg',
  backdrop: 'static'
};

@Component({
  templateUrl: './performed-action-rejected.modal.html'
})
export class PerformedActionRejectedModal {
  @Input() actions!: DocumentActor[];
  @Input() documents!: any[];
  @Input() requestStatus!: string;
  @Input() rejectionReason!: string;
}
