import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ViewerDataService } from '../../viewer-data.service';
import { ActorActionsApiClient } from 'src/app/services/api-clients/actor-actions.api-client';

@Component({
  templateUrl: './perform-action-adopt.modal.html'
})
export class PerformActionAdoptModal implements OnInit {

  @Input() jobname!: string;
  @Output() adoptClicked = new EventEmitter();
  public awaitingAction: boolean = false;
  public adoptfieldIsSet: boolean = false;
  public datefieldIsSet: boolean = false;

  public get disableButton(): boolean {
    return this.awaitingAction || !this.adoptfieldIsSet || !this.datefieldIsSet;
  }
  /**
   * Number of the current actor action
   */
  expectedActionNumber: number = 1;
  currentActorInviteId!: string;

  maxDeadline: Date = new Date(new Date().setDate(new Date().getDate()));
  maxDate!: { year: number; month: number; day: number; };
  startDate!: { year: number; month: number; day: number; };
  vaststellingText: string = "Request.View.Summary.Choose";
  /** Whether a Vaststelling datum is needed */
  isAdopted: boolean = false;
  setDate: string = "";
  date: Date | null = null;
  model: NgbDateStruct | undefined;
  translations = <any>{};
  constructor(public activeModal: NgbActiveModal, private translateService: TranslateService, private viewerDataService: ViewerDataService, private actorActionsApiClient: ActorActionsApiClient) {
    // Must not be empty. It's approved by a comment.
    this.viewerDataService.getCurrentActor$().subscribe(currentActor => {
      this.expectedActionNumber = currentActor.badgeNumber;
      this.currentActorInviteId = currentActor.id;
    });
    this.maxDate = { year: this.maxDeadline.getFullYear(), month: this.maxDeadline.getMonth() + 1, day: this.maxDeadline.getDate() };

  }

  ngOnInit(): void {
    this.getTranslations();
  }

  getTranslations() {
    this.translateService.get([
      'Request.View.Summary.ChooseDate',
    ]).subscribe(translation => {
      this.translations.ChooseDate = translation['Request.View.Summary.ChooseDate'];
    });
  }
  public changeDate(event: any) {
    document.getElementById('AdoptionDate')?.classList.remove('required');
    document.getElementById('AdoptionDateCalendar')?.classList.remove('required');
    this.setDate = event.year + "-" + event.month + "-" + event.day;
    this.date = new Date(this.setDate);
    this.datefieldIsSet = !!this.date;
  }

  public changeVastelling(event: string) {
    this.vaststellingText = "Request.View.Summary." + event;
    this.isAdopted = (event == "Yes");
    this.adoptfieldIsSet = true;
    this.datefieldIsSet = !!this.date || !this.isAdopted;
  }

  public getSelectedDate() {
    if (this.date !== null) return this.date.getDate() + "-" + this.date.getMonth() + "-" + this.date.getFullYear();
    return this.translations.ChooseDate;
  }
  back() {
    this.activeModal.close();
  }
  clickAdopt() {
    this.awaitingAction = true;
    let skipAdopton = !this.isAdopted;
    let adoptionDate = this.setDate!;
    this.actorActionsApiClient.adopt(this.viewerDataService.get_RequestGuid, this.currentActorInviteId, skipAdopton, adoptionDate).subscribe(() => {

      this.viewerDataService.setFieldsOnActor(this.currentActorInviteId,this.isAdopted);
      this.viewerDataService.actorCompletedAction(this.currentActorInviteId);
      this.activeModal.close();
    });
  }

}
