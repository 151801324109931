import { ErrorHandler, Injectable } from "@angular/core";
import { LoggingService } from "./logging.service";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

    constructor(private loggingService: LoggingService) {
        super();
    }

    override handleError(error: Error) {
        this.loggingService.logException(error); // Manually log exception

    }
}