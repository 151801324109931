import { FormGroup } from "@angular/forms";

export function MarkFormGroupTouchedAndDirty(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        MarkFormGroupTouchedAndDirty(control);
      } else {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }