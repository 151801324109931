import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

/**
 * The Api Client for the ActorActionssController
 */
@Injectable({
    providedIn: 'root'
})
export class EventLogApiClient {

    constructor(private httpClient: HttpClient) { }


    /**
     *  Grab the complete EventLog for a request
     * @param requestGuid relevant for what request to grab an eventlog
     * @returns A list of EventLogModels
     */
    Get(requestGuid: string): Observable<EventLogModel[]> {
        return this.httpClient.get<EventLogModel[]>(environment.apiUrl + "/eventlog/" + requestGuid);
    }


}
/**
 * Class matching the BE eventLogModel
 * Meant for presentating in the FE EventLog (table) component
 */
export class EventLogModel {
    occurenceTimestamp!: Date;
    sourceDisplayName!: string;
    eventType!: string;
    // description!: string;
    descriptionParametersZero!: string;
    descriptionParametersOne!: string;
    descriptionParametersTwo!: string;
}
