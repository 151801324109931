<div class="vh-100 container d-flex align-items-center">
    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 container-fuid mx-auto bg-white p-0 shadow default-boxrounded">
        <div class="w-100">
            <div class="w-100 d-flex  mx-4">
                <img id="LogoHeader" src="{{whiteLabelProvider.IdentityLogoImageSrc}}" class="my-4" alt="Logo" />
            </div>
            <div>
                <p class="my-3 mx-4 lh-base" translate=OnboardingCompleted.Information></p>
            </div>
            <div class="w-100 d-flex mx-4">
                <a href="{{url}}">
                    <button type="button" class="btn btn-sm btn-primary mb-3" translate="OnboardingCompleted.GoToLogin"></button>
                </a>
            </div>
        </div>
    </div>
</div>
