import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Dashboard, RequestsInDashboard } from '../models/dashboard';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { Requests } from '../models/requests';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) { }

  configUrlStatus = environment.apiUrl + "/dashboard/status";
  configUrlDashboard = environment.apiUrl + "/dashboard/GetRequestsV2Batch";
  configUrlRequests = environment.apiUrl + "/dashboard/GetRequestsV2";
  configUrlActions = environment.apiUrl + "/dashboard/myactions";
  configUrlOrganisationdocuments = environment.apiUrl + "/dashboard/GetRequestsForOrganisationV2Batch";
  
  async getStatus() {
    return firstValueFrom(this.httpClient.get<Dashboard>(this.configUrlStatus));
  }

  async getDashboard() {
    let params = new HttpParams().set('includeExpired', true)
    return firstValueFrom(this.httpClient.get<RequestsInDashboard>(this.configUrlDashboard, { params }));
  }

  async getRequests() {
    let params = new HttpParams().set('includeExpired', true);
    return firstValueFrom(this.httpClient.get<Requests>(this.configUrlRequests, { params }));
  }

  async getActions(){
    return firstValueFrom(this.httpClient.get<Requests>(this.configUrlActions));
  }

  async getRequestsFromOrg() {
    let params = new HttpParams().set('includeExpired', true);
    return firstValueFrom(this.httpClient.get<RequestsInDashboard>(this.configUrlOrganisationdocuments, { params }));
  }

}
