
<button type="button" class="btn bg-transparent border-0 p-0" data-bs-toggle="dropdown" aria-expanded="false">
  <i class="bi bi-three-dots bi-large"></i>
</button>
<ul class="dropdown-menu">
  <li><a class="dropdown-item pt-1 pb-1" (click)="applyAction(requestStatusActionsEnum.Withdrawn)"
      *ngIf="withdrawAllowed() && hasEditRights" translate="Request.Status.RequestOptions.WithdrawRequest"></a>
  </li>

  <li><a class="dropdown-item pt-1 pb-1" (click)="applyAction(requestStatusActionsEnum.Download)" *ngIf="hasEditRights"
      translate="Request.Status.RequestOptions.DownloadRequest"></a></li>
  <li><a class="dropdown-item pt-1 pb-1" (click)="applyAction(requestStatusActionsEnum.Purge)" *ngIf="purgeAllowed() && hasEditRights"
      translate="Request.Status.RequestOptions.Purge"></a></li>
  <li><a class="dropdown-item pt-1 pb-1" (click)="applyAction(requestStatusActionsEnum.Restore)"
      *ngIf="purgedActionsAllowed() && hasEditRights" translate="Request.Status.RequestOptions.Restore"></a>
  </li>
  <li><a class="dropdown-item pt-1 pb-1" (click)="applyAction(requestStatusActionsEnum.Delete)"
      *ngIf="purgedActionsAllowed() && hasEditRights" translate="Request.Status.RequestOptions.Delete"></a></li>

  <li><a class="dropdown-item pt-1 pb-1" (click)="applyAction(requestStatusActionsEnum.BecomeOwner)" *ngIf="allowedToChangeOwnership"
      translate="Request.Status.RequestOptions.BecomeOwner"></a></li>

  <li><a class="dropdown-item pt-1 pb-1" (click)="applyAction(requestStatusActionsEnum.Viewlog)" *ngIf="hasEditRights"
      translate="Request.Status.RequestOptions.ViewLog"></a></li>
</ul>