import { Component, EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DocumentActor } from 'src/app/models/actors';
import { StatusRequest } from 'src/app/models/requests';
import { User } from 'src/app/models/user';
import { RequestStatuses } from 'src/app/constants/status.enum';
import { RequestStatusService } from '../../requeststatus.service';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html'
})
export class ProgressBar implements OnDestroy {
  public request: StatusRequest = {} as StatusRequest;
  private requestSubscription: Subscription;
  statusIndicator!: string;

  constructor(private requestStatusService: RequestStatusService, config: NgbProgressbarConfig) {
    config.striped = false;
    config.animated = false;
    this.requestSubscription = this.requestStatusService.selectedRequest$.subscribe(request => {
      if(Object.keys(request).length === 0) return;
      this.request = request;  
    });
  }

  ngOnDestroy(): void {
    this.requestSubscription.unsubscribe();
  }

  getStatusIndicator() {
    let primaryStatuses: string[] = RequestStatuses.PrimaryStatuses;
    let warningStatuses: string[] = RequestStatuses.WarningStatuses;
    let successStatuses: string[] = RequestStatuses.SuccessStatuses;
    let errorStatuses: string[] = RequestStatuses.ErrorStatuses;

    if (errorStatuses.includes(this.request.status)) {
      this.statusIndicator = "danger";
    }
    if (successStatuses.includes(this.request.status)) {
      this.statusIndicator = "success";
    }

    if (primaryStatuses.includes(this.request.status)) {
      this.statusIndicator = "primary";
    } else if (warningStatuses.includes(this.request.status)) {
      this.statusIndicator = "warning";
    } else if (successStatuses.includes(this.request.status)) {
      this.statusIndicator = "success";
    } else if (errorStatuses.includes(this.request.status)) {
      this.statusIndicator = "danger";
    } else {
      this.statusIndicator = "warning";
    }
  }

  statusIndicatorProgress(): number {
    this.getStatusIndicator();
    let errorStatuses: string[] = RequestStatuses.ErrorStatuses;
    if (this.request.progress) {
      return parseInt(this.request.progress);
    }
    if (errorStatuses.includes(this.request.status)) {
      return 100;
    }
    return 0;
  }
}
