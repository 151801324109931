<div class="container-fuid bg-white default-boxrounded">
    <div class="row">
        <div class="col-7">
            <h2 class="p-3" translate="Request.Status.ChangeDeadlineDateModal.Title"></h2>
        </div>
    </div>
    <div class="px-3">
        <form class="row needs-validation has-validation" [formGroup]="form">
            <span class="pb-3" translate="Request.Status.ChangeDeadlineDateModal.ForPerson"
                [translateParams]="{personName: fullName}"><app-info-button
                    labelName="Request.Status.ChangeDeadlineDateModal.Info"></app-info-button></span>

            <div class="mb-3 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label class="pb-2" translate="Request.Status.ChangeDeadlineDateModal.PickDate"></label>
                <button class="btn default dropdown-toggle text-truncate language d-none d-md-block" type="button"
                    id="dropdownMenu">
                    <span class="d-block" (click)="d.toggle()">
                        {{ deadline | date:'dd-MM-yy' }}
                        <em class="bi bi-calendar float-end" aria-hidden="true"></em>
                    </span>
                    <input class="form-control invisible position-relative top--20"
                        placeholder="{{ deadline | date:'dd-MM-yy' }}" name="dp" [(ngModel)]="model"
                        [ngModelOptions]="{standalone: true}" ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"
                        [startDate]="startDate" (ngModelChange)="changeDeadline($event)"
                        aria-describedby="basic-addon1">
                </button>
                <input class="form-control d-block d-md-none" type="date" [value]="date" [min]="minDateMobile"
                    (change)="changeDeadlineMobile($event)">
            </div>
            <div class="mb-3 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label class="pb-2" translate="Request.Status.ChangeDeadlineDateModal.PickTime"></label>
                <div ngbDropdown class="dropdown d-none d-md-block" ngDefaultControl name="deadline" id="deadline">
                    <button class="btn default dropdown-toggle text-truncate language" type="button" id="dropdownMenu"
                        ngbDropdownToggle>{{ deadline | date:'HH:mm' }}
                        <em class="bi bi-chevron-down float-end" aria-hidden="true"></em>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenu" class="dropdownMenuTime">
                        <button ngbDropdownItem *ngFor="let in of hours(24); let i = index" value="{{i}}:00"
                            (click)="changeDeadlineTime($event)">{{i}}:00</button>
                    </div>
                </div>
                <input class="form-control d-block d-md-none" type="time" [value]="time"
                    (change)="changeDeadlineTimeMobile($event)">
            </div>

            <div class="mb-3 w-100">
                <button type="button" class="btn btn-sm btn-outline-primary me-1 float-start" (click)="back()"
                    translate>Button.Back</button>
                <button type="submit" class="btn btn-sm btn-primary ms-1 order-2 float-end" (click)="save()"
                    [disabled]="!deadline" translate>Button.Save</button>
            </div>
        </form>
    </div>
</div>