export enum StatusEnum {
  New = 'New',
  Active = 'Active',
  Concept = 'Concept', // not in documentation
  Processing = 'Processing',
  Declined = 'Declined',
  Error = 'Error',
  Expired = 'Expired',
  Filing = 'Filing',
  InvitationExpired = 'InvitationExpired',
  PendingSignature = 'PendingSignature',
  PendingDownload = 'PendingDownload',
  PendingApproval = 'PendingApproval',
  /**
   * (Actor) Actions are being handled
   */
  Pending = 'Pending',
  Completed = 'Completed',
  Withdrawn = 'Withdrawn',
  Deleted = 'Deleted', // not in status doc
  PendingDigipoort = 'PendingDigipoort',
  PendingSbrNexus = 'PendingSbrNexus',
  Task = 'Task', // not in status doc
  Sent = 'Sent',// not in status doc
  Failed = 'Failed',// not in status doc
  Purged = 'Purged',// not in status doc
}

export class RequestStatuses {


  public static get ThatCanBePurged(): StatusEnum[] {
    return [
      StatusEnum.New,
      StatusEnum.Active,
      StatusEnum.Processing,
      StatusEnum.Completed,
      StatusEnum.Error,
      StatusEnum.InvitationExpired,
      StatusEnum.Withdrawn,
      StatusEnum.Declined
    ]
  }


  public static get ThatCanBeDeleted(): StatusEnum[] {
    return [
      StatusEnum.Expired
    ]
  }
  /**
   * When Actor Actions are being Handled
   */
  public static get ThatAreActorPending(): StatusEnum[] {
    return [
      StatusEnum.PendingDownload,
      StatusEnum.PendingSignature,
      StatusEnum.PendingApproval,
      StatusEnum.Pending,
    ]
  }
  public static get ThatCanBeReminded(): StatusEnum[] {
    return  this.ThatAreActorPending;
  }
  public static get ThatCanBeWithdrawn(): StatusEnum[] {
    return  this.ThatAreActorPending;
  }

  public static get ThatCanBeRestored(): StatusEnum[] {
    return [
      StatusEnum.Expired
    ]
  }

  /**
   * Primary, warning, success and error statuses
   */
  public static get PrimaryStatuses(): StatusEnum[] {
    return [
      StatusEnum.New,
      StatusEnum.Active,
    ]
  }
  public static get WarningStatuses(): StatusEnum[] {
    return [
      StatusEnum.PendingDownload,
      StatusEnum.PendingSignature,
      StatusEnum.PendingApproval
    ]
  }
  public static get SuccessStatuses(): StatusEnum[] {
    return [
      StatusEnum.Completed
    ]
  }
  public static get ErrorStatuses(): StatusEnum[] {
    return [
      StatusEnum.Error,
      StatusEnum.Expired,
      StatusEnum.InvitationExpired,
      StatusEnum.Withdrawn,
      StatusEnum.Declined
    ]
  }
}


// The PKIsigning APIs both V1 and V2 use the following statuses

// New: the dossier is new on the platform and has not been viewed yet
// Active: the dossier is available on the platform, but has no signers attached
// Processing: the dossier is being converted
// Declined: the dossier has been declined
// Error: an error has occurred while converting, sending invitations, or publishing to Digipoort/AFAS
// Expired: the document has been moved to the recyclebin
// Filing: the dossier is being published to Chamber of Commerce
// InvitationExpired: the signing invitation of this dossier has been expired
// PendingSignature: invitations for the document have been set and the document is awaiting signing
// PendingDownload: the dossier is waiting to be downloaded by a client
// PendingApproval: the dossier is awaiting adoption by the client
// Completed: the dossier has been signed
// Withdrawn: the dossier has been revoked for signing
// PendingDigipoort: the dossier is being processed by Digipoort
// PendingSbrNexus: the dossier is being processed by SBR Nexus



// PendingFiling: the dossier is awaiting publication // not in current TRANSLATION list


// BACKEND STATUSSES AFAS THINGIE
// Active,         // Actief
// Completed,      // Afgerond
// Declined,       // Afgewezen
// Processing,     // Converteren
// Error,          // Fout
// PendingSbrNexus,// InBehandelingSbrNexus
// Withdrawn,      // Ingetrokken
// PendingDigipoort, // InBehandelingDigipoort
// New,            // Nieuw
// Filing,         // Publiceren
// InvitationExpired,// UitnodigingVerlopen
// Expired,        // Vervallen
// PendingApproval,// Wacht op accordering
// PendingDownload,// Wacht op download
// PendingSignature, // Wacht op ondertekening

