import { Component, Input } from '@angular/core';
import { RequestModel } from 'src/app/models/requests';
import { RequestApiClient } from 'src/app/services/request.apiclient';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ideal-payment-button',
  templateUrl: './ideal-payment-button.component.html'
})
export class IdealPaymentButtonComponent {

  private _requestId!: any;
  paymentStatus: boolean = false;
  get requestId(): any {
    return this._requestId;
  }
  @Input() set requestId(val: any) {
    this._requestId = val;
    if (this._requestId && (Object.keys(this.request).length === 0)) {
      this.getRequestData();
    }
  }

  environment: any = environment;
  paymentUrl!: string;
  request: RequestModel = {} as RequestModel;
  taxAmount: any;

  constructor(private requestService: RequestApiClient) {
  }

  taxPaymentIntegrationEnabled(): boolean {
    return this.environment.enableTaxPaymentIntegration;
  }

  getRequestData() {
    this.requestService.getRequestForViewer(this.requestId).subscribe({
      next: (requestData) => {
        this.request = requestData;
        this.getPaymentData();
      }
    });
  }

  getPaymentData() {
    if ((!this.paymentUrl || this.paymentUrl === '') && this.request.dossierType === 'VatDeclaration' && (this.request.status === 'Filing' || this.request.status === 'PendingDigipoort' || this.request.status === 'Completed') && (!this.request.taxPaymentStatus || this.request.taxPaymentStatus != 'ACSC' && this.request.taxPaymentStatus != 'PDNG')) {
      this.requestService.getPaymentUrl(this.requestId).subscribe({
        next: (data: any) => {
          this.paymentUrl = data;
        },
        error: () => {
          // error starting payment session
        },
      });
    }
  }

  pay() {
    var win = window.open(this.paymentUrl, "PaymentPopup", "location=0,height=800,width=800")!;
    if (win == null) return;
    let y = this;
    var timer = setInterval(() => {
      if (win.closed) {
        clearInterval(timer);
        y.getRequestData();
        y.checkForTaxPaymentStatus();
      }
    }, 1000);
  }

  private checkForTaxPaymentStatus() {
    let x = 1;
    let y = this;
    let intervalID = setInterval(function () {
      y.getRequestData();
      if (++x === 5 || y.request.taxPaymentStatus) {
        clearInterval(intervalID);
      }
    }, 2000 * x);
  }
}
