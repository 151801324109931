import { Component, Input } from '@angular/core';
import { BadgeStatusEnum } from '../../constants/badge-status.enum';

// AS OF WRITING THESE ARE THE BADGE STYLES IN Badges.scss
// .badge-style-info
// .badge-style-secondary 
// .badge-style-success 
// .badge-style-warning 
// .badge-style-danger 

@Component({
  selector: 'app-badge-status-display',
  templateUrl: './badge-status-display.component.html'
})
export class BadgeStatusDisplayComponent {

  // although we want to force the enum, as input this is not always possible so make sure to allow/catch invalid input
  @Input() badgeStatus: BadgeStatusEnum | string | null = BadgeStatusEnum.Info;


  private InfoStyle = "info";
  private SecondaryStyle = "secondary";
  private SuccessStyle = "success";
  private WarningStyle = "warning";
  private DangerStyle = "danger";

  get badgeStatusAsOrbStyleClass(): string {

    let mainOrbStyle = "badge-style-";
    switch (this.badgeStatus) {
      // statussen voor INFO
      case BadgeStatusEnum.Info:
        return mainOrbStyle + this.InfoStyle;

      // statussen voor SECONDARY
      case BadgeStatusEnum.Secondary:
        return mainOrbStyle + this.SecondaryStyle

      // statussen voor SUCCESS
      case BadgeStatusEnum.Success:
        return mainOrbStyle + this.SuccessStyle

      // statussen voor WARNING
      case BadgeStatusEnum.Warning:
        return mainOrbStyle + this.WarningStyle

      // statussen voor DANGER
      case BadgeStatusEnum.Danger:
        return mainOrbStyle + this.DangerStyle

      // Null, invalid or default input word INFO
      case null:
      case undefined:
      default:
        return mainOrbStyle + this.InfoStyle
    }

  }
}
