import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
/**  
 * 
 * 
 * (  Gues who is back AGAIN!  )
 *            \ |         .--.  .--.
                    /    \/    \
                   | .-.  .-.   \
                   |/_  |/_  |   \
                   || `\|| `\|    `----.
                   |\0_/ \0_/    --,    \_
 .--"""""-.       /              (` \     `-.
/          \-----'-.              \          \
\  () ()                         /`\          \
|                         .___.-'   |          \
\                        /` \|      /           ;
 `-.___             ___.' .-.`.---.|             \
    \| ``-..___,.-'`\| / /   /     |              `\
     `      \|      ,`/ /   /   ,  /
             `      |\ /   /    |\/
              ,   .'`-;   '     \/
         ,    |\-'  .'   ,   .-'`
       .-|\--;`` .-'     |\.'
      ( `"'-.|\ (___,.--'`'   
       `-.    `"`          _.--'
          `.          _.-'`-.
            `''---''``       `.
 */
@Injectable({ providedIn: 'root' })
export class TheNaviGator {
  constructor(private router: Router) {
  }
  Navigate_To_Dashboard() {
    this.router.navigate(["/dashboard"]);
  }
  Navigate_To_Build_By_Workflow(workflowGuid: string) {
    this.router.navigate(["/build"], { queryParams: { workflowId: workflowGuid } });
  }
  Navigate_To_Status(requestGuid: string) {
    this.router.navigate(["/request/" + requestGuid]);
  }
}