<div class="modal-body modal-allow-overflow modal-font p-3">
    <div class="row row-cols-12 mb-2">
        <div class="fw-bolder col-12 text-truncate">
            <span class="badge bg-primary perform-action-dialog-header-badgenumber rounded-pill me-2">
                <span ngbAutofocus class="align-middle text-white">{{expectedActionNumber}}</span>
            </span>
            <span>{{"Request.Actions.Adopt" | translate}} {{jobname}}</span>
        </div>
    </div>

    <span class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-0">
        <div>
            <label for="adoptedDropdown" class="form-label mt-4 d-block text-truncate mb-1"
                translate="Request.View.Summary.ReportAdopted"></label>
            <span class="input-group">
                <div ngbDropdown class="col dropdown" ngDefaultControl name="adoptedDropdown" id="adoptedDropdown">
                    <button class="btn language dropdown-toggle text-truncate" type="button" id="dropdownMenu"
                        [ngClass]="{'required': vaststellingText === 'Request.View.Summary.Choose'}" ngbDropdownToggle>
                        {{vaststellingText | translate}}<em class="bi bi-chevron-down float-end"
                            aria-hidden="true"></em>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenu">
                        <button ngbDropdownItem value="yes" (click)="changeVastelling('Yes')" type="button"
                            translate="Request.View.Summary.Yes"></button>
                        <button ngbDropdownItem value="no" (click)="changeVastelling('No')" type="button"
                            translate="Request.View.Summary.No"></button>
                    </div>
                </div>
            </span>
        </div>
    </span>
    <span class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-0">
        <div>
            <label for="AdoptionDate" class="form-label mt-4 d-block text-truncate mb-1"
                translate="Request.View.Summary.AdoptionDate"></label>
            <span class="input-group">

                <!--                 
                <input class="form-control invisible position-relative top--20"
                placeholder="{{ deadline | date:'dd-MM-yy' }}" name="dp" [(ngModel)]="model"
                [ngModelOptions]="{standalone: true}" ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"
                [startDate]="startDate" (ngModelChange)="changeDeadline($event)"
                aria-describedby="basic-addon1"> -->



                <input class="form-control rendereddocumentsummary-summary-dropdown position-relative" id="AdoptionDate"
                    [disabled]="!isAdopted" [(ngModel)]="model" (ngModelChange)="changeDate($event)"
                    placeholder={{getSelectedDate()}} name="dp" ngbDatepicker #d="ngbDatepicker" [startDate]="startDate"
                    [maxDate]="maxDate" (click)="d.toggle()" aria-describedby="basic-addon1" readonly required>
                <span class="input-group-text rendereddocumentsummary-summary-dropdown" id="AdoptionDateCalendar"
                    [ngClass]="{'disabled' : !isAdopted}">
                    <em class="bi bi-calendar" (click)="d.toggle()" aria-hidden="true"></em>
                </span>
            </span>
        </div>
    </span>

    <div class="mt-4 d-table w-100">
        <span class="float-start">
            <button type="button" class="btn btn-sm btn-outline-primary" (click)="back();" translate="Button.Back">
            </button>
        </span>
        <span class="float-end">
            <ng-container *ngIf="isAdopted">
                <button type="button" class="btn btn-sm btn-success ms-1 order-1 float-end" (click)="clickAdopt();"
                    [disabled]="disableButton" translate="Button.Adopt">
                </button>
            </ng-container>
            <ng-container *ngIf="!isAdopted">
                <button type="button" class="btn btn-sm btn-success ms-1 order-1 float-end" (click)="clickAdopt();"
                    [disabled]="disableButton" translate="Button.Confirm">
                </button>
            </ng-container>
        </span>
    </div>
</div>