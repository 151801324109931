import { Component, Input, OnInit } from '@angular/core';

@Component({
  templateUrl: './perform-action-pending.modal.html'
})
export class PerformActionPendingModal{

  @Input() displayMessage!: string;
  
}
