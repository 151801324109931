<div class="d-block d-lg-none overlay-mobileviewport">
    <div class="modal-dialog modal-dialog-centered modal-lg overlay-mobileviewport-modal">
        <div class="modal-content">
            <div class="container-fuid bg-white default-boxrounded m-0 p-2">
                <div class="mt-2">
                    <h3 translate="Modals.NotAvailableInMobileViewport.Title"></h3>
                    <p translate="Modals.NotAvailableInMobileViewport.Content"></p>
                </div>
            </div>
        </div>
    </div>
</div>