export class DocumentActor {
  public naam!: string;
  public firstname!: string;
  public prefix!: string;
  public lastname!: string;
  public email!: string;
  public mobile!: string;
  public order!: number;
  public ordinal!: number;
  public fieldName!: string;
  public acronym!: string;
  public signerId!: number;
  public hasSigned!: boolean;
  public signingDate!: Date | null;
  public fullName!: string;
  public deadline!: Date;
  public validateRealIdentity!: boolean;
  public identityProvider!: string;
  public language!: string;
  public emailActivity!: EmailActivity[];
  public invitationSent!: boolean;
  public actorId!: number;
  public recipientId!: number;
  public action!: string | any;
  /** InviteId */
  public id!: string;
  public warning!: boolean;
  public dossierPersonId!: string;
  public message!: string;
  public documentId!: string;
  public sigFieldX!: any;
  public sigFieldY!: any;
  public sigFieldW!: any;
  public sigFieldH!: any;
  public sigFieldPage!: number;
  public deadlineDays: number | null = null;
  public absoluteActionOrderNumber?: number;
  public workflowPlaceholderId: number | null = null;
  public allowedQtsps: string[] | null = null;

  /**
   * IF an 'Adopt' action, this holds the request properties with the made choice (if completed)
   * NULL if no choice
   */
  public adoptionChoice: boolean | null = null;
  /**
   * A FE property only, a way to ASSIGN the number for the Actor
   * This number is than used to display for the side line, action modals, and Action summary
   */
  public badgeNumber: number = 0;
}

export interface EmailActivity {
  event: string;
  reason: string;
  timestamp: Date;
  pkisMessageType: string;
  email: string;
}