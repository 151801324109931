import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { BlobHelper } from 'src/app/_helpers/blobHelper';
import { Papertypes } from 'src/app/models/papertypes';
import { LoggingService } from 'src/app/services/logging.service';
import { OrganisationApiClient } from 'src/app/services/organisation.apiclient';

@Component({
  selector: 'app-add-stationery-modal',
  templateUrl: './add-stationery-modal.component.html',
  styleUrls: ['./add-stationery-modal.component.css']
})
export class AddStationeryModalComponent implements OnInit {
  previewBackground!: SafeUrl | null;
  previewId!: string;
  papertypes: Papertypes[] = [];
  myForm: FormGroup;
  blankId: string = '00000000-0000-0000-0000-000000000000';
  @Output('close') close = new EventEmitter();

  constructor(private activeModal: NgbActiveModal, private organisationApiClient: OrganisationApiClient, private loggingService: LoggingService,
    private sanitizer: DomSanitizer, private blobHelper: BlobHelper) {
      this.myForm = new FormGroup({
        selectedStationery: new FormControl('')
      })
     }

  ngOnInit(): void {
    this.getStationery();
  }

  closeDialog() {
    this.activeModal.close();
  }

  private getStationery() {
    let getPapertypes = this.organisationApiClient.getPapertypes();
    lastValueFrom(getPapertypes).then((data: any) => {
      try {
        for (let papertype of data) {
          if (papertype.id !== this.blankId && !this.papertypes.some((s: any) => s.id === papertype.id)) {
            this.papertypes.push({
              id: papertype.id,
              name: papertype.name,
              thumbnail: papertype.thumbnail != null
                ? this.sanitizer.bypassSecurityTrustUrl(
                  window.URL.createObjectURL(this.blobHelper.base64toBlob(papertype.thumbnail, "image/jpeg", 512)))
                : null,
              ordinal: papertype.ordinal
            });
          }
        }
        this.papertypes.sort((a, b) => a.ordinal - b.ordinal);
      } catch (error: any) {
        this.loggingService.logException(error);
      }
      })
      .catch((error) => this.loggingService.logException(error));
  }
  
  showPreview(background: any) {
    if (background !== null) {
      this.previewBackground = background.thumbnail;
      this.previewId = background.id;
    } else {
      this.previewBackground = null;
      this.previewId = this.blankId;
      this.previewBackground = "../assets/blank_document.jpg";
    }
  }

  chooseStationery() {
    this.close.emit(this.myForm.controls['selectedStationery'].value);
  }

  showSelectedStationeryPreview() {
    let selectedStationeryId = this.myForm.controls['selectedStationery'].value;
    if (!selectedStationeryId) return;
    this.showPreview(this.papertypes.find(papertype => papertype.id == selectedStationeryId));
  }
}
