import { Injectable, Injector } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from 'src/environments/environment';
import { EventTypes, OidcSecurityService, OpenIdConfiguration, PublicEventsService } from "angular-auth-oidc-client";
import { filter } from 'rxjs/operators';
import { OpenIdConfigService } from "./openidconfig.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn: 'root'
  })
export class LoggingService {
    appInsights: ApplicationInsights;
    oidcSecurityService!:OidcSecurityService;
    eventsService!:PublicEventsService;
    tokenPayload:any;
    userData:any;
    openIdConfig!:OpenIdConfiguration;
    authService!:AuthenticationService;

    constructor(private injector:Injector) {
      setTimeout(()=> {
        this.oidcSecurityService = injector.get(OidcSecurityService);
        this.eventsService = injector.get(PublicEventsService);
        this.authService = injector.get(AuthenticationService);
        
        this.oidcSecurityService.getConfiguration().subscribe((config)=>{
            this.openIdConfig=config;
        });

        this.authService.currentUser.subscribe((userData)=>{
            this.userData=userData;
        });

        this.eventsService.registerForEvents()
        .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
        .subscribe({
          next: () => {
                this.oidcSecurityService.getPayloadFromIdToken().subscribe(payload=>{
                    this.tokenPayload=payload;
                });
                this.oidcSecurityService.getPayloadFromAccessToken().subscribe(payload=>{
                });                    
            }
        });
      })

      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.applicationInsightsKey,
          enableAutoRouteTracking: true, // option to log all route changes
          enableCorsCorrelation:true
        }
      });
      this.appInsights.loadAppInsights();
      this.appInsights.addTelemetryInitializer((envelope)=>{
        let itemTags = envelope.tags;
        itemTags = itemTags || [];
        itemTags['ai.cloud.role'] = environment.applicationFullName;
        itemTags['ai.user.id'] =this.tokenPayload.sub;
        itemTags['ai.session.id'] =this.tokenPayload.sid;
        itemTags['organisationGuid'] = this.userData.organisationGuid;
        itemTags['client_id'] = this.openIdConfig.clientId;
      });
    }
  
    logPageView(name?: string, url?: string) { // option to call manually
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  
    logEvent(name: string, properties?: { [key: string]: any }) {
      this.appInsights.trackEvent({ name: name}, properties);
    }
  
    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  
    logException(exception: Error, severityLevel?: number) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  
    logTrace(message: string, properties?: { [key: string]: any }) {
      this.appInsights.trackTrace({ message: message}, properties);
    }
}