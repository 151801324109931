
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { StringHelper } from "./string-helper";

/** The formGROUP must have 2 controls of which the input is equal */
export function IsEqualValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (
            matchingControl.errors && !matchingControl.errors['IsNotEqual']
        ) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ IsNotEqual: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}