/**
 * Model for the Actor Api-Client (controller) to Update an existing Person Assigned associated with an Actor
 */
export class UpdateExistingActorModel {
    /** Guid */
    requestGuid!: string;
    /** Guid */
    inviteId!: string;
    email!: string;
    mobile!: string;
    language!: string;
}